import {createAsyncThunk} from '@reduxjs/toolkit'
import API from '../../../Api'
import {thunkCatchError} from '../../utils'
import {IPageCatogories} from './types'
import {toast} from 'react-toastify'

const CATOGORY = {
  GET_OPTIONS: '/vendor/option_catalogs',
  CHANGE_STATUS: '/vendor/option_catalogs/status',
}

export const optionsAction = createAsyncThunk(
  'options/options',
  thunkCatchError(async ({page = 1, pre_page = 10, search}: IPageCatogories) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }
    const URL = `${CATOGORY.GET_OPTIONS}${query}`
    const response = await API.get(URL)

    return (
      {
        data: response.data?.data,
        pages: response.data?.pages,
      } || {}
    )
  })
)

export const changeStatusAction = createAsyncThunk(
  'options/changeStatusAction',
  thunkCatchError(async ({id, status}: {id: string; status: number}) => {
    const response = await API.put(`${CATOGORY.CHANGE_STATUS}/${id}`, {
      status: status,
    })
    return response.data
  })
)
export const dealteOptionAction = createAsyncThunk(
  'options/dealteOptionAction',
  thunkCatchError(async ({id}: {id: string}) => {
    try {
      const response = await API.delete(`${CATOGORY.GET_OPTIONS}/${id}`)
      toast.success('Deleted successfully')

      return id
    } catch (err: any) {
      toast.error(err.response.data.message)

      return
    }
  })
)

//   add  option  to  store
export const addOptionAction = createAsyncThunk(
  'options/addOptionAction',
  thunkCatchError(async (data: any) => {
    try {
      const response = await API.post(`${CATOGORY.GET_OPTIONS}`, data)

      return {...response.data.data, status: 1}
    } catch (err: any) {
      toast.error(err.response.data.message)
      return err.data.data
    }
  })
)
export const updateOptionAction = createAsyncThunk(
  'options/updateOptionAction',
  thunkCatchError(async ({id, data}: {id: string | number; data: any}) => {
    try {
      const response = await API.put(`${CATOGORY.GET_OPTIONS}/${id}`, data)

      return response.data.data
    } catch (err: any) {
      toast.error(err.response.data.message)
      return err.data.data
    }
  })
)
