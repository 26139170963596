import React from 'react'

export default function WalletPage() {
  return (
    <svg
      width='149'
      height='268'
      viewBox='0 0 149 268'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.6174 150.251L90.8297 139.072C102.99 138.625 111.123 148.092 111.123 161.179V223.096C111.123 236.183 102.719 246.423 92.352 245.968L20.2947 242.8C9.9276 242.345 1.52344 232.328 1.52344 220.427V172.256C1.52344 160.356 7.31147 152.454 18.6174 150.251Z'
        fill='url(#paint0_linear_9645_97745)'
      />
      <g opacity='0.269475'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M21.3863 156.294L88.115 146.242C99.0497 145.84 104.566 151.646 104.566 163.414V219.089C104.566 230.857 97.0093 240.064 87.6873 239.655L22.8944 239.513C13.5725 239.103 6.01562 230.096 6.01562 219.395V176.081C6.01562 165.38 11.2201 158.275 21.3863 156.294Z'
          fill='url(#paint1_linear_9645_97745)'
          // style='mix-blend-mode:multiply'
        />
      </g>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M110.812 169.317L141.947 190.154C145.94 192.827 148.337 197.315 148.337 202.121V215.179L112.258 191.048L110.812 169.317Z'
        fill='#12204C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.6495 161.156C5.28399 156.373 13.564 151.746 13.564 151.746C13.564 151.746 -1.081 156.242 0.105024 187.023C0.0355346 187.699 0 188.383 0 189.073V242.595C0 254.496 10.5636 264.512 23.5944 264.968L111.472 267.233C124.503 267.689 136.863 258.351 136.863 245.264L137.761 186.404C137.761 173.317 127.197 163.077 114.166 163.533L38.4113 166.182C26.6092 166.418 12.42 166.514 8.6495 161.156Z'
        fill='url(#paint2_linear_9645_97745)'
      />
      <g opacity='0.2'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M117.281 205.107C117.281 205.107 128.03 201.667 132.736 197.602C137.442 193.537 137.717 187.454 137.717 187.454V205.107H117.281Z'
          fill='#565656'
          // style='mix-blend-mode:multiply'
        />
      </g>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M137.866 202.085C148.295 199.473 145.061 192.681 143.352 191.307C146.374 193.245 148.331 197.855 148.331 200.402C148.331 200.408 148.331 200.414 148.331 200.421C148.335 200.42 148.338 200.419 148.342 200.418C148.878 209.273 148.75 218.952 148.575 225.031C148.474 228.534 145.747 231.36 142.256 231.666L123.262 233.334C114.651 234.09 107.242 227.303 107.242 218.659C107.242 210.925 113.222 204.509 120.926 203.827C126.322 203.349 132.5 202.748 137.866 202.085Z'
        fill='url(#paint3_linear_9645_97745)'
      />
      <ellipse
        cx='122.843'
        cy='218.932'
        rx='7.99165'
        ry='8.0263'
        fill='url(#paint4_linear_9645_97745)'
      />
      <path
        d='M2.45312 172.643C5.41993 181.078 20.0996 182.634 25.5655 183.078C31.0314 183.522 126.406 184.834 135.87 177.078'
        stroke='#B5B8C1'
        stroke-width='3'
        stroke-linecap='round'
        stroke-dasharray='9'
      />
      <path
        d='M1.55469 240.478C4.37383 248.963 19.0416 248.775 24.4989 249.315C29.9562 249.854 125.276 254.83 134.873 247.24'
        stroke='#B5B8C1'
        stroke-width='3'
        stroke-linecap='round'
        stroke-dasharray='9'
      />
      <path
        d='M36.9135 156.309C22.9176 120.006 74.5468 85.0651 88.9913 100.362C97.5 114 92 125.078 67.9017 125.078C47.41 123.102 35.2976 104.776 39.338 85.0651C47.3528 45.9658 83.9022 41.802 86.5 40'
        stroke='url(#paint5_linear_9645_97745)'
        stroke-width='5'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-dasharray='6 15'
      />
      <ellipse
        opacity='0.4'
        cx='88.352'
        cy='16.3522'
        rx='9.98462'
        ry='13.1406'
        transform='rotate(-45 88.352 16.3522)'
        fill='#CED8E5'
      />
      <ellipse
        opacity='0.4'
        cx='113.765'
        cy='40.8206'
        rx='9.98462'
        ry='13.1406'
        transform='rotate(135 113.765 40.8206)'
        fill='#CED8E5'
      />
      <path
        d='M92.8594 27.1425C92.8594 22.669 96.4859 19.0425 100.959 19.0425H110.106V28.1612C110.106 32.6347 106.479 36.2612 102.006 36.2612H92.8594V27.1425Z'
        fill='#A8B7C5'
      />
      <mask
        id='mask0_9645_97745'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='92'
        y='19'
        width='19'
        height='18'
      >
        <path
          d='M92.8594 27.1425C92.8594 22.669 96.4859 19.0425 100.959 19.0425H110.106V28.1612C110.106 32.6347 106.479 36.2612 102.006 36.2612H92.8594V27.1425Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_9645_97745)'>
        <rect
          x='89.8438'
          y='23.8311'
          width='2.72308'
          height='19.9375'
          transform='rotate(-45 89.8438 23.8311)'
          fill='#DDE5F2'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M95.2891 19.3L97.2146 17.3745L111.313 31.4725L109.387 33.398L95.2891 19.3Z'
          fill='#DDE5F2'
        />
      </g>
      <path d='M119.367 14.7286L125.992 13.152' stroke='#8696C5' stroke-width='0.9' />
      <path d='M116.648 12.0098L119.197 5.45264' stroke='#8696C5' stroke-width='0.9' />
      <ellipse cx='112.832' cy='17.23' rx='7.26154' ry='7.25' fill='#8B9AA8' />
      <defs>
        <linearGradient
          id='paint0_linear_9645_97745'
          x1='59.1355'
          y1='120.606'
          x2='33.8102'
          y2='166.972'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#405189' />
          <stop offset='1' stop-color='#384982' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_9645_97745'
          x1='57.8195'
          y1='130.097'
          x2='36.0314'
          y2='171.141'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#405189' />
          <stop offset='1' stop-color='#394B88' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_9645_97745'
          x1='-10.2249'
          y1='247.914'
          x2='29.1926'
          y2='247.914'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#7293FF' />
          <stop offset='1' stop-color='#405189' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_9645_97745'
          x1='148.727'
          y1='210.329'
          x2='136.851'
          y2='210.329'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#88A4FF' />
          <stop offset='1' stop-color='#6E8AE6' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_9645_97745'
          x1='132.611'
          y1='198.192'
          x2='116.484'
          y2='210.792'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#405189' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_9645_97745'
          x1='51.9921'
          y1='28.6935'
          x2='-2.71693'
          y2='69.1135'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C8D5E5' />
          <stop offset='1' stop-color='#E9E9E9' stop-opacity='0.645663' />
        </linearGradient>
      </defs>
    </svg>
  )
}
