import React from 'react'

export default function Delete() {
  return (
    <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.449707' width='32' height='32' rx='6' fill='#D81923' fill-opacity='0.1' />
      <path
        d='M11.9497 13V22C11.9497 22.8284 12.6213 23.5 13.4497 23.5H19.4497C20.2781 23.5 20.9497 22.8284 20.9497 22V13H11.9497Z'
        fill='#D81923'
      />
      <path
        opacity='0.5'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.9497 10.375V10.25C17.9497 9.69772 17.502 9.25 16.9497 9.25H15.9497C15.3974 9.25 14.9497 9.69772 14.9497 10.25V10.375H11.6997C11.4236 10.375 11.1997 10.5989 11.1997 10.875V11C11.1997 11.2761 11.4236 11.5 11.6997 11.5H21.1997C21.4759 11.5 21.6997 11.2761 21.6997 11V10.875C21.6997 10.5989 21.4759 10.375 21.1997 10.375H17.9497Z'
        fill='#D81923'
      />
    </svg>
  )
}
