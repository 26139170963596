import {Stack} from '@mui/material'
import React from 'react'
import EmptyTax from './EmptyTax'
import FormTax from './FormTax'
import EmptyFormTax from './EmptyFormTax'

export default function Tax() {
  const isActive = !true
  return <Stack>{isActive ? <EmptyTax /> : <EmptyFormTax isEdit />}</Stack>
}
