import {FormikErrors, FormikTouched, useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'

import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import * as yup from 'yup'
import {RootState, useDispatch, withToastr} from '../../../../store'
import {
  createItemBulkAction,
  getBulkById,
  removeBulkAction,
  updateItemBulkAction,
} from '../../../../store/vender/products/thunk'
import {useValidationSchemaBulk} from '../validation/product/bluk'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import ErrorMsg from '../../../customTable/ErrorMsg'
import {toast} from 'react-toastify'
import DisCountItem from './DisCountItem'
import MainBtn from '../../../customTable/MainBtn'

export interface BulkDiscount {
  min: number
  max: number
  is_fixed: number
  value: number
  id?: number
}

interface InitialValues {
  optionDetails: Array<BulkDiscount>
}

export function BulkDiscountDetails({activeKey}: any) {
  const {t} = useTranslation()

  const [rowSlectedToEdit, setRowSlectedToEdit] = useState<number>(-1)
  const {id} = useParams<{id?: string}>()
  const dispatch = useDispatch()

  const {bulkDiscount} = useSelector((state: RootState) => state.product)
  // isCreated
  const [isCreated, setIsCreated] = useState<boolean>(false)

  const [isEditing, setIsEditing] = useState(true)

  const {validationSchema, initialValues} = useValidationSchemaBulk({bulkDiscount})

  const formik = useFormik<InitialValues>({
    initialValues: initialValues || {optionDetails: []}, // add conditional check here
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {},
  })
  const intl = useIntl()

  //   isAllow  to  edit  if  the  last  item  is valid  then  we  can  make  add  else  will  be  disable
  const isAllowToAdd = useMemo(() => {
    const isAllow =
      formik.values.optionDetails.length > 0 &&
      formik.values.optionDetails[formik.values.optionDetails.length - 1].min &&
      formik.values.optionDetails[formik.values.optionDetails.length - 1].max &&
      formik.values.optionDetails[formik.values.optionDetails.length - 1].is_fixed &&
      formik.values.optionDetails[formik.values.optionDetails.length - 1].value
    return isAllow
  }, [formik.values.optionDetails])
  const [activeAdd, setActiveAdd] = useState(true)

  //   fetch  the  producgetBulkById
  const getBUlks = useCallback(async () => {
    if (id) {
      dispatch(getBulkById({id}))
    }
  }, [id, dispatch])
  useEffect(() => {
    // getBUlks()
    activeKey === 'Bulk_Discount' && id && getBUlks()
  }, [getBUlks, id, activeKey])

  //  useCallBack  handleRemove

  return (
    <div>
      <div className='card card-flush py-4 mb-4 '>
        {/* {JSON.stringify(formik.values.optionDetails)} */}
        <div className='card-body pt-0'>
          {formik?.values?.optionDetails?.map((option, index: number) => {
            return (
              <DisCountItem
                rowSlectedToEdit={rowSlectedToEdit}
                index={index}
                formik={formik}
                id={id}
                setIsEditing={setIsEditing}
                setRowSlectedToEdit={setRowSlectedToEdit}
                // handelSaveAndUpdate={handelSaveAndUpdate}
                setIsCreated={setIsCreated}
                setActiveAdd={setActiveAdd}
                // msgErr={msgErr}
              />
            )
          })}
        </div>
        {/*  add  more   */}
      </div>
      <div className='row mb-8 g-4 bg-transparent my-4 '>
        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
          <div className='py-6'>
            <MainBtn
              backageKey='BULK_DISCOUNT_NUM'
              num={formik?.values?.optionDetails?.length}
              width='auto'
              secondary
              handelSubmit={() => {
                setActiveAdd(false)

                formik.setFieldValue(`optionDetails.${formik.values.optionDetails.length}`, {
                  min: 0,
                  max: 0,
                  is_fixed: 1,
                  value: 0,
                })

                setRowSlectedToEdit(formik.values.optionDetails.length)
              }}
              disabled={!activeAdd}
              text='bulkDiscount'
            />
            {/* <button
              type='button'
              disabled={!activeAdd}
              // disabled={isAllowToAdd ?false : true}
              onClick={() => {
                setActiveAdd(false)

                formik.setFieldValue(`optionDetails.${formik.values.optionDetails.length}`, {
                  min: 0,
                  max: 0,
                  is_fixed: 1,
                  value: 0,
                })

                setRowSlectedToEdit(formik.values.optionDetails.length)
              }}
              className='btn btn-light-danger btn-sm mb-10'
            >
              <span className='svg-icon svg-icon-2'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='11'
                    y='18'
                    width='12'
                    height='2'
                    rx='1'
                    transform='rotate(-90 11 18)'
                    fill='currentColor'
                  ></rect>
                  <rect x='6' y='11' width='12' height='2' rx='1' fill='currentColor'></rect>
                </svg>
              </span>
              {t('bulkDiscount')}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}
