import {Stack} from '@mui/material'
import React from 'react'

export default function Image() {
  return (
    <Stack
      borderRadius={'6.19px'}
      width={'86px'}
      height={'86px'}
      alignItems={'center'}
      justifyContent={'center'}
      boxShadow={'0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.08)'}
      bgcolor={'#fff'}
    >
      <svg
        width='86'
        height='86'
        viewBox='0 0 86 86'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_9159_22055)'>
          <path d='M86 0H0V86H86V0Z' fill='white' />
          <mask
            id='mask0_9159_22055'
            // style='mask-type:luminance'
            maskUnits='userSpaceOnUse'
            x='18'
            y='18'
            width='50'
            height='50'
          >
            <path d='M67.4617 18.6328H18.6328V67.4617H67.4617V18.6328Z' fill='white' />
          </mask>
          <g mask='url(#mask0_9159_22055)'>
            <path
              d='M31.3477 56.3357C28.0906 56.3357 25.1933 54.2502 24.1412 51.1454L24.0701 50.9115C23.822 50.0893 23.718 49.3979 23.718 48.7061V34.834L18.7822 51.3102C18.1474 53.7335 19.594 56.246 22.0211 56.9154L53.482 65.3409C53.8748 65.4425 54.2674 65.4914 54.6541 65.4914C56.6804 65.4914 58.5318 64.1465 59.0508 62.1646L60.8838 56.3357H31.3477Z'
              fill='#DCE5F1'
            />
            <path
              d='M36.9443 34.9725C39.1885 34.9725 41.0133 33.1475 41.0133 30.9033C41.0133 28.6591 39.1885 26.834 36.9443 26.834C34.7001 26.834 32.875 28.6591 32.875 30.9033C32.875 33.1475 34.7001 34.9725 36.9443 34.9725Z'
              fill='#DCE5F1'
            />
            <path
              d='M62.3786 20.7295H31.8598C29.0564 20.7295 26.7734 23.0124 26.7734 25.8162V48.1964C26.7734 51.0002 29.0564 53.2831 31.8598 53.2831H62.3786C65.1824 53.2831 67.4653 51.0002 67.4653 48.1964V25.8162C67.4653 23.0124 65.1824 20.7295 62.3786 20.7295ZM31.8598 24.7987H62.3786C62.9404 24.7987 63.396 25.2543 63.396 25.8162V40.2597L56.9689 32.76C56.2871 31.9605 55.3002 31.5332 54.2403 31.509C53.1864 31.5149 52.1977 31.9829 51.5222 32.7928L43.9656 41.8627L41.5038 39.407C40.1123 38.0155 37.8477 38.0155 36.4581 39.407L30.8427 45.0205V25.8162C30.8427 25.2543 31.2984 24.7987 31.8598 24.7987Z'
              fill='#DCE5F1'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_9159_22055'>
            <rect width='86' height='86' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Stack>
  )
}
