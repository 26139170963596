import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import TitlePage from '../customTable/TitlePage'
import iMedia from '../../interface/iMedia'
import {Link, useNavigate} from 'react-router-dom'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import MainBtn from '../customTable/MainBtn'
export default function ServesFees() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('servesFees'),
    home: t('home'),
    linkHome: '/',
  }
  const [rows, setRows] = useState<iMedia[]>([])
  const [added, setAdded] = useState(false)
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
      </Stack>
      <Table added={added} rows={rows} setRows={setRows} />
    </Stack>
  )
}
