import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import LabelWithValue from './LabelWithValue'
import LabelWithInput from './LabelWithInput'

export default function Invoice() {
  const [data, setData] = useState({
    test: 'text',
    Features: ['test', 'test', 'test', 'test', 'test', 'test', 'test'],
  })
  return (
    <Stack gap='24px' p='32px'>
      <Stack gap='24px' p='32px' borderRadius={'8px'} border='1px solid #EFF0F6'>
        <Label fw='600' fs='18px' text='subscribedThrough' />
        <LabelWithValue label='name' value='test' />
        <LabelWithValue label='type' value='test' />
        <LabelWithValue label='email' value='test' />
        <LabelWithValue label='country' value='test' />
        <LabelWithValue label='city' value='test' />
      </Stack>
      <Stack
        gap='24px'
        flexWrap={'wrap'}
        direction={'row'}
        p='32px'
        borderRadius={'8px'}
        border='1px solid #EFF0F6'
      >
        <Stack width={{md: '50%', xs: '100%'}} gap='24px'>
          <Label fw='600' fs='18px' text='paymentDetails' />
          <LabelWithValue label='invoiceID' value='test' />
          <LabelWithValue label='subTotalAmount' value='test' />
          <LabelWithValue label='totalAmount' value='test' />
          <LabelWithValue label='discountAmount' value='test' />
          <LabelWithValue label='tax' value='test' />
        </Stack>
        <Stack gap='24px'>
          <Label fw='600' fs='18px' text='subscriptionsDate' />
          <LabelWithValue label='startDate' value='test' />
          <LabelWithValue label='endDate' value='test' />
        </Stack>
      </Stack>
      <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
        <LabelWithInput name={'test'} text={'subscriptionStatus'} data={data} setData={setData} />
        <LabelWithInput name={'test'} text={'packageName'} data={data} setData={setData} />
        <LabelWithInput name={'test'} text={'numberOfProducts'} data={data} setData={setData} />
        <LabelWithInput name={'test'} text={'numberOfCategories'} data={data} setData={setData} />
      </Stack>
      <Stack gap='24px'>
        <Label fw='600' text='packageFeatures' />
        {data.Features.map((e: any, i: any) => (
          <Label text={`${i + 1}- ${e}`} />
        ))}
      </Stack>
    </Stack>
  )
}
