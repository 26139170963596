import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import InvoEn from './InvoEn'

export default function ShowInvo({open, setOpen, invoiceData}: any) {
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <></>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {},
        }}
      >
        <InvoEn invoiceData={invoiceData} />
      </Dialog>
    </div>
  )
}
