import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import Backages from './Backages'
import ActiveForm from './ActiveForm'

export default function ApplicationMaker({android}: any) {
  const {t} = useTranslation()
  const [activeBackage, setActiveBackage] = useState(!false)
  const bannerInfo = {
    title: t('applicationMaker'),
    home: t('home'),
    linkHome: '/',
  }
  return (
    <Stack>
      <TitlePage info={bannerInfo} />
      <Stack mt='24px'>
        {!activeBackage ? <Backages android={android} /> : <ActiveForm android={android} />}
      </Stack>
    </Stack>
  )
}
