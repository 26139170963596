/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'

import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {Stack} from '@mui/material'
import DrawerComponent from '../../DrowerSideBar'
import Logo from '../../../../app/svg/Logo'
export function HeaderWrapper({activeMenu}: any) {
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }
  const image = localStorage.getItem('image')
  return (
    <Stack
      sx={{
        top: '0',
        bgcolor: 'var(--bs-app-bg-color)',
        position: 'sticky !important',
        zIndex: '500',
      }}
      // height={'100px'}
      width={'100%'}
    >
      <Stack
        width={'100%'}
        sx={{
          // left: '30px !important',
          // right: '30px !important',

          height: 'auto',
          borderRadius: '12px',
          padding: {xs: '25px 0 0 0'},
          // bgcolor: 'var(--bs-app-bg-color)',
          boxShadow: 'none',
          // position: 'relative !important',
        }}
        // id='kt_app_header'
        // className='app-header'
      >
        <Stack
          width={'100%'}
          boxShadow={'var(--bs-app-header-base-box-shadow)'}
          borderRadius='12px'
          bgcolor={'var(--bs-app-footer-bg-color)'}
        >
          <Stack
            width={'100%'}
            direction={'row'}
            justifyContent={'space-between'}
            p='15px 0'
            alignItems={'center'}
            id='kt_app_header_container'
            className={clsx(
              'app-container flex-lg-grow-1',
              classes.headerContainer.join(' '),
              config.app?.header?.default?.containerClass
            )}
          >
            {config.app.sidebar?.display && (
              <>
                <Stack
                  display={{xs: 'block', md: 'none'}}
                  // m='15px'
                  borderRadius={'8px'}
                  direction={'row'}
                  alignItems={'center'}
                  // className='d-flex align-items-center d-lg-none ms-n2 me-2'
                  title='Show sidebar menu'
                >
                  <DrawerComponent />
                </Stack>
              </>
            )}

            {!config.app.sidebar?.display && (
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
                <Link to='/dashboard'>
                  <Logo />
                </Link>
              </div>
            )}
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              // id='kt_app_header_wrapper'
              // className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
            >
              <Stack>
                <Stack sx={{display: {xs: 'none', md: 'block'}}}>
                  <Link to='/dashboard'>
                    <Logo />
                  </Link>
                </Stack>
              </Stack>

              <Navbar />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

{
  /* <Stack
  sx={{
    left: '30px !important',
    right: '30px !important',
    height: 'auto',
    borderRadius: '12px',
    width: '100%',
    // padding: '25px 30px 0 30px',
    // position: 'sticky !important',
    zIndex: '10',
  }}
>
  <Stack
    sx={{
      // left: '30px !important',
      // right: '30px !important',
      height: 'auto',
      borderRadius: '12px',
      padding: {xs: '25px 30px 0 30px'},
      bgcolor: 'var(--bs-app-bg-color)',
      boxShadow: 'none',
      // position: 'relative !important',
    }}
    id='kt_app_header'
    className='app-header'
  >
    <Stack
      boxShadow={'var(--bs-app-header-base-box-shadow)'}
      borderRadius='12px'
      bgcolor={'var(--bs-app-footer-bg-color)'}
    >
      <Stack
        direction={'row'}
        p='15px 0'
        alignItems={'center'}
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <Stack
              // m='15px'
              borderRadius={'8px'}
              direction={'row'}
              alignItems={'center'}
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <DrawerComponent />
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/dashboard' className='d-lg-none'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/avatars/logo.svg')}
                    className='h-30px'
                  />
                </Link>
              </div>
            </Stack>
          </>
        )}

        {!config.app.sidebar?.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/dashboard'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-small.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </Stack>
    </Stack>
  </Stack>
</Stack> */
}
