import {Box, Button, IconButton, Stack} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {useTranslation} from 'react-i18next'

export default function UploadImage({base64Image, setBase64Image}: any) {
  const [image, setImage] = useState(base64Image || '/media/avatars/img.svg')
  useEffect(() => {
    if (!base64Image) {
      setImage('')
    }
  }, [])
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setBase64Image(reader.result)
      setImage(reader.result as string)
    }
    reader.onerror = (error) => {}
  }
  const {t} = useTranslation()

  return (
    <Stack alignItems={'center'} mt='15px'>
      <Stack
        width='120px'
        height={'120px'}
        sx={{
          boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
          borderRadius: '7px',
        }}
      >
        <Stack width='100%' height='100%'>
          <Button
            sx={{color: 'red', width: '100%', height: '100%', padding: '0', borderRadius: '7px'}}
            component='label'
          >
            <img
              style={{width: '100%', height: '100%', borderRadius: '7px'}}
              src={base64Image || image}
              alt=''
            />
          </Button>
        </Stack>
      </Stack>
      <Stack mt='15px' gap={'5px'} alignItems={'center'} direction={'row'}>
        <Box
          sx={{
            border: '1px solid var(--main-color)',
            padding: '12px 20px',
            color: 'var(--main-color)',
            borderRadius: '8px',
          }}
          component='label'
        >
          <input hidden accept='image/*' onChange={handleImageChange} multiple type='file' />
          {t('change')}
        </Box>
        <IconButton
          onClick={() => {
            setImage('/media/avatars/img.svg')
            setBase64Image('')
          }}
        >
          <DeleteIcon sx={{color: 'var(--orange-color)'}} />
        </IconButton>
      </Stack>
    </Stack>
  )
}
