import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import IBanner from '../interface/IBanner'
import ICuopon from '../interface/ICuopon'
import ApisMethods from './apisMethods'

const Payment = {
  getHistory: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/payment-history?page=${page}&per_page=${rowsPerPage}&search=${searchText}`,
      callback,
      setIsLoading
    )
  },
  putStatus: (id: number | undefined, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/medias/status/${id}`, {status: status}, callback, setIsLoading)
  },

  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/medias`, data, callback, setIsLoading)
  },
  put: (id: any, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/medias/${id}`, data, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/medias/${id}`, callback, setIsLoading)
  },
  getMediaAttachment: async (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/medias/get/attachments`, callback, setIsLoading)
    const media = await Apiservices.get(`/vendor/medias/get/attachments`)

    return media
  },
}
export default Payment
