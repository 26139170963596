import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import BasicSpeedDial from './Actions'
import EditOption from './EditOption'
import Product from '../../../../../Api/product'
import {useParams} from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import DeletedMsg from '../../../../customTable/DeleteMsg'
import SimpleLoading from '../../../../customTable/SimpleLoading'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export default function Row({
  item,
  optionValue,
  setOptionValue,
  type,
  values,
  option_id,
  count,
  setCount,
  isSelected,
  setIsSelected,
  options,
  setIsGetOption,
  setLoading,
  getSelectedOptions,
}: any) {
  const [open, setOpen] = React.useState(false)
  const [isDelete, setIsDelete] = useState(true)
  const [editItem, setEditItem] = useState(item)
  const {id} = useParams()
  const [openTost, setOpenTost] = React.useState(false)
  const store = useSelector((state: RootState) => state.vender)
  const [openDelete, setOpenDelete] = useState(false)
  const [isloadingDelete, setIsLoadingDelete] = useState(false)
  const handelDelete = () => {
    if (item.id) {
      if (count === 1) {
        setOpenTost(true)
      } else {
        setCount(count - 1)
        Product.destroyOptionProduct(
          item.id,
          id,
          values.id,
          () => {
            setIsSelected(isSelected.filter((e: number) => e !== item.option_detail_id))

            setIsDelete(false)
          },
          setIsLoadingDelete
        )
      }
    } else {
      setLoading(true)

      setIsSelected(isSelected.filter((e: number) => e !== item.option_value_id))

      setOptionValue(optionValue.filter((e: any) => e.idDate !== item.idDate))
      setTimeout(() => {
        setLoading(false)
      }, 100)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenTost(false)
  }

  return (
    <>
      {isDelete && (
        <Stack
          sx={{
            borderTop: '1px solid var(--bs-gray-300)',
            borderBottom: '1px solid var(--bs-gray-300)',
          }}
          direction={'row'}
          width='100%'
        >
          <Stack width={'32%'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
            <Typography onClick={() => {}}>
              {editItem.option_value_local && editItem.option_value_local.option_value
                ? editItem.option_value_local.option_value[store.lang] ||
                  editItem.option_value_local.option_value[store.defLang]
                : editItem.option_detail
                ? editItem.option_detail.option_value[store.lang] ||
                  editItem.option_detail.option_value[store.defLang]
                : editItem.option_value &&
                  (editItem.option_value[store.lang] || editItem.option_value[store.defLang])
                ? editItem.option_value[store.lang] || editItem.option_value[store.defLang]
                : 'New Option'}
            </Typography>
          </Stack>
          <Stack width={'40%'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
            <Typography>{editItem.price_value} </Typography>
          </Stack>
          <Stack sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}} width={'24%'}>
            <Typography>{editItem.sort}</Typography>
          </Stack>
          <Stack width={'42px'}>
            {isloadingDelete ? (
              <SimpleLoading />
            ) : (
              <BasicSpeedDial
                setOpen={setOpen}
                setOptionValue={setOptionValue}
                optionValue={optionValue}
                item={editItem}
                valuesId={values.id}
                setIsDelete={setIsDelete}
                count={count}
                setCount={setCount}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                handelDelete={handelDelete}
                openTost={openTost}
                setOpenTost={setOpenTost}
                setOpenDelete={setOpenDelete}
              />
            )}
          </Stack>
          <EditOption
            type={type}
            option_id={option_id}
            values={values}
            item={editItem}
            open={open}
            setOpen={setOpen}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
            setEditItem={setEditItem}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            options={options}
            setIsGetOption={setIsGetOption}
            getSelectedOptions={getSelectedOptions}
          />
          <DeletedMsg open={openDelete} setOpen={setOpenDelete} onClick={handelDelete} />
          <Snackbar open={openTost} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity='warning' sx={{width: '100%'}}>
              The last saved option cannot be deleted
            </Alert>
          </Snackbar>
          {/* <button onClick={handelDelete}>{item.option_value_id}</button> */}
        </Stack>
      )}
    </>
  )
}
