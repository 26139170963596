import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function PopUp({children, open, setOpen, width}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <></>

      <Dialog
        dir={store.isRtl !== 0 ? 'rtl' : 'ltr'}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)',
            m: {xs: '10px', md: '20px', xl: '32px'},
            borderRadius: '16px',
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
          },
        }}
        fullWidth={width ? true : false}
        maxWidth={width}
      >
        {children}
      </Dialog>
    </div>
  )
}
