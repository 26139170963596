import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import ImgMsg from './ImgMsg'
import ErrorMsg from './NewMsgErr'

export default function UploadImg({base64Image, setBase64Image, errors, name}: any) {
  const [image, setImage] = useState(base64Image || '/media/avatars/img.svg')

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setBase64Image(reader.result)
      setImage(reader.result as string)
    }
    reader.onerror = (error) => {}
  }

  return (
    <>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack width='100%' height='100%'>
          <Button
            sx={{width: '100%', height: '100%', padding: '10px', borderRadius: '7px'}}
            component='label'
          >
            <img style={{width: '100%', height: '100%'}} src={image} alt='' />
            <input
              hidden
              accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
            />
          </Button>
        </Stack>
      </Tooltip>
      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </>
  )
}
