import React from 'react'

export default function Nike({color}: any) {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.99479 1.33594C4.31479 1.33594 1.32812 4.3226 1.32812 8.0026C1.32812 11.6826 4.31479 14.6693 7.99479 14.6693C11.6748 14.6693 14.6615 11.6826 14.6615 8.0026C14.6615 4.3226 11.6748 1.33594 7.99479 1.33594ZM6.1899 10.863L3.79656 8.46964C3.53656 8.20964 3.53656 7.78964 3.79656 7.52964C4.05656 7.26964 4.47656 7.26964 4.73656 7.52964L6.66323 9.44964L11.2499 4.86297C11.5099 4.60297 11.9299 4.60297 12.1899 4.86297C12.4499 5.12297 12.4499 5.54297 12.1899 5.80297L7.1299 10.863C6.87656 11.123 6.4499 11.123 6.1899 10.863Z'
        fill={color}
      />
    </svg>
  )
}
