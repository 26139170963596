import React, {useState} from 'react'
import MySelect from '../../customTable/CustomSelectTest'

export default function LangSelect({
  name,
  values,
  option,
  disabled,
  value,
  setValues,
  selectedLang,
  setSelectedLang,
}: {
  name: string
  values: any
  option: any
  disabled?: boolean | undefined
  value?: any
  setValues: any
  selectedLang: number[]
  setSelectedLang: React.Dispatch<React.SetStateAction<number[]>>
}) {
  const [selectedOption, setSelectedOption] = useState<any>(value)

  const handleChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = option.find(
      (item: any) => item.id === selectedId || item.value === selectedId
    )
    setSelectedOption(selectedItem)
    setSelectedLang([...selectedLang, +event.target.value])
    setValues({...values, [`${name}`]: +event.target.value})

    // values[`${name}`] = +event.target.value
  }

  return (
    <MySelect
      values={values}
      option={[{name: 'test', id: 1}]}
      setValues={setValues}
      name='discount_type'
      value={{id: -1, name: 'ff'}}
    />
  )
}
