import {Button, Stack, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import {useTranslation} from 'react-i18next'

export default function UpdateFile({base64Image, setBase64Image, setting, setSeeting, name}: any) {
  const [image, setImage] = useState(base64Image || null)
  const {t} = useTranslation()

  useEffect(() => {
    if (base64Image) {
      setImage(base64Image)
    }
  }, [base64Image])
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    setSeeting({...setting, [`${name}`]: event.target.files[0]})
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setBase64Image(reader.result)
      setImage(reader.result as string)
    }
    reader.onerror = (error) => {}
  }

  return (
    <Stack component='label' width={'100%'} height={'100%'} sx={{display: 'inline-flex'}}>
      <Button
        variant='contained'
        style={{
          color: 'red',
          background: ' rgba(216, 25, 35, 0.1)',
          borderRadius: '6px',
          border: 'none',
          // marginTop: '10px',
          padding: '12px 33px',
          boxShadow: 'none',
          width: '120px',
        }}
        component='span'
      >
        {t('upload')}
      </Button>
      <input
        accept='image/*'
        id='uploadFile'
        onChange={handleImageChange}
        multiple
        type='file'
        style={{display: 'none'}}
      />
    </Stack>
  )
}
