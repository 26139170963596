import {Paper, Grid, Typography, Stack} from '@mui/material'
import {Box} from '@mui/system'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ShowInvo from './ShowInvo'
import {format, parseISO} from 'date-fns'

export default function Cards({order}: any) {
  const cardStyle = {
    height: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const [open, setOpen] = React.useState(false)

  return (
    <div style={{width: '100%'}}>
      <Grid container spacing={2} sx={{justifyContent: 'center'}}>
        <Grid item xs={12} md={6} lg={4}>
          {/* <Paper sx={cardStyle}> */}
          <Stack
            p='32px'
            height={'100%'}
            minHeight={'301px'}
            bgcolor={`var(--bs-body-bg)`}
            width={'100%'}
            borderRadius={'8px'}
            // gap='32px'
            // justifyContent={'center'}
          >
            <Typography sx={{fontSize: '19px', fontWeight: '600'}}>
              {t('orderDetails')} ({order.order_number})
            </Typography>
            <Stack
              borderBottom='1px solid var(--bs-gray-300)'
              p='32px 0 16px 0'
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/1.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {t('dateAdded')}
                </Typography>
              </Stack>
              <Typography
                sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
              >
                {order.created_at}
                {/* {order.created_at && format(parseISO(order.created_at), 'yyyy-MM-dd')} */}
              </Typography>
            </Stack>
            <Stack
              borderBottom='1px solid var(--bs-gray-300)'
              p='16px 0 16px 0'
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/3.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {' '}
                  {t('paymentMethod')}
                </Typography>
              </Stack>
              <Typography
                sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
              >
                {' '}
                {order.payment_method && order.payment_method[store.lang]}
              </Typography>
            </Stack>
            {/* <Stack p='16px 0 16px 0' justifyContent={'space-between'} direction={'row'}>
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/4.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {t('shippingMethod')}
                </Typography>
              </Stack>
              <Typography
                sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
              >
                {t('dateAdded')}
              </Typography>
            </Stack> */}
          </Stack>
          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack
            p='32px'
            height={'100%'}
            minHeight={'301px'}
            bgcolor={`var(--bs-body-bg)`}
            width={'100%'}
            borderRadius={'8px'}

            // gap='32px'
            // justifyContent={'center'}
          >
            <Typography sx={{fontSize: '19px', fontWeight: '600'}}>
              {t('customerDetails')}
            </Typography>
            <Stack
              borderBottom='1px solid var(--bs-gray-300)'
              p='32px 0 16px 0'
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/5.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {t('customer')}
                </Typography>
              </Stack>
              <Stack gap='4px' direction={'row'}>
                <img src={order.user && order.user.image} alt='' style={{width: '19px'}} />

                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {order.user && order.user.full_name}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              borderBottom='1px solid var(--bs-gray-300)'
              p='16px 0 16px 0'
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/6.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {' '}
                  {t('email')}
                </Typography>
              </Stack>
              <Typography
                sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
              >
                {' '}
                {order.email}
              </Typography>
            </Stack>
            <Stack p='16px 0 16px 0' justifyContent={'space-between'} direction={'row'}>
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/7.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {t('phone')}
                </Typography>
              </Stack>
              <Typography
                sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
              >
                {order.phone}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Stack
            p='32px'
            height={'100%'}
            minHeight={'301px'}
            bgcolor={`var(--bs-body-bg)`}
            width={'100%'}
            borderRadius={'8px'}

            // gap='32px'
            // justifyContent={'center'}
          >
            <Typography sx={{fontSize: '19px', fontWeight: '600'}}>{t('documents')}</Typography>
            <Stack
              borderBottom='1px solid var(--bs-gray-300)'
              p='32px 0 16px 0'
              justifyContent={'space-between'}
              direction={'row'}
              onClick={() => setOpen(true)}
              sx={{cursor: 'pointer'}}
            >
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/8.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {t('invoice')}
                </Typography>
              </Stack>
              <Stack gap='4px' direction={'row'}>
                <img src={order.user && order.user.image} alt='' style={{width: '19px'}} />

                <Typography sx={{fontSize: '14px', fontWeight: '600', color: '#009EF7'}}>
                  {order.user && order.order_number}
                </Typography>
              </Stack>
            </Stack>
            <ShowInvo invoiceData={order} open={open} setOpen={setOpen} />
            {/* <Stack
              borderBottom='1px solid var(--bs-gray-300)'
              p='16px 0 16px 0'
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Stack gap='6.5px' direction={'row'}>
                <Box>
                  <img src='/media/avatars/9.svg' alt='' style={{width: '19px'}} />
                </Box>
                <Typography
                  sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
                >
                  {' '}
                  {t('shipping')}
                </Typography>
              </Stack>
              <Typography
                sx={{fontSize: '14px', fontWeight: '600', color: 'var(--bs-text-gray-700)'}}
              >
                {' '}
                {order.email}
              </Typography>
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}
