import {useTranslation} from 'react-i18next'
// import {languages} from '../../../../types'
import ErrorMsg from '../../../customTable/ErrorMsg'
import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import OldInput from '../../../customTable/OldInput'
type Props<T> = {
  formik: any
  isEdit: boolean
  name: string
  msgErr: string
  errors?: any
}

export function LanguageRowInput<T>({isEdit, formik, name, errors}: Props<T>) {
  const {t} = useTranslation()
  const [languages, setlanguages] = useState<any>([])

  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguages(store.langs.map((e: any) => (e = {...e, label: e.lang, lang: e.code})))
  }, [store.langs])

  return (
    <div className='row mt-6'>
      {languages?.map((lang: any, index: any) => (
        <div key={index} className='col-lg-6 fv-row fv-plugins-icon-container'>
          <label className='required form-label'>
            {t('optionName') + ' '}
            {lang.code}{' '}
          </label>
          <OldInput errors={errors} child={lang.code} name={name} isEdit={isEdit} formik={formik} />
        </div>
      ))}
    </div>
  )
}
