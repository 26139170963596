import * as Yup from 'yup'

export const ProfileSchema = Yup.object().shape({
  mobile: Yup.number().required('mobileErr'),
  full_name: Yup.string().required('nameErr'),
  email: Yup.string().required('emailErr'),
})
export const LoginSchema = Yup.object().shape({
  mobile: Yup.string().required('mobileErr'),
  password: Yup.string().required('passwordErr'),
})
export const ForgetSchema = Yup.object().shape({
  mobile: Yup.string().required('mobilErr'),
})
export const ResetSchema = Yup.object().shape({
  new_password: Yup.string().required().min(6),
  new_password_confirmation: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'PasswordsMustMatch'
  ),
})
export const ResetVendorSchema = Yup.object().shape({
  old_password: Yup.string().required('currentPasswordErr'),
  new_password: Yup.string().required('newPasswordErr').min(2, 'MinNewPassword'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'passwordConfirmationErr')
    .required('passwordConfirmationErr'),
})
