import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en']
let languages = SetLang() || ['en']

export const GeneralSettingValidation = (languages: any) => {
  return Yup.object().shape({
    title: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`titleErr`),
        }
      }, {})
    ),

    email: Yup.string().required('emailErr'),
  })
}
export const AddressSettingValidation = (languages: any) => {
  return Yup.object().shape({
    address: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`addressErr`),
        }
      }, {})
    ),

    postal_code: Yup.string().required('postalCodeErr'),
  })
}
