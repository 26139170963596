import React from 'react'

export default function DeleteIcon() {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M60.0011 107.05H29.7011C12.3511 107.05 5.10113 94.6504 13.5011 79.5004L29.1011 51.4004L43.8011 25.0004C52.7011 8.95039 67.3011 8.95039 76.2011 25.0004L90.9011 51.4504L106.501 79.5504C114.901 94.7004 107.601 107.1 90.3011 107.1H60.0011V107.05Z'
        fill='#F16645'
        stroke='#F16645'
        stroke-width='8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M59.9727 85H60.0176'
        stroke='white'
        stroke-width='8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M60 45V70'
        stroke='white'
        stroke-width='8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
