import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en', 'ar']
export const MediaSchema = Yup.object().shape({
  title: Yup.string().required('titleErr'),

  attachment: Yup.mixed()
    .nullable() // يسمح بالقيمة null
    .test('is-valid', 'Invalid attachment', (value) => {
      if (value === null) return true // إذا كانت القيمة null، تعتبر صالحة

      // تحقق من أن القيمة هي ملف
      if (value instanceof File) {
        const allowedTypes = ['image/jpeg', 'image/png', 'video/mp4'] // أنواع الملفات المسموح بها
        return allowedTypes.includes(value.type)
      }

      // تحقق من أن القيمة هي سلسلة نصية
      if (typeof value === 'string') {
        return true // يمكنك إضافة تحقق إضافي للسلسلة النصية إذا لزم الأمر
      }

      return false // القيمة ليست ملفًا أو سلسلة نصية، تعتبر غير صالحة
    })
    .required('fileErr'),
})
