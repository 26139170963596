import {Stack, Typography} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import ITitleInfo from '../../interface/ITitleInfo'

export default function TitlePage({info}: any) {
  return (
    <Stack>
      <Typography sx={{fontSize: '1.23rem', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {info.title}
      </Typography>
      <Stack gap='7px' direction={'row'}>
        <Link style={{textDecoration: 'none'}} to={info.linkHome}>
          <Typography
            sx={{color: 'var(--bs-text-muted) !important', fontSize: '13px', fontWeight: '500'}}
          >
            {info.home}
          </Typography>
        </Link>
        <Typography
          sx={{color: 'var(--bs-text-muted) !important', fontSize: '13px', fontWeight: '500'}}
        >
          {info.title}
        </Typography>
      </Stack>
    </Stack>
  )
}
