import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import JwtService from '../utils/TokenServices'
export const baseURL = 'https://vendor.api.koninstore.com'

const Axios: AxiosInstance = axios.create({
  baseURL: `${baseURL}/api/v1`,
})

Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const user = localStorage.getItem('user-data') || ''
    //     const {token} = (user && (JSON.parse(user))) || {token: ''}
    //   take userLanguage from local storage
    const userLanguage = localStorage.getItem('i18nextLng') || 'en'
    const token = JwtService.getToken()
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Accept-Language': userLanguage,
      }
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default class API {
  static async get(url: string, config?: any): Promise<AxiosResponse> {
    try {
      return await Axios.get(url, config || {})
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        window.location.href = '/#/403'
      }
      throw error
    }
  }

  static async post<T>(url: string, body: T, config?: any): Promise<AxiosResponse> {
    try {
      return await Axios.post(url, body, config || {})
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        window.location.href = '/#/403'
      }
      throw error
    }
  }

  static async put<T>(url: string, body?: any): Promise<AxiosResponse> {
    try {
      return await Axios.put(url, body)
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        window.location.href = '/#/403'
      }
      throw error
    }
  }

  static async delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      return await Axios.delete(url, config || {})
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        window.location.href = '/#/403'
      }
      throw error
    }
  }
}
