import React, {useState, useEffect} from 'react'
import {ActionsButton} from './actionsButton'
import UploadImg from '../../../custom/UploadImg'
import {useParams} from 'react-router-dom'
import AddActionBtns from './AddActionBtn'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ErrorMsg from '../../customTable/NewMsgErr'
import OldInput from '../../customTable/OldInput'
// import ErrorMsg from '../../customTable/ErrorMsg'

export default function OptionColor({
  index,
  option,
  formik,
  disabled,
  handleRemoveOption,
  handleEditOption,
  Adderrors,
  setActiveAdd,
}: any) {
  const {id} = useParams<{
    id?: string
  }>()
  const {t} = useTranslation()

  const [isActive, setIsActive] = useState(id && option.option_id ? true : false)
  const [rowSlectedToEdit, setRowSlectedToEdit] = useState<number>(-1)

  const [isDelete, setIsDelete] = useState(false)
  const [isAllowToEdit, setIsAllowToEdit] = useState<boolean>(false)
  const handleRemove = async (index: number) => {
    handleRemoveOption(formik.values.optionDetails[index].id)
  }

  const handleEdit = async (index: number) => {
    handleEditOption(
      formik.values.optionDetails[index].id,
      formik.values.optionDetails[index]
    ).then((x: any) => {
      setRowSlectedToEdit(-1)
    })
  }

  const [err, setError] = useState('')
  const [errors, setErrors] = useState<any>({})
  const store = useSelector((state: RootState) => state.vender)
  const [langs, setLangs] = useState([])
  useEffect(() => {
    setLangs(store.langs)
  }, [store.langs])
  return (
    <>
      {!isDelete && (
        <div
          style={{padding: '24px', display: 'flex', justifyContent: 'center'}}
          key={index}
          className='row  custom-box  my-4 '
        >
          <div className='row'>
            {langs.map((lang: any) => (
              <div key={lang.code} className='col-lg-6 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold form-label mt-3'>
                  <span className='required'>
                    {t('optionValueName')} {lang.code.toUpperCase()}
                  </span>
                </label>

                <input
                  type='text'
                  className='form-control '
                  disabled={isActive}
                  {...formik.getFieldProps(`optionDetails.${index}.option_value.${lang.code}`)}
                />
                {(errors && errors[`option_value.${lang.code}`] && (
                  <ErrorMsg child={lang.code} msgErr={errors[`option_value.${lang.code}`]} />
                )) ||
                  (Adderrors && Adderrors[`optionDetails[${index}].option_value.${lang.code}`] && (
                    <ErrorMsg
                      child={lang.code}
                      msgErr={Adderrors[`optionDetails[${index}].option_value.${lang.code}`]}
                    />
                  ))}

                {err.includes(lang.code) && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{err}</div>
                  </div>
                )}
                {formik.errors[`option_value.${lang.code}`] &&
                  formik?.touched[`option_value.${index}.${lang.code}`] && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {formik.errors[`option_value.${index}.${lang.code}`] as React.ReactNode}
                      </div>
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className='row'>
            <div className='col-lg-6 fv-row fv-plugins-icon-container'>
              <label className='fs-6 fw-semibold form-label mt-3'>
                <span className='required'>{t('sort')}</span>
              </label>
              <input
                min={0}
                disabled={isActive}
                type='number'
                className='form-control '
                {...formik.getFieldProps(`optionDetails.${index}.sort`)}
              />
              {err.includes('sort') && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{err}</div>
                </div>
              )}
              {formik.errors[`optionDetails.${index}.sort`] &&
                formik.touched[`optionDetails.${index}.sort`] && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {formik.errors[`optionDetails.${index}.sort`] as string}
                    </div>
                  </div>
                )}
            </div>
            <div className='col-lg-6 fv-row fv-plugins-icon-container'>
              <label className='fs-6 fw-semibold form-label mt-3'>
                <span className='required'>{t('color')}</span>
              </label>
              <input
                type={'color'}
                className='form-control '
                style={{
                  minHeight: '40px',
                }}
                disabled={isActive}
                color={formik.values[`optionDetails.${index}.option_code`]}
                {...formik.getFieldProps(`optionDetails.${index}.option_code`)}
              />

              {formik.errors[`optionDetails.${index}.color`] && formik?.touched[`color.${index}`] && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors[`optionDetails.${index}.color`] as React.ReactNode}
                  </div>
                </div>
              )}
              <div className=' justify-content-end align-items-end d-flex'>
                {/*   SAME  FOR  EDIT  */}

                {id && option.option_id ? (
                  <ActionsButton
                    isAllowToEdit={isAllowToEdit && rowSlectedToEdit !== index}
                    index={index}
                    setIsAllowToEdit={setIsAllowToEdit}
                    handleRemove={handleRemove}
                    handleEdit={handleEdit}
                    isActive={isActive}
                    option={option}
                    setIsActive={setIsActive}
                    setIsDelete={setIsDelete}
                    setError={setError}
                    setErrors={setErrors}
                  />
                ) : null}
                <Stack gap='15px' direction={'row'} alignContent={'center'}>
                  {option.id === -1 && (
                    <button
                      style={{margin: '10px 0', background: 'var(--orange-color) !important'}}
                      type='button'
                      onClick={() => {
                        setActiveAdd(false)

                        formik.setFieldValue(
                          'optionDetails',
                          formik.values.optionDetails.filter(
                            (e: any) => e.deleteId !== option.deleteId
                          )
                          // formik.values.option_details.length - 2
                        )
                      }}
                      className='btn btn-icon btn-danger btn-sm'
                    >
                      <i className='bi bi-trash fs-7'></i>
                    </button>
                  )}
                  {id && !option.option_id && (
                    <AddActionBtns
                      id={id}
                      setError={setError}
                      formik={formik}
                      option={{...option}}
                      setIsDelete={setIsDelete}
                      setActiveAdd={setActiveAdd}
                      setErrors={setErrors}
                    />
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
