import {IconButton, Stack} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
export default function LangBtns({langs, selectedLang, setSelectedLang}: any) {
  console.log(langs, 'langs', selectedLang, 'selectedLang')

  return (
    <Stack mt='16px' gap='16px' flexWrap={'wrap'} direction={'row'}>
      {langs.map((e: any, i: number) => (
        <button
          style={{
            background: 'var(--main-color)',
            color: 'white',
            border: 'none',
            width: '137px',
            borderRadius: '6px',
            padding: '13px 0',
            fontSize: '14px',
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              border: '1px solid white',
              padding: '0',
            }}
            onClick={() => setSelectedLang(selectedLang.filter((a: any) => a !== e.id))}
          >
            <CloseIcon sx={{fontSize: '13.7px', color: 'white'}} />
          </IconButton>
          {e.lang}
        </button>
      ))}
    </Stack>
  )
}
