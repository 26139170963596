import {createAsyncThunk} from '@reduxjs/toolkit'
import store from '../..'
import API from '../../../Api'
import {languages} from '../../../types'
import {thunkCatchError} from '../../utils'
import {isBase64} from '../catogory/thunk'
import {IPageCatogories} from './types'

const MANUFACTURER = {
  GET_manufacturer: '/vendor/manufacturers',
  CHANGE_STATUS: '/vendor/manufacturers/status',
  GET_MANUFACTURER_NAME: 'vendor/manufacturers/get/names',
}

export const manufacturerAction = createAsyncThunk(
  'manufacturer/manufacturer',
  thunkCatchError(async ({page = 1, pre_page = 10, search}: IPageCatogories) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }
    const response = await API.get(`${MANUFACTURER.GET_manufacturer}${query}`)
    return (
      {
        data: response.data?.data,
        pages: response.data?.pages,
      } || {}
    )
  })
)

export const changeManufacturerStatusAction = createAsyncThunk(
  'manufacturer/changeManufacturerStatusAction',
  thunkCatchError(async ({id, status}: {id: string; status: number}) => {
    const response = await API.put(`${MANUFACTURER.CHANGE_STATUS}/${id}`, {
      status: status,
    })
    return response.data
  })
)
export const delateManufacturerAction = createAsyncThunk(
  'manufacturer/delateManufacturerAction',
  thunkCatchError(async ({id}: {id: string}) => {
    await API.delete(`${MANUFACTURER.GET_manufacturer}/${id}`)
    const {data} = store.getState().manufacturer.manufacturers
    const newData = data.filter((item: any) => item.id + '' !== id)

    return {
      data: newData,
    }
  })
)
//   add  option  to  store
export const addManufacturerAction = createAsyncThunk(
  'manufacturer/addManufacturerAction',
  thunkCatchError(async (data: any) => {
    if (!data.hasOwnProperty('image')) {
      throw new Error('Image not found in data object')
    }

    var fromData = new FormData()
    for (const lang of languages) {
      fromData.append(`name[${lang}]`, data?.name[lang] || '')
    }

    fromData.append('image', data.image)

    fromData.append('sort', data.store_id || 0)
    const response = await API.post(`${MANUFACTURER.GET_manufacturer}`, fromData)

    return response.data.data
  })
)
export const updateManufacturerAction = createAsyncThunk(
  'manufacturer/updateManufacturerAction',
  thunkCatchError(async ({id, data}: {id: string | number; data: any}) => {
    //   check  if  image  is  base64  string

    // const isImageBase64 = isBase64(data.image)

    try {
      const response = await API.put(
        `${MANUFACTURER.GET_manufacturer}/${id}`,
        !data.image.includes('data') ? {...data, image: ''} : data
      )
      const manufacturers = store.getState()?.manufacturer.manufacturers

      if (manufacturers?.data?.length === 0) {
        store.dispatch(manufacturerAction({page: 1, pre_page: 10}))
      }

      const manufacturersUpdated = manufacturers?.data?.map((item: any) => {
        if (item.id == id) {
          return {
            ...response.data?.data,
            // ...data,
          }
        }

        return item
      })

      return manufacturersUpdated || []
    } catch (err: any) {
      if (err.response) {
        return err.response.data.message
      } else {
        return 'done'
      }
    }
  })
)

//  manufacturerName
export const manufacturerNameAction = createAsyncThunk(
  'manufacturer/manufacturerNameAction',
  thunkCatchError(async () => {
    const response = await API.get(`${MANUFACTURER.GET_MANUFACTURER_NAME}`)
    return response.data.data
  })
)
