import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

interface ItemRoute {
  to: string
  title: string
  hasBullet: boolean
}

export interface SideMenuItemIProps {
  parent: ItemRoute
  item: Array<ItemRoute>
  activeMenu?: boolean
  link?: any
  setIsOpen?: any
  icon?: any
  activeIcon?: any
}

export const SideMenuItems = ({
  parent,
  item,
  activeMenu,
  link,
  setIsOpen,
  icon,
  activeIcon,
}: SideMenuItemIProps) => {
  return (
    <SidebarMenuItemWithSub
      to={parent.to}
      title={parent.title}
      hasBullet={parent.hasBullet || true}
      activeMenu={activeMenu}
      link={link}
      icon={icon}
      activeIcon={activeIcon}
    >
      {item.map((item, index) => (
        <>
          {item.title && (
            <SidebarMenuItem
              to={item.to}
              key={index}
              title={item.title}
              hasBullet={item.hasBullet || true}
              activeMenu={activeMenu}
              setIsOpen={setIsOpen}
            />
          )}
        </>
      ))}
    </SidebarMenuItemWithSub>
  )
}
