import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Boundary} from '../../../componet/Bondary'
import {RootState, useDispatch, withToastr} from '../../../store'
import {FetchStatus} from '../../../store/types'
import {categoriesAction} from '../../../store/vender/catogory/thunk'
import {getOptionAction} from '../../../store/vender/option/thunk'
import {ArrangementOrder} from '../../../types'
import {getComparator, stableSort} from '../../../utils/sortTable'
import {PaginationSection} from '../../customTable/pagnationSection'

import {EnhancedTableHead} from './EnhancedTableHead'
import {TooBar} from './TooBar'
import {CustomerListData} from './types'
import {CircularProgress, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import Options from '../../../Api/options'
import Row from './RowTable'
import TableHead from './TablHead'

const OptionTable = () => {
  const {t} = useTranslation()

  const [page, setPage] = useState<number>(1)
  const [order, setOrder] = useState<ArrangementOrder>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const [selected, setSelected] = useState<string[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [search, setSearch] = useState<string>('')
  const [total, setTotal] = useState(1)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [rows, setRows] = useState([])
  const [added, setAdded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(e.target.value, 5, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )
  const getCoupons = (searchText: string, rowsPerPage: number, page: number) => {
    Options.get(
      searchText,
      rowsPerPage,
      page,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    if (search && page === 1) {
    } else {
      getCoupons(search, rowsPerPage, page)
    }
  }, [rowsPerPage, page])
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const [num, setNum] = useState(0)

  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])
  return (
    <Stack mt='31px' className={`card `}>
      <TooBar num={num} handleSearch={handleSearch} search={search} />
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />

            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.map((row: any, i: any) => (
                      <Row
                        setAdded={setAdded}
                        setNum={setNum}
                        setIsLoading={setIsLoading}
                        setRows={setRows}
                        rows={rows}
                        item={row}
                        i={i}
                        key={i}
                      />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>
          {/*  WILL  SHOW  PAGNATION  SECTION  ONLY  IF  THERE  IS  DATA */}
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </Stack>
  )
}

export default OptionTable
