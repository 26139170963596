import React, {useState, useEffect} from 'react'
import ErrorMsg from './NewMsgErr'

interface Props {
  value: any
  name: string
  change: any
  child?: string
  errors?: any
}

const TextErea: React.FC<Props> = ({value, name, change, child, errors}) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (child && (!value || !value[name])) {
      change({...value, [name]: {}})
    }
  }, [child, name, change, value])

  useEffect(() => {
    if (child) {
      setInputValue(value && value[name] && value[name][child] ? value[name][child] : '')
    } else {
      setInputValue(value && value[name] ? value[name] : '')
    }
  }, [child, name, value])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    setInputValue(newValue)

    if (child) {
      change({...value, [name]: {...value[name], [child]: newValue}})
    } else {
      change({...value, [name]: newValue})
    }
  }

  return (
    <>
      <textarea
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '6.18px',
          width: '100%',
          color: 'var(--bs-text-dark)',
        }}
        rows={6}
        value={inputValue}
        onChange={handleChange}
      />
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </>
  )
}

export default TextErea
