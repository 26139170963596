import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TableHead() {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t('srNo'),
      class: 'min-w-80px',
    },
    {
      name: t('title'),
      class: 'min-w-100px',
    },
    {
      name: t('parent'),
      class: 'min-w-100px',
    },
    {
      name: t('position'),
      class: 'min-w-180px',
    },
    {
      name: t('status'),
      class: 'min-w-410px ',
    },

    {
      name: t('actions'),
      class: 'max-w-520px',
    },
  ]

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === 0 ? 'start' : 'start',
              width: i === headTable.length - 1 || i === 0 ? '120px' : 'auto',
            }}
            key={i}
            className={e.class}
          >
            {e.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}
