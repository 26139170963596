import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en', 'ar']
// let languages = SetLang() || ['en']
export const OptionCatalogSchema = (languages: any) => {
  return Yup.object().shape({
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),
    sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
  })
}
