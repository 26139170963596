import clsx from 'clsx'
import {FormikProps} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {fileListToBase64} from '../../../utils/image'
import {useDispatch} from 'react-redux'
import React from 'react'
import {actions} from '../../../store/vender/Auth/auth.slice'
import ImgMsg from '../../customTable/ImgMsg'
import ErrorMsg from '../../customTable/NewMsgErr'
type Props<T> = {
  formik: FormikProps<T | any>
  isShow?: boolean
  image: string[] | null | Blob | any
  error?: string | null
  touched?: boolean | null
  className?: string
  errors?: any
  name?: any
  child?: any
}

export function IamgeSection<T>({
  formik,
  isShow = true,
  image,
  error,
  touched,
  className,
  errors,
  name,
  child,
}: Props<T>) {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (formik.values.image) {
      dispatch(actions.setVariationImg(formik.values.image))
    }
  }, [formik.values.image])
  return (
    <div className={clsx('card-body pt-0', className)}>
      <div
        className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
        data-kt-image-input='true'
      >
        <div
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          className='image-input-wrapper w-150px h-150px'
        >
          {formik.values.image ? (
            <img
              src={
                typeof formik.values.image === 'object'
                  ? URL.createObjectURL((formik.values.image as any)[0])
                  : formik.values.image
              }
              alt='User Avatar'
              className='w-150px h-150px'
              style={{maxWidth: '100%', maxHeight: '100%'}}
            />
          ) : (
            <img
              src={toAbsoluteUrl('/media/avatars/img.svg')}
              alt='user-info'
              className='w-100px h-100px'
            />
          )}
        </div>
        <label
          htmlFor='image'
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change image'
        >
          <i className='bi bi-pencil-fill fs-7'></i>
          <input
            id='image'
            type='file'
            multiple={false}
            style={{display: 'none'}}
            accept='.jpg, .jpeg, .png, .svg'
            // {...formik.getFieldProps("image")}
            onChange={(e) => {
              const fileList = e.target.files as FileList
              if (e.target.files && e.target.files.length > 1) {
                formik.setFieldError('image', 'Please do not take more than 4 items')
                formik.setFieldTouched('image', true)
                e.target.value = '' // Clear the value of the input element
                return
              }
              fileListToBase64(fileList).then((base64String) => {
                formik.setFieldValue('image', base64String)
              })
            }}
            onFocus={(e) => {
              formik.setFieldTouched('image', true)
            }}
          />
        </label>
        {/*  check  if  the  formik  image  is  found  then  show  x  icon  */}
        {image && image?.length > 0 && (
          <span
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            title='Cancel image'
            onClick={() => {
              formik.setFieldValue('image', null)
            }}
          >
            <i className='bi bi-x fs-2'></i>
          </span>
        )}
      </div>
      {isShow && <ImgMsg />}
      {error && touched && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{error}</div>
        </div>
      )}
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg child={child} msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </div>
  )
}
