import React from 'react'
import Select from 'react-select'
import SimpleLoading from './SimpleLoading'
import ErrorMsg from './NewMsgErr'

export default function OldSelect({
  styles,
  isDisabled,
  components,
  options,
  onChange,
  value,
  onBlur,
  isMulti,
  isLoading,
  errors,
  child,
  name,
}: any) {
  return (
    <>
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <Select
          styles={styles}
          isDisabled={isDisabled}
          className='react-select-container'
          classNamePrefix='react-select'
          components={components}
          options={options}
          isMulti={isMulti}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
        />
      )}
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg child={child} msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </>
  )
}
