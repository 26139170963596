import ApisMethods from './apisMethods'

const SubCatalog = {
  // get: async (callback: any, setIsLoading?: any) => {
  //   ApisMethods().get(`/vendor/categories/get/names`, callback, setIsLoading)
  // },
  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/subcategories`, data, callback, setIsLoading)
  },
  put: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/subcategories/${data.id}`, data, callback, setIsLoading)
  },
  getById: (id: any, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/subcategories/${id}`, callback, setIsLoading)
  },
  // put: (id: any, data: any, callback: any, setIsLoading: any) => {
  //   ApisMethods().put(`/vendor/categories/${id}`, data, callback, setIsLoading)
  // },
}
export default SubCatalog
