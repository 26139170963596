import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function OrderDetails({order}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack p='35px 25px' mt='32px' bgcolor={'var(--bs-body-bg)'} borderRadius={'8px'}>
      <Typography sx={{fontSize: '19px', fontWeight: '600'}}>
        {t('order')} {order.order_number}
      </Typography>
    </Stack>
  )
}
