import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en', 'ar']
export const OptionSchema = (languages: any) => {
  return Yup.object().shape({
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),
    option_catalog_id: Yup.object()
      .shape({
        value: Yup.string().required('categoryErr').min(1, 'parentErr'),
      })
      .required('categoryErr'),
    input_type: Yup.string()
      .oneOf(['single_select', 'multi_select', 'date', 'text', 'file'])
      .required('optionTypesErr'),
    // value_type: Yup.string()
    //   .oneOf(['image', 'color', 'text', 'textt'])
    //   .required('Input should be one of "image", "color", or "text".'),
    optionDetails: Yup.array()
      .nullable()
      .when('value_type', {
        is: 'image',
        then: Yup.array()
          .of(
            Yup.object().shape({
              option_image: Yup.string().required('imageErr'),
              sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
              option_value: Yup.object().shape(
                languages.reduce((acc: any, lang: any) => {
                  return {
                    ...acc,
                    [lang]: Yup.string().required(`optionValueErr`),
                  }
                }, {})
              ),
            })
          )
          .required('optionDetailsErr'),
        otherwise: Yup.array()
          .of(
            Yup.object().shape({
              color: Yup.string().optional(),
              sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
              option_value: Yup.object().shape(
                languages.reduce((acc: any, lang: any) => {
                  return {
                    ...acc,
                    [lang]: Yup.string().required(`optionValueErr`),
                  }
                }, {})
              ),
            })
          )
          .required('optionDetailsErr'),
      }),
  })
}
