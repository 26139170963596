import React, {useState} from 'react'
import {Avatar, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function Row({item}: any) {
  const [editItem, setEditItem] = useState(item)

  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      <tr className='fw-bold text-muted '>
        <td>{editItem.order_number}</td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.service_fees_amount}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.total}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.created_at}</span>
        </td>
      </tr>
    </>
  )
}
