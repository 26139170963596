/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useTranslation} from 'react-i18next'
import DeletedMsg from '../../modules/customTable/DeleteMsg'
type Props = {
  onEdit?: any
  onDelete?: () => void
  onView?: (() => void) | null
  onReplay?: (() => void) | null
  onReview?: (() => void) | null
  deleteMsg?: any
}
const ActionsCell: FC<Props> = ({onEdit, onDelete, onView, onReplay, onReview, deleteMsg}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const {mode} = useThemeMode()
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <a
        style={{minWidth: '108px'}}
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        // data-kt-menu-placement='bottom-end'
      >
        {t('actions')}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {onReplay && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onReplay}>
              <img
                src={toAbsoluteUrl('/media/avatars/replay.svg')}
                alt='SVG Image'
                style={{width: '15px', marginRight: '5px'}}
              />

              {t('replay')}
            </a>
          </div>
        )}
        {onView && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onView}>
              <img
                src={toAbsoluteUrl('/media/avatars/eye.png')}
                alt='SVG Image'
                style={{width: '15px', marginRight: '5px'}}
              />

              {t('view')}
            </a>
          </div>
        )}
        {onEdit && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onEdit}>
              <img
                src={toAbsoluteUrl('/media/avatars/edit.png')}
                alt='SVG Image'
                style={{width: '15px', marginRight: '5px'}}
              />
              {t('edit')}
            </a>
          </div>
        )}
        {onReview && (
          <div className='menu-item px-3'>
            <a style={{display: 'flex', gap: '5px'}} className='menu-link px-3' onClick={onReview}>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.15523 2.33977L10.3286 4.68643C10.4886 5.0131 10.9152 5.32643 11.2752 5.38643L13.4019 5.73977C14.7619 5.96643 15.0819 6.9531 14.1019 7.92643L12.4486 9.57977C12.1686 9.85977 12.0152 10.3998 12.1019 10.7864L12.5752 12.8331C12.9486 14.4531 12.0886 15.0798 10.6552 14.2331L8.66189 13.0531C8.30189 12.8398 7.70856 12.8398 7.34189 13.0531L5.34856 14.2331C3.92189 15.0798 3.05523 14.4464 3.42856 12.8331L3.90189 10.7864C3.98856 10.3998 3.83523 9.85977 3.55523 9.57977L1.90189 7.92643C0.928559 6.9531 1.24189 5.96643 2.60189 5.73977L4.72856 5.38643C5.08189 5.32643 5.50856 5.0131 5.66856 4.68643L6.84189 2.33977C7.48189 1.06643 8.52189 1.06643 9.15523 2.33977Z'
                  stroke='#7e8299'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>

              {t('evaluations')}
            </a>
          </div>
        )}

        {/* end::Menu item */}

        {/* begin::Menu item */}
        {onDelete && (
          <>
            {' '}
            <div className='menu-item px-3'>
              <a
                style={{display: 'flex', gap: '5px'}}
                className='menu-link px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => {
                  setOpen(true)
                }}
              >
                <img
                  src={toAbsoluteUrl('/media/avatars/delete.png')}
                  alt='SVG Image'
                  style={{width: '15px', marginRight: '5px'}}
                />
                {t('delete')}
              </a>
            </div>
            <DeletedMsg deleteMsg={deleteMsg} onClick={onDelete} open={open} setOpen={setOpen} />
          </>
        )}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
