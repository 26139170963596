import {useState, useEffect} from 'react'
import {GoogleMap, useLoadScript, Marker} from '@react-google-maps/api'

export default function Home({center, setCenter}: any) {
  const [mapRef, setMapRef] = useState(null)

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: 'AIzaSyBXAI9CDItCJWevXGg7VGVhU-f_DPtC3t4',
    libraries: ['places'],
  })

  const handleMapLoad = (map: any) => {
    setMapRef(map)
  }

  if (loadError) return <div>Error loading maps</div>
  if (!isLoaded) return <div>Loading...</div>

  return (
    <>
      <Map center={center} setCenter={setCenter} mapRef={mapRef} handleMapLoad={handleMapLoad} />
    </>
  )
}

function Map({mapRef, handleMapLoad, center, setCenter}: any) {
  const [location, setLocation] = useState(null)
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setCenter({lat: position.coords.latitude, lng: position.coords.longitude})
  //   })
  // }, [])
  const handleMarkerClick = (e: any) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    setCenter({lat: e.latLng.lat(), lng: e.latLng.lng()})
    // Log lat/lng to console

    // Add a circle overlay to the map
    const circle = new window.google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: mapRef,
      center: {lat, lng},
      radius: 1000, // 1km
    })

    // Add a line for latitude to the map
    const latLine = new window.google.maps.Polyline({
      path: [
        {lat, lng: -180},
        {lat, lng: 180},
      ],
      map: mapRef,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    })

    // Add a line for longitude to the map
    const lngLine = new window.google.maps.Polyline({
      path: [
        {lat: -90, lng},
        {lat: 90, lng},
      ],
      map: mapRef,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    })
  }

  return (
    <>
      <div style={{height: '100%', width: '100%'}}>
        <GoogleMap
          onLoad={handleMapLoad}
          zoom={10}
          center={center}
          mapContainerStyle={{height: '100%', width: '100%'}}
          options={{fullscreenControl: false}}
          ref={mapRef}
          onClick={handleMarkerClick}
        >
          <Marker position={center} />
        </GoogleMap>
      </div>
    </>
  )
}
