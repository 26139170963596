import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import UploadImg from '../customTable/UploadImg'
import ErrorMsg from '../customTable/ErrorMsg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import LabelInput from '../customTable/LabelInput'
import Tabs from '../customTable/Tabs'
import Manufacturers from '../../Api/manufacturers'
import {ManfactuerSchema} from '../../yup/Manfactuer'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import TabsWithFlag from '../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import ValidationFun from '../../yup/ValidationFun'

export default function EditForm({
  setOpen,
  open,
  item,
  setItem,
  setRows,
  rows,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  item: any
  setItem: React.Dispatch<React.SetStateAction<any>>
  setRows: any
  rows: any
}) {
  const [base64Image, setBase64Image] = React.useState(item.image)
  const [banner, setBanner] = useState({...item})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const {t} = useTranslation()
  useEffect(() => {
    // setBanner({...item, sort: item.sort_order})
  }, [item])
  const [languagess, setlanguagess] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguagess(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState<any>({})

  const saveChange = () => {
    ValidationFun(
      {
        ...banner,
        image: base64Image,
        sort: banner.sort ? banner.sort : 0,
      },
      ManfactuerSchema(languagess),
      setErrors,
      (value: any) => {
        Manufacturers.put(
          item.id,
          {
            ...banner,
            image: base64Image.includes('data') ? base64Image : '',
            sort: banner.sort ? banner.sort : 0,
          },
          (res: any) => {
            setItem(res)

            // setBase64Image('')
            setMsgErr('')
            setUsLoading(false)
            setOpen(false)
            setBase64Image(res.image)
            setBanner({
              ...res,
            })
          },
          setUsLoading
        )
      }
    )
    // ManfactuerSchema(languagess)
    //   .validate({
    //     ...banner,
    //     image: base64Image,
    //     sort: banner.sort ? banner.sort : 0,
    //   })
    //   .then(() => {
    //     Manufacturers.put(
    //       item.id,
    //       {
    //         ...banner,
    //         image: base64Image.includes('data') ? base64Image : '',
    //         sort: banner.sort ? banner.sort : 0,
    //       },
    //       (res: any) => {
    //         setItem(res)

    //         // setBase64Image('')
    //         setMsgErr('')
    //         setUsLoading(false)
    //         setOpen(false)
    //         setBase64Image(res.image)
    //         setBanner({
    //           ...res,
    //         })
    //       },
    //       setUsLoading
    //     )
    //   })
    //   .catch((error: Yup.ValidationError) => {
    //     setMsgErr(error.message)
    //     setUsLoading(false)
    //   })
  }
  const languages = [
    {label: 'English', lang: 'en', flag: '/media/flags/united-states.svg'},
    {label: 'Arabic', lang: 'ar', flag: '/media/flags/lebanon.svg'},
  ]

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(store.defLang)
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {t('editManufacturer')}
      </Typography>
      {open && (
        <Stack
          mt='25px'
          width='150px'
          height={'150px'}
          sx={{
            boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
            borderRadius: '7px',
          }}
        >
          <UploadImg open={open} base64Image={item.image} setBase64Image={setBase64Image} />
          <ErrorMsg msgErr={msgErr} name='Image' />
        </Stack>
      )}

      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        {selectedLang.map((e: any, i: any) => (
          <>
            {e.code === defLang && (
              <Stack gap='10px'>
                <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                  <LabelInput text={t('name')} />
                  <RedStart />
                </label>
                <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                  <InputType
                    name={'name'}
                    change={setBanner}
                    value={banner}
                    type='text'
                    child={e.code}
                    errors={errors}
                  />
                  <Stack
                    position={'absolute'}
                    left={store.isRtl === 1 ? 0 : 'auto'}
                    right={store.isRtl === 0 ? 0 : 'auto'}
                    top='-53px'
                  >
                    <TabsWithFlag
                      languages={selectedLang}
                      activeLang={defLang}
                      setActiveLang={setDefLang}
                      msgErr={errors && Object.keys(errors)[0]}
                    />
                    {/* <Tabs activeLang={activeLang} setActiveLang={setActiveLang} /> */}
                  </Stack>
                </Box>
              </Stack>
            )}
          </>
        ))}{' '}
        <ErrorMsg msgErr={msgErr} name='name' />
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('sort')}
          </Typography>
          <RedStart />
        </Stack>
        <InputType name={'sort'} change={setBanner} value={banner} type='number' />
        <ErrorMsg msgErr={msgErr} name='sort' />
      </Stack>
      <SaveButton text={t('saveChanges')} submit={saveChange} isLoading={false} setOpen={setOpen} />
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
      {isLoading && <Loading />}
    </Container>
  )
}
