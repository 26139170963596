import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Stack, Typography} from '@mui/material'
import TitlePage from '../../customTable/TitlePage'
import {KTSVG} from '../../../../_metronic/helpers'
import Table from './Table'
import {useTranslation} from 'react-i18next'
export default function BackageTable() {
  const {t} = useTranslation()

  const [rows, setRows] = useState<any>([])

  return (
    <Stack>
      <Table rows={rows} setRows={setRows} />
    </Stack>
  )
}
// var(--bs-body-bg)
