import * as React from 'react'
import Dialog from '@mui/material/Dialog'

import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {Container, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import MainBtn from '../customTable/MainBtn'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Msg({open, setOpen}: {open: any; setOpen: any}) {
  const handleClose = () => {
    // setOpen(false)
  }
  const store = useSelector((state: RootState) => state.vender)

  const {t} = useTranslation()
  const navigate = useNavigate()
  return (
    <div>
      <></>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <Container
          dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
          lang={store.lang}
          sx={{padding: '30px 24px 40px 40px'}}
          maxWidth='md'
        >
          <p style={{width: '500px'}}></p>
          <Stack gap='18px' alignItems={'center'}>
            <Stack
              sx={{
                background: 'var(--main-color)',
                width: '66px',
                height: '66px',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '35px',
                  fontWeight: '600',
                }}
              >
                !
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: 'var(--bs-body-color)',
                fontSize: '18px',
                fontWeight: '600',
              }}
            >
              {t('subscriptionRequired')}
            </Typography>
            <Typography
              sx={{
                color: 'var(--bs-body-color)',
                fontSize: '18px',
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              {t('subscriptionMsg')}
            </Typography>
            <MainBtn
              handelSubmit={() => {
                navigate('/subscriptions-change-package')
                setOpen(false)
              }}
              width='auto'
              text='renewYourSubscription'
            />
          </Stack>
        </Container>{' '}
      </Dialog>
    </div>
  )
}
