import {FormikProps} from 'formik'
import {useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Language} from '../../../types'
import {ActionsButton} from './actionsButton'
import UploadImg from '../../../custom/UploadImg'
import OptionImage from './OptionImage'
import {useTranslation} from 'react-i18next'
import {AnyIfEmpty} from 'react-redux'

export interface ItemOptionImage {
  option_image: string | File | null
  sort: number
  option_value: {
    [key in Language]: string
  }
  deleteId: any
  id?: string | number
}
export interface ItemOptionColor {
  color: string
  sort: number
  option_value: {
    [key in Language]: string
  }
  deleteId?: any
  option_code: string
}
export interface ItemOptionText {
  sort: number
  option_value: {
    [key in Language]: string
  }
  deleteId: any
}

interface OptionDetailsProps {
  optionDetails: Array<ItemOptionImage | ItemOptionColor | ItemOptionText>
  formik: FormikProps<any>
  activeLang: Language
  disabled?: boolean
  handleRemoveOption: (id: string | number) => Promise<void>
  handleEditOption: (id: string | number, data: any) => Promise<void>
  handelSaveOption: (data: any) => Promise<void>
  msgErr: any
  setActiveAdd: any
  Adderrors: any
}

export function OptionDetails({
  activeLang,
  formik,
  disabled,
  handleRemoveOption,
  handleEditOption,
  handelSaveOption,
  msgErr,
  setActiveAdd,
  Adderrors,
}: OptionDetailsProps) {
  const {id} = useParams<{
    id?: string
  }>()
  const {t} = useTranslation()

  return (
    <>
      <div className='my-2 card card-flush bg-transparent '>
        {(formik?.values?.optionDetails as Array<ItemOptionImage>)?.map((option, index) => {
          return (
            <OptionImage
              option={option}
              index={index}
              formik={formik}
              Adderrors={Adderrors}
              disabled={disabled}
              handleRemoveOption={handleRemoveOption}
              handleEditOption={handleEditOption}
              handelSaveOption={handelSaveOption}
              msgErr={msgErr}
              setActiveAdd={setActiveAdd}
            />
          )
        })}
      </div>
    </>
  )
}
