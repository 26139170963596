import {Box, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

export default function UploadVideo({base64Image, setBase64Image}: any) {
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    setBase64Image(file)
  }

  const {t} = useTranslation()

  return (
    <Box>
      <Button
        sx={{
          color: 'var(--main-color)',
          padding: '13px 21px',
          background: 'rgba(216, 25, 35, 0.1);',
          borderRadius: '7px',
          marginTop: '10px',
        }}
        component='label'
      >
        {t('attachFiles')}
        <input hidden accept='video/*' onChange={handleImageChange} multiple type='file' />
      </Button>
    </Box>
  )
}
