import React from 'react'

export default function ErrorMsg({msgErr, name}: {msgErr: string; name: string}) {
  return (
    <>
      {msgErr.includes(name) && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{msgErr}</div>
        </div>
      )}
    </>
  )
}
