import {FormikProps} from 'formik'
import React, {useMemo} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {InitialValues} from './validationSchema'
import Select, {ActionMeta, SingleValue} from 'react-select'
import {useDispatch} from 'react-redux'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'

interface Props {
  formik: FormikProps<InitialValues>
  options: Array<{label: string; value: string}>
  lable?: string
  subLable?: string
}

export const ProductDetails: React.FC<Props> = ({formik, options, lable, subLable}) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-app-bg-color)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'var(--bs-heading-color, inherit)',
    }),
  }
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {id} = useParams()
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  return (
    <div className='card card-flush py-4'>
      <div className='card-header'>
        <div className='card-title'>
          <h2>{lable}</h2>
        </div>
      </div>
      <div style={{padding: '0 2.25rem'}} className='card-body pt-0'>
        <div>
          <label className='form-label'>{subLable}</label>
          <Select
            name='categories'
            isMulti={true}
            styles={customStyles}
            value={formik.values.categories}
            onChange={(selectedOption, actionMeta) => {
              formik.setFieldValue('categories', selectedOption)
            }}
            options={options as any}
            classNamePrefix='react-select'
          />
        </div>

        <div className='text-muted fs-7 mb-7 mt-2'>Add product to a category.</div>
        {store.permissions && store.permissions.includes('categories_create') && (
          <MainBtn
            num={options && options.length}
            backageKey={'CATEGOR11Y_NUM'}
            without
            width='auto'
            handelSubmit={() => {
              if (id) {
                dispatch(actions.setCashData({...formik.values, dataCashType: 'product', id: id}))
              } else {
                dispatch(actions.setCashData({...formik.values, dataCashType: 'product'}))
              }
              navigate(`/catalog/add-category`)
            }}
            text='createNewCategory'
          />
          // <Link
          //   onClick={() => {
          //     if (id) {
          //       dispatch(actions.setCashData({...formik.values, dataCashType: 'product', id: id}))
          //     } else {
          //       dispatch(actions.setCashData({...formik.values, dataCashType: 'product'}))
          //     }
          //   }}
          //   to={`/catalog/add-category`}
          //   className='btn btn-light-danger btn-sm mb-10'
          // >
          //   {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
          //   <span className='svg-icon svg-icon-2'>
          //     <svg
          //       width='24'
          //       height='24'
          //       viewBox='0 0 24 24'
          //       fill='none'
          //       xmlns='http://www.w3.org/2000/svg'
          //     >
          //       <rect
          //         opacity='0.5'
          //         x='11'
          //         y='18'
          //         width='12'
          //         height='2'
          //         rx='1'
          //         transform='rotate(-90 11 18)'
          //         fill='currentColor'
          //       />
          //       <rect x='6' y='11' width='12' height='2' rx='1' fill='currentColor' />
          //     </svg>
          //   </span>
          //   {/*end::Svg Icon*/}
          //   {t('createNewCategory')}
          // </Link>
        )}
      </div>
      {/*end::Card body*/}
    </div>
  )
}

//  default  export  ProductDetails;
ProductDetails.defaultProps = {
  lable: 'sub Category Details',
  subLable: 'Parent Category',
}
