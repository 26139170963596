import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import MainBtn from './MainBtn'

export default function SaveButton({
  text,
  submit,
  isLoading,
  setOpen,
  handelselctions,
}: {
  text: string
  submit: any
  isLoading: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handelselctions?: any
}) {
  const {t} = useTranslation()

  return (
    <Stack mt={'20px'} width={'100%'} gap={'10px'} direction={'row'} justifyContent={'flex-end'}>
      <button
        type='submit'
        onClick={() => {
          if (handelselctions) {
            handelselctions()
          }

          setOpen(false)
        }}
        className='btn btn-light me-3'
      >
        {t('cancel')}{' '}
      </button>
      <MainBtn isLoading={isLoading} width='auto' text={text} type='submit' handelSubmit={submit} />
    </Stack>
  )
}
