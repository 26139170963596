import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'

export default function Tabs({active, setActive}: any) {
  const [tabs, setTabs] = useState([
    {name: 'IHaveAccountsInStores', id: 1},
    {name: 'IDoNotHaveAccountsInStores', id: 2},
  ])
  return (
    <Box display={'flex'}>
      {' '}
      <Stack
        bgcolor={'#fff'}
        direction={'row'}
        gap='8px'
        border='1px solid #C5C5D8'
        borderRadius={'8px'}
      >
        {tabs.map((e: any) => (
          <Stack
            onClick={() => {
              setActive(e.id)
            }}
            sx={{cursor: 'pointer'}}
            borderRadius={'8px'}
            bgcolor={e.id !== active ? '#fff' : 'var(--main-color)'}
            p='16px'
          >
            <Label text={e.name} fw='400' color={e.id === active ? '#fff' : '#979797'} />
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
