import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const RolesPermissions = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/roles`, callback, setIsLoading)
  },
  getNameRoles: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/roles/names`, callback, setIsLoading)
  },

  getSingleRole: (id: any, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/roles/${id}`, callback, setIsLoading)
  },
  getPermissions: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/permissions`, callback, setIsLoading)
  },
  getPermissionsById: async (id: any, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/roles/edit/${id}`, callback, setIsLoading)
  },

  add: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/roles`, data, callback, setIsLoading)
  },
  put: async (data: any, id: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/roles/${id}`, data, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/roles/${id}`, callback, setIsLoading)
  },
  getMyPermissions: async (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/get/permissions`, callback, setIsLoading)
  },
  getMyPackageFeatures: async (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/get/package-features`, callback, setIsLoading)
  },
}
export default RolesPermissions
