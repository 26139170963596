import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import * as yup from 'yup';
import { BulkDiscount } from '../../componnet/discountItems';

interface InitialValues {
  optionDetails: Array<BulkDiscount>
}

interface UseValidationSchemaBulkProps {
  bulkDiscount: Array<BulkDiscount>;
}

export const useValidationSchemaBulk = ({bulkDiscount}:UseValidationSchemaBulkProps) => {
  const intl = useIntl();


  const initialValues: InitialValues = useMemo(() => ({

    optionDetails: bulkDiscount ?? [],
  }), [bulkDiscount]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        optionDetails: yup.array().of(
          yup.object().shape({
            min: yup
              .number()
              .min(
                1,
                ({ min }) =>
                  intl.formatMessage({ id: 'minErrorMessage' }, { min })
              )
              .required(({ path }) =>
                intl.formatMessage({ id: 'requiredErrorMessage' }, { path })
              ),
              max: yup.number()
              .min(yup.ref('min'), ({ min, max }:any) => intl.formatMessage({ id: 'maxErrorMessage' }, { min, max }))
              .required(({ path }) => intl.formatMessage({ id: 'requiredErrorMessage' }, { path })),
            is_fixed: yup
              .number()
              .required(({ path }) =>
                intl.formatMessage({ id: 'requiredErrorMessage' }, { path })
              ),
            value: yup
              .number()
              .required(({ path }) =>
                intl.formatMessage({ id: 'requiredErrorMessage' }, { path })
              ),
            id: yup.number(),
          })
        ),
      }),
    [intl]
  );

  return { validationSchema,initialValues };
};
