import {Box, Button, Stack} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

export default function UploadImg({base64Image, setBase64Image}: any) {
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    setBase64Image(file)
  }

  const {t} = useTranslation()

  return (
    <Box>
      <Button
        sx={{
          color: 'red',
          padding: '13px 21px',
          background: 'rgba(216, 25, 35, 0.1);',
          borderRadius: '7px',
          marginTop: '10px',
        }}
        component='label'
      >
        {t('attachFiles')}
        {/* <img style={{width: '100%', height: '100%', borderRadius: '7px'}} src={image} alt='' /> */}
        <input hidden accept='image/*' onChange={handleImageChange} multiple type='file' />
      </Button>
    </Box>
  )
}
