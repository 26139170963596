import {Stack} from '@mui/material'
import React, {useEffect} from 'react'
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import Cards from './Cards'
import Msg from './Msg'
import CardsPacage from './CardsPacage'
import Backages from '../storeManagement/applicationMaker/Backages'
import BackageTable from './backageTable'

export default function ChangePackage({width}: any) {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('changePackage'),
    home: t('home'),
    linkHome: '/',
  }

  return (
    <Stack>
      <TitlePage info={bannerInfo} />
      <Stack bgcolor={'#fff'} mt='32px'>
        <Backages width={width} title='changeBackage' subTitle='ChooseTheRightPackageForYou' />
        <BackageTable />
      </Stack>
    </Stack>
  )
}
