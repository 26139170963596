import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {SideMenuItemIProps} from './SideMenuItemCustom'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../app/store'

export const useCatalog = () => {
  const intl = useIntl()
  const {t} = useTranslation()

  const catalog = useMemo(() => {
    const items: SideMenuItemIProps = {
      parent: {
        to: '/catalog/list',
        title: t('catlog'),
        hasBullet: true,
      },
      item: [
        {
          to: '/catalog/list',
          title: t('catlogList'),
          hasBullet: true,
        },

        {
          to: '/catalog/products',
          title: t('products'),
          hasBullet: true,
        },
        {
          to: '/catalog/options',
          title: t('optionsList'),
          hasBullet: true,
        },

        {
          to: '/catalog/option-catelogue',
          title: t('optionCatelogue'),

          hasBullet: true,
        },

        {
          to: '/catalog/manufacturer',
          title: t('manufacturer'),

          hasBullet: true,
        },
      ],
    }
    return items
  }, [intl])

  return {catalog}
}

export const useReport = () => {
  const intl = useIntl()
  const {t} = useTranslation()

  const report = useMemo(() => {
    const items: SideMenuItemIProps = {
      parent: {
        to: '/report/option-catelogue',
        title: t('options'),
        hasBullet: true,
      },
      item: [],
    }
    return items
  }, [intl])

  return {report}
}

export const useCustomer = () => {
  const intl = useIntl()
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const customer = useMemo(() => {
    const items: SideMenuItemIProps = {
      parent: {
        to: '/customer/list',
        title: t('customer'),

        hasBullet: true,
      },
      item: [
        {
          to: '/customer/list',
          title: t('customerList'),
          hasBullet: true,
        },
      ],
    }
    return items
  }, [store.lang])

  return {customer}
}

export const useCoupons = () => {
  const intl = useIntl()
  const {t} = useTranslation()

  const Coupons = useMemo(() => {
    const items: SideMenuItemIProps = {
      parent: {
        to: '/helpdesk/list',
        title: t('helpDesk'),
        hasBullet: true,
      },
      item: [
        {
          to: '/helpdesk/list',
          title: t('caponsList'),
          hasBullet: true,
        },
        {
          to: '/helpdesk/banner',
          title: t('banner'),
          hasBullet: true,
        },
      ],
    }
    return items
  }, [intl])

  return {Coupons}
}
