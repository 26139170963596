import React, {useState} from 'react'
import {ActionsCell} from '../../../../componet/Menu'
import {Stack} from '@mui/material'
import IBanner from '../../../../interface/IBanner'
import Switch from '../../../../componet/Toastr/Switch'
import Banner from '../../../../Api/banner'
import Manufacturers from '../../../../Api/manufacturers'
import {RootState} from '../../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../../customTable/SimpleLoading'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Catalog from '../../../../Api/catalog'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setIsLoading,
  setNum,
  setAdded,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
  setNum: any
  setAdded: any
}) {
  const [state, setState] = useState<any>(item.status)
  const {name, status, id} = item
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelEdit = () => {
    setOpen(true)
  }

  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const params = useParams()
  const navigate = useNavigate()

  const handelDelete = async () => {
    setAdded(!true)

    Catalog.delete(
      item.id,
      () => {
        setRows((prev: any) => prev.filter((e: any) => e.id !== item.id))
        setAdded(true)
        setNum((prev: any) => prev - 1)
      },
      setIsLoadingDelete
    )

    // setDeleted(false)
  }
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const handleEdit = () => {
    navigate(`/catalog/edit-sub-category/${id}?idcata=${params.id}`)
  }
  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td className=''>
              <div className='ms-5'>
                <Stack
                  className='text-gray-800 text-hover-primary fs-5 fw-bold mb-1'
                  data-kt-ecommerce-category-filter='category_name'
                >
                  {/* <p className='text-gray-800 text-hover-primary fs-5 fw-bold mb-1'> */}
                  {name &&
                    (name[lang] || name[defLang]) &&
                    (name[lang] || name[defLang]).slice(0, 15)}
                  {name &&
                    (name[lang] || name[defLang]) &&
                    (name[lang] || name[defLang]).length > 15 &&
                    '...'}
                  {/* </p> */}
                  {/* {name.hasOwnProperty(lang) ? name[lang] : name[Object.keys(name)[0]]} */}
                  {/* {typeof name === 'object' ? name[lang] : name[Object.keys(name)[0]]} */}
                </Stack>
              </div>
            </td>
            <td>
              {!!status ? (
                <div
                  style={{padding: '8px 16px', color: 'green'}}
                  className='badge badge-light-primary'
                >
                  {t('active')}
                </div>
              ) : (
                <div style={{padding: '8px 16px'}} className='badge badge-light-danger'>
                  {t('unActive')}
                </div>
              )}
            </td>
            <td style={{textAlign: 'start'}}>
              {isLoadingDelete ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={store.permissions.includes(`categories_update`) && handleEdit}
                  onDelete={store.permissions.includes(`categories_delete`) && handelDelete}
                  onView={null}
                />
              )}
            </td>
          </tr>
        </>
      )}
    </>
  )
}
