import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Social = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/social-media`, callback, setIsLoading)
  },

  put: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/settings/social-media`, data, callback, setIsLoading)
  },
}
export default Social
