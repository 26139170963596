import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const MenuApi = {
  get: async (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    let url = `/vendor/menus?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      if (searchText) {
        url = url + `&search=${searchText}`
      }
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  getOne: async (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/menus/edit/${id}`, callback, setIsLoading)
  },
  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/menus`, data, callback, setIsLoading)
  },
  put: (data: any, id: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/menus/${id}`, data, callback, setIsLoading)
  },
  putStatus: (id: number | undefined, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/menus/status/${id}`, {status: status}, callback, setIsLoading)
  },
  getNames: async (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/menus/get/menus-name`, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/menus/${id}`, callback, setIsLoading)
  },
}
export default MenuApi
