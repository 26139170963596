import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'

export const OptionColorschema = (languages: any) => {
  const OptionColorschem = Yup.object().shape({
    option_value: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`optionValueErr`),
        }
      }, {})
    ),
    id: Yup.string().required('idErr'),
    option_code: Yup.string().required('optionCodeErr'),
  })
  return OptionColorschem
}

export const Imageschema = (languages: any) => {
  return Yup.object().shape({
    option_image: Yup.string().required('imageErr'),
    option_value: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`optionValueErr`),
        }
      }, {})
    ),
    id: Yup.string().required(),
  })
}

export const Textschema = (languages: any) => {
  return Yup.object().shape({
    // sort: Yup.number().required(),
    option_value: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`optionValueErr`),
        }
      }, {})
    ),
    id: Yup.string().required(),
    // sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
  })
}
