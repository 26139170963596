import 'react-quill/dist/quill.snow.css'

import {BulkDiscountDetails} from '../componnet/discountItems'
import {useTranslation} from 'react-i18next'

export const BulkDiscount = ({activeKey, setActiveKey}: any) => {
  const {t} = useTranslation()

  return (
    <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
      <BulkDiscountDetails activeKey={activeKey} />

      <div className='d-flex justify-content-end'>
        {/* //<!--begin::Button--> */}
        <button
          onClick={() => {
            setActiveKey('Options')
          }}
          className='btn btn-light me-5'
        >
          {' '}
          {t('back')}
        </button>
      </div>
    </div>
  )
}
