import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog, {DialogProps} from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {Stack, Typography} from '@mui/material'
import Img from '../../../componet/imag'
import {useTranslation} from 'react-i18next'
import Product from '../../../Api/product'

export default function ViewBtn({open, setOpen, id}: any) {
  const [fullWidth, setFullWidth] = React.useState(true)
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg')
  const {product} = useSelector((state: RootState) => state.product)
  const [selectedFilesFound, setSelectedFilesFound] = React.useState<any>([])

  React.useEffect(() => {
    if (product?.product_pictures?.length) {
      const files = product?.product_pictures?.map((file: any) => {
        const imageNameWithExtension = file?.picture.substring(file?.picture.lastIndexOf('/') + 1)
        const imageName = imageNameWithExtension.substring(
          0,
          imageNameWithExtension.lastIndexOf('.')
        )

        return {
          id: file.id,
          preview: file.picture,
          name: imageName,
        }
      })

      setSelectedFilesFound(files)
    }
  }, [product?.product_pictures])

  const handleClose = () => {
    setOpen(false)
  }
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  const currency = localStorage.getItem('currency')
  const [isLoading, setIsLoading] = React.useState(false)
  const getData = () => {
    Product.getViewProduct(
      id,
      (res: any) => {
        setViewData(res.data)
      },
      setIsLoading
    )
  }
  const [viewData, setViewData] = React.useState<any>({})
  React.useEffect(() => {
    if (open) {
      getData()
    }
  }, [open])

  return (
    <React.Fragment>
      <></>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
        fullWidth={fullWidth}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
      >
        <Stack p='48px' lang={store.lang} dir={store.isRtl === 0 ? 'ltr' : 'rtl'}>
          <Typography mb='60px' sx={{color: '#181C32', fontSize: '20px', fontWeight: '600'}}>
            {t('review')}
          </Typography>
          <Stack>
            <Stack alignItems={'center'} gap='16px' direction={'row'}>
              <img
                style={{width: '50px', height: '50px', borderRadius: '50%'}}
                src='https://i.pinimg.com/236x/a8/d4/3f/a8d43fa7f686833ee97c0478d41215a2.jpg'
                alt=''
              />

              <Stack alignItems={'center'} gap='5px' direction={'row'}>
                <Typography sx={{color: '#3F4254', fontSize: '15px', fontWeight: '600'}}>
                  Cameron Williamson{' '}
                </Typography>
                <Typography sx={{color: '#9D9DA6', fontSize: '12px', fontWeight: '400'}}>
                  12:31 Pm. 23 Nov, 2020{' '}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography mt='32px' sx={{color: '#616161', fontSize: '14px', fontWeight: '400'}}>
            Hi, my name is Eric and I’m betting you’d like your website kayanstore.com to generate
            more leads. Here’s how: Talk With Web Visitor is a SMS Text With Lead feature enables
            you to start a text (SMS) conversation – answer questions, provide more info, and close
            a deal that way. If they don’t take you up on your offer then, just follow up with text
            messages for new offers, content links, even just “how you doing?” notes to build a
            relationship. CLICK HERE https://boostleadgeneration.com to discover what Talk With Web
            Visitor can do for your business. The difference between contacting someone within 5
            minutes versus a half-hour means you could be converting up to 100X more leads today!
            Try Talk With Web Visitor and get more leads now. Eric PS: The studies show 7 out of 10
            visitors don’t hang around – you can’t afford to lose them! Talk With Web Visitor offers
            a FREE 14 days trial – and it even includes International Long Distance Calling. You
            have customers waiting to talk with you right now… don’t keep them waiting. CLICK HERE
            https://boostleadgeneration.com to try Talk With Web Visitor now. If you'd like to
            unsubscribe click here http:{' '}
          </Typography>
        </Stack>
      </Dialog>
    </React.Fragment>
  )
}
