import {useCallback, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {ActionsCell} from '../../../componet/Menu'
import {RootState, useDispatch, withToastr} from '../../../store'
import {deleteProductAction} from '../../../store/vender/products/thunk'
import {Language} from '../../../types'
import {handleClickSelected} from '../../../utils/sortTable'
import {RowItemProps} from './types'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Box, Stack} from '@mui/material'
import VariationRow from './VariationRow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {toast} from 'react-toastify'
import ViewBtn from '../addProduct/ViewBtn'
import PupUpImage from '../../customTable/PupUpImage'
export const RowItem = (props: any) => {
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const {
    id,
    name,
    status,
    image,
    quantity,
    price,
    discount_amount,
    rate,
    sku,
    className,
    selected,
    setSelected,
    variation_values,
    unlimited_quantity,
    setProducts,
    newProducts,
    setIsLoading,
  } = props

  const handleClick = (id: string) => {
    setSelected(handleClickSelected(id, selected))
  }
  const handleDelete = useCallback(async () => {
    try {
      setIsLoading(true)
      await dispatch(
        withToastr(deleteProductAction(id), {
          success: {
            title: 'we  are  success  to  delete item  ',
          },
        })
      )
      setProducts(newProducts.filter((e: any) => e.id !== id))
      setIsLoading(false)

      toast.success('Deleted Successfully')
    } catch (err: any) {
      setIsLoading(false)
    }
  }, [dispatch, id])

  const handleEdit = useCallback(() => {
    navigate(`/catalog/edit-product/${id}`)
  }, [id, navigate])
  const onView = useCallback(() => {
    navigate(`/category/sub-category/${id}`)
  }, [id, navigate])
  const {t} = useTranslation()
  const [isDrow, setIsDrow] = useState(false)
  const [openView, setView] = useState(false)
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      <tr className={className}>
        <td
          onClick={() => {
            if (variation_values && variation_values.length > 0) {
              setIsDrow(!isDrow)
            }
          }}
        >
          <div className='d-flex align-items-center'>
            <div className='ms-5'>
              <PupUpImage image={image}>
                <Link
                  style={{display: 'flex', gap: '10px', alignItems: 'center'}}
                  to=''
                  className='text-gray-800 text-hover-primary fs-5 fw-bold'
                >
                  <img
                    style={{width: '50px', height: '50px', marginLeft: '5px', borderRadius: '12px'}}
                    src={image}
                    alt=''
                  />
                  <p className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                    {name &&
                      (name[lang] || name[defLang]) &&
                      (name[lang] || name[defLang]).slice(0, 15)}
                    {name &&
                      (name[lang] || name[defLang]) &&
                      (name[lang] || name[defLang]).length > 15 &&
                      '...'}
                  </p>
                  <Box sx={{height: '38px'}}>
                    {variation_values && variation_values.length > 0 && (
                      <KeyboardArrowDownIcon sx={{transform: isDrow ? 'rotateX(180deg)' : ''}} />
                    )}
                  </Box>
                </Link>
              </PupUpImage>
            </div>
          </div>
        </td>

        <td className=''>
          <span className='fw-bold'>{sku}</span>
        </td>
        <td data-order='17'>
          {quantity !== 0 && quantity <= 10 && (
            <span className='badge badge-light-warning'>{t('lowStock')}</span>
          )}
          {quantity === 0 && unlimited_quantity !== 1 && (
            <span
              style={{
                color: 'red',
                background: '#ff000040',
                padding: '2px 5px',
                borderRadius: '4px',
              }}
            >
              {t('outOfStock')}
            </span>
          )}
          <span className='fw-bold ms-3'>{quantity}</span>
        </td>
        <td>{price}</td>
        <td>
          <div className='rating '>
            {Array.from({length: rate}, (_, i) => (
              <div className='rating-label checked'>
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
            ))}
            {Array.from({length: 5 - rate}, (_, i) => (
              <div className='rating-label'>
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </td>
        <td className=' pe-0' data-order='Published'>
          <div style={{padding: '7px 16px'}} className='badge badge-light-success'>
            {'Published'}
          </div>
        </td>

        <td className='text-center'>
          <ActionsCell
            onEdit={
              store.permissions && store.permissions.includes('products_update') && handleEdit
            }
            onDelete={
              store.permissions && store.permissions.includes('products_delete') && handleDelete
            }
            onView={() => {
              setView(true)
            }}
            onReview={() => {
              navigate(`/catalog/products/review/${id}`)
            }}
          />
        </td>
      </tr>
      <tr style={{borderBottom: 'none'}}>
        <td colSpan={7}>
          <Stack
            width={'100%'}
            sx={{transition: '0.25s linear all'}}
            maxHeight={isDrow ? '350px' : '0px'}
            // bgcolor={'red'}
            gap='12px'
          >
            {variation_values.map((e: any, i: any) => (
              <Stack key={i} sx={{display: isDrow ? 'block' : 'none'}}>
                <VariationRow item={e} />
              </Stack>
            ))}
          </Stack>
        </td>
      </tr>
      <ViewBtn id={id} setOpen={setView} open={openView} />
    </>
  )
}
