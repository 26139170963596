import {Stack, Typography} from '@mui/material'
import TitlePage from '../customTable/TitlePage'
import {KTSVG} from '../../../_metronic/helpers'
import Tabs from './Tabs'
import {useState} from 'react'
import General from './general'
import Social from './social'
import Languages from './languages'
import Seo from './seo'
import Address from './address'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import Tax from './tax'
import Currency from './currency'
export default function SettingPage() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('storeSettings'),
    home: t('home'),
    linkHome: '/',
  }
  const [active, setActive] = useState(0)
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
      </Stack>
      <Tabs active={active} setActive={setActive} />
      {active === 0 && store.permissions && store.permissions.includes('general_read') && (
        <General />
      )}
      {active === 1 && store.permissions && store.permissions.includes('social_read') && <Social />}
      {active === 2 && store.permissions && store.permissions.includes('languages_read') && (
        <Languages />
      )}
      {active === 3 && store.permissions && store.permissions.includes('seo_read') && <Seo />}
      {active === 4 && store.permissions && store.permissions.includes('address_read') && (
        <Address />
      )}
      {active === 5 && <Tax />}
      {active === 6 && <Currency />}
    </Stack>
  )
}
// var(--bs-body-bg)
