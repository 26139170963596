import React, {useState, useCallback, useEffect} from 'react'
import HeadTable from '../../../modules/customTable/HeadTable'
import TableHead from '../../../modules/orders/TablHead'
import Row from './Row'
import Banner from '../../../Api/banner'
import {PaginationSection} from '../../../modules/customTable/pagnationSection'
import {CustomerListLoading} from '../../../modules/customer/order/custmerListLoading'
import {CircularProgress, Stack, Typography} from '@mui/material'
import IBanner from '../../../interface/IBanner'
import Manufacturers from '../../../Api/manufacturers'
import Search from '../../../modules/customTable/Search'
import Create from '../../../modules/orders/Create'
import SelectState from '../../../modules/orders/SelectState'
import {useTranslation} from 'react-i18next'
import OrderApi from '../../../Api/order'
import HomeApi from '../../../Api/home'

export default function Table() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [added, setAdded] = useState(false)
  const [rows, setRows] = useState([])

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(e.target.value, 5, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )

  const getCoupons = async (searchText: string, rowsPerPage: number, page: number) => {
    const coupons = await HomeApi.getOrder(
      searchText,
      (res: any) => {
        setRows(res.data)
      },
      setIsLoading
    )
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }

  useEffect(() => {
    if (search && page === 1) {
      // Handle search and page
    } else {
      getCoupons('', rowsPerPage, page)
    }
  }, [rowsPerPage, page])

  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])

  const {t} = useTranslation()

  const options = [
    {value: 'allOrder', label: t('allOrder')},
    {value: 'pending', label: t('pending')},
    {value: 'preparing', label: t('preparing')},
    {value: 'in_progress', label: t('inProgress')},
    {value: 'refunded', label: t('refunded')},
    {value: 'completed', label: t('completed')},
    {value: 'canceled', label: t('canceled')},
  ]

  // const handelFilterData = async (state: any) => {
  //   setIsLoading(true)
  //   const data = await HomeApi.getOrder('')
  //   setIsLoading(false)
  //   setRows(data.data.data)
  // }

  const [changeState, setChangeState] = useState({value: 'allOrder'})

  return (
    <div
      style={{marginTop: '30px', padding: '2rem !important', overflowX: 'auto'}}
      className='card fds sha'
    >
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive'>
          <Stack
            alignItems='center'
            m='15px 0'
            width='100%'
            justifyContent='space-between'
            direction={{xs: 'column', sm: 'row'}}
          >
            <Stack>
              <Typography sx={{fontSize: '17px', fontWeight: '600'}}>
                {t('latestOrders')}
              </Typography>
              <Typography sx={{fontSize: '14px', fontWeight: '400', color: '#B5B5C3'}}>
                {t('avg')}
              </Typography>
            </Stack>
            <Stack width='200px'>
              <Search search={search} handleSearch={handleSearch} />
            </Stack>
          </Stack>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead isActive={changeState.value} />
            {!added ? (
              <>
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.map((e, i) => (
                      <Row isActive={changeState.value} item={e} i={i} key={i} />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>
        </div>
      </div>
    </div>
  )
}
