import {Stack} from '@mui/material'
import React from 'react'
import Label from './Label'

export default function EmptyInput({width, text}: any) {
  return (
    <Stack width={width || 'auto'} gap='8px'>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Label text={text} />
      </Stack>
      <Stack
        gap='9px'
        direction={'row'}
        borderRadius={'6.18px'}
        p='5px'
        border='1px solid var(--bs-gray-300)'
        height={'47.2px'}
      ></Stack>
    </Stack>
  )
}
