import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import UploadImg from '../customTable/UploadImg'
import ErrorMsg from '../customTable/ErrorMsg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import {bannerSchema} from '../../yup/BannerShcema'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ValidationFun from '../../yup/ValidationFun'

export default function Form({
  rows,
  setRows,
  setOpen,
  open,
  setAdded,
  setNum,
}: {
  rows: IBanner[] | never[]
  setRows: React.Dispatch<React.SetStateAction<IBanner[] | never[]>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  setAdded: any
  setNum: any
}) {
  const [base64Image, setBase64Image] = React.useState('')
  const [banner, setBanner] = useState({type: 1})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const {t} = useTranslation()
  const [errors, setErrors] = useState({})
  const saveChange = async () => {
    ValidationFun(
      {
        ...banner,
        type: banner.type === 0 ? 'sub_banner' : 'banner',
        image: base64Image,
      },
      bannerSchema,
      setErrors,
      () => {
        Banner.add(
          {
            ...banner,
            type: banner.type === 0 ? 'sub_banner' : 'banner',
            image: base64Image,
          },
          (res: any) => {
            setNum((prev: any) => prev + 1)
            setBase64Image('')
            setMsgErr('')
            setOpen(false)
            setRows([res, ...rows])

            setBanner({type: 1})
            setAdded(true)
          },
          setUsLoading
        )
      }
    )
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <form action=''>
        <p style={{width: '500px'}}></p>
        <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
          {t('addBanner')}
        </Typography>
        {open && (
          <Stack
            mt='25px'
            width='150px'
            height={'150px'}
            padding={base64Image ? '0' : '15px'}
            sx={{
              boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
              borderRadius: '7px',
            }}
          >
            <UploadImg
              name='image'
              errors={errors}
              open={open}
              base64Image={''}
              setBase64Image={setBase64Image}
            />
          </Stack>
        )}

        <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
          <Stack direction={'row'} gap='5px'>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                color: 'var(--bs-body-color)',
                lineHeight: '16px',
              }}
            >
              {t('title')}
            </Typography>
            <RedStart />
          </Stack>
          <Box width='100%'>
            <InputType
              errors={errors}
              name={'title'}
              change={setBanner}
              value={banner}
              type='text'
            />
          </Box>
          <ErrorMsg msgErr={msgErr} name='title' />
        </Stack>
        <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
          <Stack direction={'row'} gap='5px'>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                color: 'var(--bs-body-color)',
                lineHeight: '16px',
              }}
            >
              {t('type')}
            </Typography>
            {/* <RedStart /> */}
          </Stack>
          <Box width='100%'>
            <MySelect
              option={[
                {name: t('banner'), id: 1},
                {name: t('subBanner'), id: 0},
              ]}
              values={banner}
              setValues={setBanner}
              name='type'
              value={{id: 1}}
              errors={errors}
            />{' '}
          </Box>
          <ErrorMsg msgErr={msgErr} name='Type' />
        </Stack>
        <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
          <Stack direction={'row'} gap='5px'>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                color: 'var(--bs-body-color)',
                lineHeight: '16px',
              }}
            >
              {t('sort')}
            </Typography>
            <RedStart />
          </Stack>
          <Box width='100%'>
            <InputType name={'sort'} change={setBanner} value={banner} type='number' />
          </Box>
          <ErrorMsg msgErr={msgErr} name='sort' />
        </Stack>
        <SaveButton
          text={t('saveChanges')}
          submit={saveChange}
          isLoading={false}
          setOpen={setOpen}
        />
        <Stack alignItems={'flex-end'}>
          <ErrorMsg msgErr={msgErr} name='' />
        </Stack>

        {isLoading && <Loading />}
      </form>
    </Container>
  )
}
