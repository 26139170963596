import {createSlice} from '@reduxjs/toolkit'
import {RootState} from './../../index'
import {FetchStatus} from './../../types'
import {loginVendor} from './thunk'
import {IinitialState, IUser} from './types'

const initialState: IinitialState = {
  user: localStorage.getItem('token') || null,
  userInfo: null,
  isUser: null,
  status: FetchStatus.idle,
  error: null,
  lang: 'en',
  variationImg: '',
  isAddMore: false,
  langs: [],
  defLang: '',
  langStatus: 0,
  isRtl: 0,
  cashData: {description: {}},
  cashCataData: {},
  storeLangs: [],
  permissions: [],
  myPackage: [],

  StoreImage: null,
  domain: null,
  viewProduct: {images: [], imagesSeved: []},
}

const userResponseToUserState = (user: IUser) => {
  if (!user?.id) return null
  return {
    ...user,
  }
}

const VendorSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload
    },
    setDefLang: (state, action) => {
      state.defLang = action.payload
    },
    setRtl: (state, action) => {
      state.isRtl = action.payload
    },
    setLangs: (state, action) => {
      state.langs = action.payload
    },
    setCashData: (state, action) => {
      state.cashData = action.payload
    },
    setCashCataData: (state, action) => {
      state.cashCataData = action.payload
    },
    setLangStatus: (state, action) => {
      state.langStatus = action.payload
    },
    setStoreLangs: (state, action) => {
      state.storeLangs = action.payload
    },
    setVariationImg: (state, action) => {
      state.variationImg = action.payload
    },
    login: (state, action) => {
      state.user = action.payload
    },
    getUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setViewProduct: (state, action) => {
      state.viewProduct = action.payload
    },
    getStoreImage: (state, action) => {
      state.StoreImage = action.payload
    },
    getDomain: (state, action) => {
      state.domain = action.payload
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload
    },
    setMyPackage: (state, action) => {
      state.myPackage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginVendor.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.user = userResponseToUserState(action.payload)
    })

    builder.addCase(loginVendor.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(loginVendor.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
  },
})
export const actions = VendorSlice.actions

export default VendorSlice.reducer

export const selectVender = (state: RootState) => state.vender
export const isUserLoggedIn = (state: RootState) => Boolean(state?.vender?.user)
