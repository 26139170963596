import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const HomeApi = {
  getSillingTop: (callback: any, setIsLoading: any) => {
    ApisMethods().get('/vendor/dashboard/top-selling', callback, setIsLoading)

    // const data = await Apiservices.get('/vendor/dashboard/top-selling')
    // return data
  },
  getOrder: async (search: any, callback: any, setIsLoading: any) => {
    let url = `/vendor/dashboard/last-orders?`
    if (search) {
      url = url + `search=${search}`
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  getAnalysis: (date: any, callback: any, setIsLoading: any) => {
    if (date.type) {
      ApisMethods().get(`/vendor/dashboard/get-analysis?type=${date.type}`, callback, setIsLoading)
    } else {
      ApisMethods().get(
        `/vendor/dashboard/get-analysis?type=custom-range&from=${date.start}&to=${date.end}`,
        callback,
        setIsLoading
      )
    }
  },
  getChart: async (date: any, callback: any, setIsLoading: any) => {
    ApisMethods().get(
      `/vendor/dashboard/get-chart?type=custom-range&from=${date.start}&to=${date.end}`,
      callback,
      setIsLoading
    )
  },
}
export default HomeApi
