import React from 'react'
import {useTranslation} from 'react-i18next'
import MainBtn from '../../../customTable/MainBtn'

type Props = {
  backTo: string
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
  nextTo?: string
}

export const ActionBackOrNext = (props: Props) => {
  const {backTo, setActiveKey, nextTo} = props
  const {t} = useTranslation()

  return (
    <div style={{gap: '20px'}} className='d-flex justify-content-end mt-4'>
      <button
        className='btn btn-light me-5'
        onClick={() => {
          setActiveKey(backTo)
        }}
      >
        {t('back')}
      </button>
      {nextTo && (
        <MainBtn
          width='auto'
          handelSubmit={() => {
            setActiveKey(nextTo)
          }}
          text='next'
        />
      )}
    </div>
  )
}
