import {useTranslation} from 'react-i18next'
import {EnhancedTableHeadProps, HeadCell} from './types'

export function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const {onSelectAllClick, order, numSelected, rowCount, onRequestSort} = props
  const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
    onRequestSort(event, property)
  }
  const {t} = useTranslation()
  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      label: t('product'),
      align: 'left',
    },
    {
      id: 'sku',
      numeric: false,
      label: t('sku'),
      align: 'left',
    },
    {
      id: 'quantity',
      numeric: false,
      label: t('qty'),
      align: 'left',
    },
    {
      id: 'price',
      numeric: false,
      label: t('price'),
      align: 'left',
    },
    {
      id: 'rate',
      numeric: false,
      label: t('rating'),
      align: 'left',
    },
    {
      id: 'status',
      numeric: false,
      label: t('status'),
      align: 'left',
    },
  ]
  return (
    <thead>
      <tr className='fw-bold text-muted'>
        {/* <th className='w-25px'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              onChange={onSelectAllClick}
              checked={rowCount > 0 && numSelected === rowCount}
              data-kt-check='true'
              data-kt-check-target='.widget-9-check'
            />
          </div>
        </th> */}
        {numSelected > 0 && (
          <th className=''>
            <span className=' fw-bold bg-body btn-color-gray-700 btn-active-color-primary'>
              {numSelected} selected
            </span>
          </th>
        )}
        {/* {numSelected > 0 && (
          <td colSpan={7}>
            <div className='d-flex text-center w-100 align-content-center justify-content-end'>
              <button className='btn btn-danger  fw-bold fw-semibold px-6 py-3'>
                <span className='svg-icon svg-icon-3'>
                  <i className='bi bi-trash'></i>
                </span>
                Delete
              </button>
            </div>
          </td>
        )} */}
        {numSelected <= 0 && (
          <>
            {headCells.map((headCell) => (
              <th className='min-w-140px ' key={headCell.id}>
                {headCell.label}
              </th>
            ))}
            <th style={{width: '108px'}} className='min-w-140px text-start'>
              {t('action')}
            </th>{' '}
          </>
        )}
      </tr>
    </thead>
  )
}
