import React, {useState} from 'react'
import {Stack} from '@mui/material'

import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'
import PopUp from '../../customTable/PopUp'

export default function Row({
  item,
  i,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
}) {
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelOpen = () => {
    setOpen(true)
  }

  const [isDeleted, setDeleted] = useState(true)

  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>
                Easy online store to launch your business for freeEasy online store to launch your
                business for free
              </a>
            </td>
            <td style={{textAlign: 'center'}}>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td style={{textAlign: 'center'}}>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td style={{textAlign: 'center'}}>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td style={{textAlign: 'center'}}>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
          </tr>
        </>
      )}
    </>
  )
}
