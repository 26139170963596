import ApexCharts, {ApexOptions} from 'apexcharts'
import {useEffect, useRef} from 'react'
import {getCSS, getCSSVariableValue} from '../../assets/ts/_utils'
import {useThemeMode} from '../layout/theme-mode/ThemeModeProvider'
import {Stack} from '@mui/material'

interface ApexChartProps {
  charts: any
}
interface ApexChartState {
  series: {
    name: string
    data: number[]
  }[]
  options: ApexOptions
}

const AreaChartLine = (props: ApexChartProps) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {charts} = props
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, charts))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, charts])
  return (
    <div className='card-body pb-2'>
      {/* begin::Chart */}
      <Stack ref={chartRef} sx={{height: '270px', width: '100%'}} className='' />
    </div>
  )
}

function getChartOptions(height: number, charts: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = '#ED436F'
  const lightColor = 'red'

  const options: any = {
    series: [
      {
        name: 'Sales',
        data: charts.sales || [],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 80, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: charts.dates ? ['', ...charts.dates, ''] : charts.dates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,
      labels: {
        offsetY: 10, // تغيير القيمة إلى 20

        rotate: 0,
        rotateAlways: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: -100, // قم بتغيير هذه القيمة
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      max: (charts.sales && Math.max(...charts.sales)) || 10000,
      min: (charts.sales && Math.min(...charts.sales)) || 0,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val: any) {
          return '$' + val
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val: any) {
          return '$' + val
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      //  strokeColor: [baseLightColor],
      strokeWidth: 3,
    },
  }

  return options
}

export default AreaChartLine
