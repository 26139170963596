import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'

// let languages = SetLang() || ['en']
export const Cuoponschema = (languages: any) => {
  const cuopons = Yup.object().shape({
    code: Yup.string().required('couponCodeErr'),
    description: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`descriptionErr`),
        }
      }, {})
    ),
    discount_amount: Yup.number().required('discountErr'),
    discount_type: Yup.number().required('discountTypeErr'),
    end_date: Yup.date().required('endDateErr'),
    is_free_shipping: Yup.boolean(),
    limit_usage_to_x_items: Yup.number().required(),
    min_spend: Yup.number().required(),
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),

    start_date: Yup.date().required('startDateErr'),
    status: Yup.number().required('statusErr'),
    usage_limit_per_coupon: Yup.number().required('usageLimitPerCouponErr'),
    usage_limit_per_user: Yup.number().required('usageLimitPerUserErr'),
  })
  return cuopons
}
