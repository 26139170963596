import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import UploadImg from '../customTable/UploadImg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import {ManfactuerSchema} from '../../yup/Manfactuer'
import LabelInput from '../customTable/LabelInput'
import TabsWithOutFlag from '../sittings/general/TabsWithOutFlag'
import Tabs from '../customTable/Tabs'
import Manufacturers from '../../Api/manufacturers'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import TabsWithFlag from '../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import ValidationFun from '../../yup/ValidationFun'

export default function Form({
  rows,
  setRows,
  setOpen,
  open,
  setAdded,
  setNum,
}: {
  rows: any[] | never[]
  setRows: React.Dispatch<React.SetStateAction<any[] | never[]>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  setAdded: any
  setNum: any
}) {
  const [base64Image, setBase64Image] = React.useState('')
  const [banner, setBanner] = useState({sort: 0})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const {t} = useTranslation()

  const store = useSelector((state: RootState) => state.vender)

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(store.defLang)
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  const [languagess, setlanguagess] = useState(['en'])

  useEffect(() => {
    setlanguagess(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState<any>({})

  const saveChange = async () => {
    ValidationFun(
      {
        ...banner,
        image: base64Image,
      },
      ManfactuerSchema(languagess),
      setErrors,
      (value: any) => {
        Manufacturers.add(
          {
            ...banner,
            image: base64Image,
          },
          (res: any) => {
            setNum((prev: any) => prev + 1)
            setBase64Image('')
            setMsgErr('')
            setUsLoading(false)
            setOpen(false)
            setRows([{...res, status: 1}, ...rows])
            setBanner({sort: 0})
            setAdded(true)
          },
          setUsLoading
        )
      }
    )
  }
  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {t('addManufacturer')}
      </Typography>
      {open && (
        <Stack
          mt='25px'
          width='150px'
          height={'150px'}
          padding={base64Image ? '0' : '15px'}
          sx={{
            boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
            borderRadius: '7px',
          }}
        >
          <UploadImg
            errors={errors}
            name='image'
            open={open}
            base64Image={''}
            setBase64Image={setBase64Image}
          />
        </Stack>
      )}

      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Box width='100%'>
          {selectedLang.map((e: any, i: number) => (
            <>
              {e.code === defLang && (
                <Stack gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('name')} />
                    <RedStart />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'name'}
                      change={setBanner}
                      value={banner}
                      type='text'
                      child={e.code}
                      errors={errors}
                    />
                    <Stack
                      position={'absolute'}
                      left={store.isRtl === 1 ? 0 : 'auto'}
                      right={store.isRtl === 0 ? 0 : 'auto'}
                      top='-54px'
                    >
                      <TabsWithFlag
                        languages={selectedLang}
                        activeLang={defLang}
                        setActiveLang={setDefLang}
                        msgErr={errors && Object.keys(errors)[0]}
                      />

                      {/* <Tabs activeLang={activeLang} setActiveLang={setActiveLang} /> */}
                    </Stack>
                  </Box>
                </Stack>
              )}
            </>
          ))}{' '}
        </Box>
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('sort')}
          </Typography>
          {/* <RedStart /> */}
        </Stack>
        <InputType name={'sort'} change={setBanner} value={banner} type='number' />
      </Stack>
      <SaveButton text={t('saveChanges')} submit={saveChange} isLoading={false} setOpen={setOpen} />
      <Stack alignItems={'flex-end'}></Stack>

      {isLoading && <Loading />}
    </Container>
  )
}
