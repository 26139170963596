import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function LabelInput({text}: {text: string}) {
  const {t} = useTranslation()
  return (
    <Typography
      sx={{
        fontSize: '13.5px',
        color: 'var(--bs-heading-color, inherit)',
        fontWeight: 400,
        lineHeight: '16px',
        textTransform: 'capitalize',
      }}
    >
      {t(text)}
    </Typography>
  )
}
