import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import CheckIcon from '@mui/icons-material/Check'
import {useTranslation} from 'react-i18next'
export default function CheckBox({isCheck, setIsCheck}: any) {
  const {t} = useTranslation()

  const checkBox = [
    {name: t('image'), value: 'image'},

    {name: t('video'), value: 'video'},
  ]

  return (
    <Stack alignItems={'center'} gap={'24px'} direction={'row'}>
      <Stack gap={'24px'} direction={'row'}>
        {checkBox.map((e, i) => (
          <Stack
            onClick={() => setIsCheck(e.value)}
            alignItems={'center'}
            gap={'8px'}
            direction={'row'}
          >
            <Stack
              width={'24px'}
              borderRadius={'6px'}
              height={'24px'}
              bgcolor={'var(--bs-app-bg-color)'}
            >
              {isCheck === e.value && (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'100%'}
                  borderRadius={'6px'}
                  bgcolor={'var(--main-color)'}
                >
                  <CheckIcon sx={{color: 'white'}} />
                </Stack>
              )}
            </Stack>
            <Typography sx={{fontSize: '14px', color: 'var(--bs-text-dark)'}}>{e.name}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
