import * as Yup from 'yup'

export default async function ValidationFun(data: any, schema: any, setErrors: any, callback: any) {
  try {
    setErrors({})
    console.log(data, 'schema')

    await schema.validate(data, {abortEarly: false})
    callback(data)
    return {valid: true, Module: data}
  } catch (err: any) {
    const errors: {[key: string]: string} = {}
    if (err instanceof Yup.ValidationError && err.inner) {
      err.inner.forEach((error) => {
        if (error.path) {
          errors[error.path] = error.message
        }
      })
    }
    console.log(errors, err.message)

    setErrors(errors)

    return {valid: false, errors}
  }
}
