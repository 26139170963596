import {Stack, Typography} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'
import Doller from '../../../svg/Doller'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Nike from '../../../svg/Nike'
import {useTranslation} from 'react-i18next'

export default function Package({item, width}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  return (
    <Stack
      alignItems={'center'}
      p='40px'
      borderRadius={'8px'}
      border={'1px solid #D8D8D8'}
      width={{xs: '100%', sm: '50%', lg: width ? '50%' : '25%'}}
      minWidth={'262px'}
    >
      <Label align='center' fs='20px' fw='700' color='#666' text={item.type} />
      <Stack direction={'row'} m='11px auto 0 auto' position={'relative'}>
        <Stack width={'48px'}></Stack>
        <Stack
          sx={{
            left: store.isRtl === 0 ? '20px' : 'auto',
            right: store.isRtl !== 0 ? '20px' : 'auto',
            top: '18px',
          }}
          position={'absolute'}
        >
          <Doller />
        </Stack>
        <Stack>
          <Label fw='700' fs='48px' text={item.amount} />
        </Stack>
        <Stack position={'relative'} width={'48px'}>
          <Stack
            position={'absolute'}
            sx={{
              left: store.isRtl === 0 ? '5px' : 'auto',
              right: store.isRtl !== 0 ? '5px' : 'auto',
              bottom: '10px',
            }}
          >
            <Label text='/MO' fw='300' fs='24px' />
          </Stack>
        </Stack>
      </Stack>
      <Stack m='41px 0 50px 0' gap='16px'>
        {item.advantages.map((e: any) => (
          <Stack justifyContent={'flex-end'} gap='19px' direction={'row'}>
            <Label fs='13px' align='end' fw='400' text={e} />
            <Stack height={'16px'} width={'16px'}>
              <Nike color={item.color} />
            </Stack>
          </Stack>
        ))}
      </Stack>
      <button
        style={{
          border: item.comingSoon ? `1px solid ${item.color}` : 'none',
          color: item.comingSoon ? item.color : '#fff',
          background: !item.comingSoon ? item.color : '#fff',
          padding: '10px 16px',
          borderRadius: '8px',
          fontSize: '14px',
          fontWeight: '600',
        }}
      >
        {t(item.comingSoon ? 'comingSoon' : 'getStarted')}
      </button>
    </Stack>
  )
}
