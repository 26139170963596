import {Stack} from '@mui/material'
import React, {useEffect} from 'react'
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import Cards from './Cards'
import Msg from './Msg'
import MainBtn from '../customTable/MainBtn'
import {useNavigate} from 'react-router-dom'

export default function Subscriptions() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('subscriptions'),
    home: t('home'),
    linkHome: '/',
  }
  const [open, setOpen] = React.useState(false)
  const navagiate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      // setOpen(true)
    }, 5000)
  }, [])
  return (
    <Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <TitlePage info={bannerInfo} />
        <MainBtn
          handelSubmit={() => {
            navagiate('/subscriptions-date')
          }}
          width='auto'
          text='subscriptionHistory'
        />
      </Stack>
      <Cards />
      <Msg setOpen={setOpen} open={open} />
    </Stack>
  )
}
