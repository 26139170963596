import {Button, CircularProgress} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {useNavigate} from 'react-router-dom'

export default function MainBtn({
  isLoading,
  handelSubmit,
  text,
  disabled,
  width,
  p,
  fs,
  isAmount,
  bgcolor,
  type,
  without,
  height,
  secondary,
  br,
  num,
  backageKey,
}: any) {
  const {myPackage} = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const {t} = useTranslation()
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const translatedText = t(text)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <Button
      type={type}
      disabled={disabled || isLoading}
      sx={{
        fontSize: fs || '14px',
        color: without ? 'var(--main-color)' : secondary ? '#129AFC' : 'white',
        fontWeight: '600',
        background:
          disabled || isLoading
            ? '#92e6fc'
            : bgcolor
            ? bgcolor
            : without
            ? '#fff'
            : secondary
            ? 'rgba(18, 154, 252, 0.10)'
            : 'var(--main-color)',
        borderRadius: br || '4px',
        padding: p || '10px 24px',
        border: without ? '1px solid var(--main-color)' : 'none',
        width: width || '100%',
        minWidth: '120px',
        height: height || 'auto',
        textTransform: 'none',
        '&:hover': {
          color: '#f16645',
          border: '1px solid #f16645',
        },
      }}
      onClick={
        myPackage[backageKey] && myPackage[backageKey].amount && myPackage[backageKey].amount <= num
          ? () => {
              navigate('/subscriptions-change-package')
            }
          : handelSubmit
      }
    >
      {myPackage[backageKey] &&
      myPackage[backageKey].amount &&
      myPackage[backageKey].amount <= num ? (
        t('changeBackage')
      ) : isLoading ? (
        <CircularProgress size={'20px'} color='info' />
      ) : isAmount ? (
        translatedText
      ) : (
        capitalizedText
      )}
    </Button>
  )
}
