import React, {useState} from 'react'
import IPage from '../../../interface/IPage'
import {Box, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import Edit from '../media/Edit'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export default function CardMedia({item}: {item: IPage}) {
  const [editItem, setEditItem] = useState(item)
  const [open, setOpen] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const {t} = useTranslation()
  const copyLink = () => {
    navigator.clipboard
      .writeText(editItem.attachment)
      .then(() => {
        setOpen(true)
      })
      .catch(() => {})
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack gap='30px' direction={'column'}>
      {editItem.type === 'image' ? (
        <img style={{width: '100%', maxHeight: '294px'}} src={editItem.attachment} alt='' />
      ) : (
        <video
          style={{width: '100%', maxHeight: '294px'}}
          controls
          src={editItem.attachment}
        ></video>
      )}
      <Box border={'1px solid var(--bs-gray-300)'} borderRadius={'8px'} p='14px'>
        <Typography width={'100%'} overflow={'hidden'}>
          {editItem.attachment}
        </Typography>
      </Box>
      <Stack direction={'row'} gap={'10px'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <MainBtn width='auto' handelSubmit={copyLink} text='copyUrl' />

        {store.permissions && store.permissions.includes('medias_update') && (
          <MainBtn
            width='auto'
            handelSubmit={() => {
              setOpenEdit(true)
            }}
            text='edit'
          />
        )}
      </Stack>
      <Edit setItem={setEditItem} item={editItem} open={openEdit} setOpen={setOpenEdit} />

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' sx={{width: '100%'}}>
          {t('msgSuccessfully')}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
