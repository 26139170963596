import {useCallback, useMemo, useState, useEffect} from 'react'

import {FormikProps} from 'formik'
import 'react-quill/dist/quill.snow.css'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {RootState, useDispatch, useSelector} from '../../../store'
import {Language} from '../../../types'
import {InitialValues} from './validationSchema'

import 'react-quill/dist/quill.snow.css'
import {FileZone} from './componnet/fileZone'
import {Advanced} from './tabs/Advanced'
import {BulkDiscount} from './tabs/bullt'
import {General} from './tabs/General'
import {Links} from './tabs/Links'
import Options from './componnet/option/Options'
import {Container, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import WarperBackages from '../../customTable/WarperBackages'

type Props = {
  formik: FormikProps<InitialValues>
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  activeKey: any
  setActiveKey: any
}
const TabsConst = [
  {
    eventKey: 'General',
    title: 'general',
    isAllow: true,
  },
  {
    eventKey: 'Advanced',
    title: 'advanced',
  },
  {
    eventKey: 'Links',
    title: 'Links',
  },
  {
    eventKey: 'Media',
    title: 'Media',
  },
  {
    eventKey: 'Options',
    title: 'Options',
  },
  {
    eventKey: 'Bulk_Discount',
    title: 'Bulk Discount',
  },
]

export const tabsItem = TabsConst.map((x) => x.eventKey)
const TabsComponent = ({formik, setErrorMsg, activeKey, setActiveKey, setErrors, errors}: any) => {
  const {id} = useParams<{
    id?: string
  }>()
  const location = useLocation()
  const isEditCategory = location.pathname.includes('edit-product')
  //
  const [isActive, setActive] = useState(1)
  useEffect(() => {
    if (id) {
      setActive(7)
    } else {
      setActive(1)
    }
  }, [])
  const {t} = useTranslation()

  const tabs = useMemo(() => {
    return [
      {
        eventKey: 'General',
        title: 'general',
        isAllow: true,
      },
      {
        eventKey: 'Advanced',
        title: 'advanced',
        isAllow: true,
      },
      {
        eventKey: 'Links',
        title: 'links',
        isAllow: true,
      },
      {
        eventKey: 'Media',
        title: 'media',
        isAllow: isEditCategory,
      },
      {
        eventKey: 'Options',
        title: 'options',
        isAllow: isEditCategory,
      },
      {
        eventKey: 'Bulk_Discount',
        title: 'bulkDiscount',
        isAllow: isEditCategory,
      },
    ]
  }, [isEditCategory])

  const [activeLang, setActiveLang] = useState<Language>('en')
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    if (store.defLang) {
      setActiveLang(store.defLang)
    }
  }, [store.langs])
  const handleTabClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    //     setActiveKey(event.currentTarget.dataset.eventKey)
  }, [])
  const [fullActive, setFullActive] = useState(false)

  return (
    <Stack
      // maxWidth='lg'
      style={{paddingLeft: '0', paddingRight: '0'}}
      className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'
    >
      {/* {activeKey} */}

      <ul
        style={{padding: '0'}}
        className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'
      >
        {tabs?.map((tab, index) => (
          <li className='nav-item' key={index}>
            <a
              style={{
                fontWeight: '600',
                // borderBottom: !tab.isAllow || isActive <= index ? '0' : '5px solid red !important',
              }}
              className={`nav-link text-active-primary pb-4 ${
                activeKey === tab.eventKey ? 'active' : ''
              } 
              ${!tab.isAllow || isActive <= index ? 'disabled' : ''}
              `}
              data-bs-toggle='tab'
              href={`#kt_ecommerce_add_product_${tab.eventKey}`}
              onClick={() => {
                // handleTabClick()

                setActiveKey(tab.eventKey)
              }}
            >
              {t(tab.title)}
            </a>
          </li>
        ))}
      </ul>
      <div className='tab-content'>
        <div
          className={`tab-pane fade ${activeKey === tabsItem[0] ? 'active show' : ''}`}
          id={`kt_ecommerce_add_product_${tabsItem[0]}`}
        >
          <General
            formik={formik}
            activeLang={activeLang}
            setActiveLang={setActiveLang}
            setActiveKey={setActiveKey}
            setActive={setActive}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div
          className={`tab-pane fade ${activeKey === tabsItem[1] ? 'active show' : ''}`}
          id={`kt_ecommerce_add_product_${tabsItem[1]}`}
        >
          <Advanced
            errors={errors}
            setErrors={setErrors}
            setActive={setActive}
            formik={formik}
            setActiveKey={setActiveKey}
          />
        </div>
        <div
          className={`tab-pane fade ${activeKey === tabsItem[2] ? 'active show' : ''}`}
          id={`kt_ecommerce_add_product_${tabsItem[2]}`}
        >
          <Links
            activeKey={activeKey}
            setErrorMsg={setErrorMsg}
            formik={formik}
            setActiveKey={setActiveKey}
            setFullActive={setFullActive}
            setActive={setActive}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div
          className={`tab-pane fade ${activeKey === tabsItem[3] ? 'active show' : ''}`}
          id={`kt_ecommerce_add_product_${tabsItem[3]}`}
        >
          <FileZone setActiveKey={setActiveKey} />
        </div>
        <div
          className={`tab-pane fade ${activeKey === tabsItem[4] ? 'active show' : ''}`}
          id={`kt_ecommerce_add_product_${tabsItem[4]}`}
        >
          <WarperBackages width backageKey='PRODUCT_OPTION_NUM'>
            <Options
              setActive={setActive}
              activeKey={activeKey}
              formik={formik}
              setActiveKey={setActiveKey}
            />
          </WarperBackages>
        </div>
        <div
          className={`tab-pane fade ${activeKey === tabsItem[5] ? 'active show' : ''}`}
          id={`kt_ecommerce_add_product_${tabsItem[5]}`}
        >
          <WarperBackages width backageKey='BULK_DISCOUNT_NUM'>
            <BulkDiscount setActiveKey={setActiveKey} activeKey={activeKey} />
          </WarperBackages>
        </div>
      </div>
    </Stack>
  )
}

export default TabsComponent
