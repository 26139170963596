import {Stack} from '@mui/material'
import React, {useState} from 'react'
import LabelWithText from '../../customTable/LabelWithText'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import StaticInputFile from '../../customTable/StaticInputFile'
import CheckInputWithLabel from '../../customTable/CheckInputWithLabel'
import UploadImgBtn from '../../customTable/UploadImgBtn'
import EmptyInput from '../../customTable/EmptyInput'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useTranslation} from 'react-i18next'

export default function EmptyFormTax({isEdit}: any) {
  const [data, setData] = useState<any>({check: 1})
  const store = useSelector((state: RootState) => state.vender)
  const [base64Image, setBase64Image] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const {t} = useTranslation()
  return (
    <Stack flexWrap={'wrap'} gap='24px' direction={'row'} p={isEdit ? '32px 0' : '32px'}>
      <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}} position={'relative'}>
        <LabelWithText name='tax' type='number' data={data} setData={setData} text='tax' />
        <Stack
          sx={{
            left: store.isRtl !== 0 ? '0px' : 'auto',
            right: store.isRtl === 0 ? '0px' : 'auto',
            bottom: '2px',
            borderRadius: '6.18px',
            color: '#fff',
          }}
          p='13px'
          bgcolor={'var(--orange-color)'}
          position={'absolute'}
        >
          %
        </Stack>
      </Stack>
      <StaticInputFile width={{xs: '100%', md: 'calc(50% - 12px)'}} text='commercialRegister' />
      {!isEdit && (
        <Stack width='100%' gap='24px' direction={'row'}>
          <CheckInputWithLabel
            onClick={() => setData({...data, check: 1})}
            check={data.check}
            value={1}
            text={'citizen'}
          />
          <CheckInputWithLabel
            onClick={() => setData({...data, check: 0})}
            check={data.check}
            value={0}
            text={'resident'}
          />
        </Stack>
      )}{' '}
      {data.check === 1 ? (
        <>
          <Stack position={'relative'} width={{xs: '100%', md: 'calc(50% - 12px)'}}>
            {data.frontImage ? (
              <StaticInputFile text='frontImage' />
            ) : (
              <EmptyInput text='frontImage' />
            )}
            <Stack
              sx={{
                left: store.isRtl !== 0 ? '24px' : 'auto',
                right: store.isRtl === 0 ? '24px' : 'auto',
                bottom: '-43px',
              }}
              position={'absolute'}
            >
              {' '}
              <UploadImgBtn
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                name='frontImage'
                data={data}
                setData={setData}
              />
            </Stack>
          </Stack>
          <Stack position={'relative'} width={{xs: '100%', md: 'calc(50% - 12px)'}}>
            {data.backImage ? (
              <StaticInputFile text='backImage' />
            ) : (
              <EmptyInput text='backImage' />
            )}
            <Stack
              sx={{
                left: store.isRtl !== 0 ? '24px' : 'auto',
                right: store.isRtl === 0 ? '24px' : 'auto',
                bottom: '-43px',
              }}
              position={'absolute'}
            >
              {' '}
              <UploadImgBtn
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                name='backImage'
                data={data}
                setData={setData}
              />
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          {' '}
          <Stack position={'relative'} width={{xs: '100%', md: 'calc(50% - 12px)'}}>
            {data.backImage ? (
              <StaticInputFile text='pasportImg' />
            ) : (
              <EmptyInput text='pasportImg' />
            )}
            <Stack
              sx={{
                left: store.isRtl !== 0 ? '24px' : 'auto',
                right: store.isRtl === 0 ? '24px' : 'auto',
                bottom: '-43px',
              }}
              position={'absolute'}
            >
              {' '}
              <UploadImgBtn
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                name='pasportImg'
                data={data}
                setData={setData}
              />
            </Stack>
          </Stack>
        </>
      )}
      <RestSaveButton
        isCancel={!isEdit}
        isLoading={isLoading}
        SaveText={t('save')}
        cancelText={t('cancel')}
        submit={() => {}}
        cancel={() => {}}
      />
    </Stack>
  )
}
