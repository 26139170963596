import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import Create from './Create'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import iMedia from '../../../interface/iMedia'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
export default function Media() {
  const {t} = useTranslation()
  const [total, setTotal] = useState(1)

  const bannerInfo = {
    title: t('media'),
    home: t('home'),
    linkHome: '/',
  }
  const [rows, setRows] = useState<iMedia[]>([])
  const [added, setAdded] = useState(false)
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  const store = useSelector((state: RootState) => state.vender)
  const [num, setNum] = useState(0)

  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />

        <Stack gap={'10px'} direction={'row'}>
          {store.permissions && store.permissions.includes('medias_create') && (
            <Create num={num} setNum={setNum} setAdded={setAdded} rows={rows} setRows={setRows} />
          )}
        </Stack>
      </Stack>
      <Table
        setNum={setNum}
        total={total}
        setTotal={setTotal}
        added={added}
        rows={rows}
        setRows={setRows}
        setAdded={setAdded}
      />
    </Stack>
  )
}
