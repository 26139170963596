import React, {useState, useCallback, useEffect} from 'react'
import TableHead from './TablHead'
import Row from './Row'
import Banner from '../../Api/banner'
import {PaginationSection} from '../customTable/pagnationSection'
import {CircularProgress} from '@mui/material'
import IBanner from '../../interface/IBanner'
import JwtService from '../../utils/TokenServices'
import {useDispatch} from 'react-redux'
import {actions} from '../../store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'

export default function Table({
  rows,
  setRows,
  added,
  setAdded,
  total,
  setTotal,
  setNum,
}: {
  rows: IBanner[] | never[]
  setRows: React.Dispatch<React.SetStateAction<IBanner[] | never[]>>
  added: boolean
  setAdded: any
  total: any
  setTotal: any
  setNum: any
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(newSearchValue, 5, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )
  const dispatch = useDispatch()

  const getCoupons = (searchText: string, rowsPerPage: number, page: number) => {
    Banner.get(
      searchText,
      rowsPerPage,
      page,
      (coupons: any) => {
        console.log(coupons)

        setTotal(coupons.pages.total)

        setRows(coupons.data)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    getCoupons('', rowsPerPage, page)
  }, [rowsPerPage, page])
  return (
    <div style={{marginTop: '30px'}} className={`card fds sha`}>
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.map((e, i) => (
                      <Row
                        setNum={setNum}
                        setAdded={setAdded}
                        setRows={setRows}
                        rows={rows}
                        item={e}
                        i={i}
                        key={i}
                      />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  )
}
