import {FormikProps} from 'formik'
import {useState} from 'react'
import {Language} from '../../../types'
import {ActionsButton} from './actionsButton'
import {useParams} from 'react-router-dom'
import TextOption from './TextOption'

export interface ItemOptionColor {
  color?: string
  sort: number
  option_value: {
    [key in Language]: string
  }
}

interface OptionDetailsProps {
  optionDetails: Array<ItemOptionColor>
  formik: FormikProps<any>
  activeLang: Language
  disabled?: boolean
  handleRemoveOption: (id: string | number) => Promise<void>
  handleEditOption: (id: string | number, data: any) => Promise<void>
  msgErr: any
  setActiveAdd: any
  Adderrors: any
}

export function OptionTextDetails({
  optionDetails,
  activeLang,
  formik,
  disabled,
  handleRemoveOption,
  handleEditOption,
  msgErr,
  setActiveAdd,
  Adderrors,
}: OptionDetailsProps) {
  const {id} = useParams<{
    id?: string
  }>()
  const [rowSlectedToEdit, setRowSlectedToEdit] = useState<number>(-1)
  const [isAllowToEdit, setIsAllowToEdit] = useState<boolean>(false)

  const handleRemove = async (index: number) => {
    handleRemoveOption(formik.values.optionDetails[index].id)
  }
  const handleEdit = async (index: number) => {
    setRowSlectedToEdit(index)
    setIsAllowToEdit(true)
    // handleEditOption(formik.values.optionDetails[index].id, formik.values.optionDetails[index]);
  }

  return (
    <div style={{background: 'var(--bs-app-bg-color)'}} className='row  g-4   '>
      {(formik?.values?.optionDetails as Array<ItemOptionColor>)?.map((option, index) => (
        <TextOption
          option={option}
          index={index}
          formik={formik}
          disabled={disabled}
          handleRemoveOption={handleRemoveOption}
          handleEditOption={handleEditOption}
          msgErr={msgErr}
          setActiveAdd={setActiveAdd}
          Adderrors={Adderrors}
        />
      ))}
    </div>
  )
}
