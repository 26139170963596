import React from 'react'
interface ToggleProps {
  value: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => any
  name?: string
  readonly?: boolean
}
export default function Switch({name, value, handleChange}: ToggleProps) {
  return (
    <div className='text-center d-flex'>
      <label className='form-check form-switch form-switch-sm form-check-custom form-switch-custom-red form-check-solid flex-stack'>
        <span className='form-check-label text-danger fs-6 fw-bold ms-0 me-2'></span>
        <input
          name={name}
          className='form-check-input'
          type='checkbox'
          checked={value}
          onChange={handleChange}
        />
      </label>
    </div>
  )
}
