import React, {useState} from 'react'
import {ActionsCell} from '../../../componet/Menu'
import {Stack} from '@mui/material'
import IBanner from '../../../interface/IBanner'
import Switch from '../../../componet/Toastr/Switch'
import Banner from '../../../Api/banner'
import Edit from '../../optionCatalog/Edit'
import Manufacturers from '../../../Api/manufacturers'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'
import OptionCatalog from '../../../Api/optionCatalog'
import Options from '../../../Api/options'
import {useNavigate} from 'react-router-dom'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setIsLoading,
  setAdded,
  setNum,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
  setNum: any
  setAdded: any
}) {
  const [state, setState] = useState<any>(item.status)
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    Options.changeStatus(
      item.id,
      {status: state === 1 ? 0 : 1},
      () => {
        setState(state === 1 ? 0 : 1)
      },
      setIsLoadingStatus
    )
  }
  // const handleDelete = useCallback(() => {
  //   dispatch(
  //     withToastr(
  //       deleteOptionAction({
  //         id,
  //       }),
  //       {
  //         success: {
  //           title: 'we  are  success  to  delete item',
  //         },
  //       }
  //     )
  //   )
  // }, [dispatch, id])
  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const openForm = () => {
    setOpen(true)
  }
  const handelDelete = async () => {
    Options.destroy(
      item.id,
      () => {
        setNum((prev: any) => prev - 1)

        setRows(rows.filter((e: any) => e.id !== item.id))
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(!true)
        }, 1)
      },
      setIsLoadingDelete
    )
  }
  const edit = () => {
    navigate(`/catalog/edit-option/${item.id}`)
  }
  const {lang, defLang, permissions} = useSelector((state: RootState) => state.vender)
  const {name, value_type} = item
  return (
    <>
      {isDeleted && (
        <tr>
          <td className=' pe-0' data-order='17'>
            <span className='fw-bold ms-3'>{i + 1}</span>
          </td>

          <td className=''>
            {typeof name === 'object'
              ? (name[lang] || name[defLang]) && (name[lang] || name[defLang]).slice(0, 15)
              : name}{' '}
            {name &&
              (name[lang] || name[defLang]) &&
              (name[lang] || name[defLang]).length > 15 &&
              '...'}
          </td>
          <td className=''>
            {typeof value_type === 'object' ? value_type[lang] || value_type[defLang] : value_type}
          </td>
          <td className=''>{typeof name === 'object' ? name[lang] || name[defLang] : name}</td>
          <td>
            {isLoadingStatus ? (
              <SimpleLoading />
            ) : (
              <Switch
                name='status'
                value={state === 1 ? true : false}
                handleChange={
                  permissions && permissions.includes('options_update') && handleStatusChange
                }
              />
            )}
          </td>

          <td
            style={{display: 'flex', justifyContent: 'center', border: 'none'}}
            className='text-end'
          >
            {isLoadingDelete ? (
              <SimpleLoading />
            ) : (
              <ActionsCell
                onEdit={permissions && permissions.includes('options_update') && edit}
                onDelete={permissions && permissions.includes('options_delete') && handelDelete}
                onView={null}
              />
            )}
          </td>
        </tr>
      )}
    </>
  )
}
