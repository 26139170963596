import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const OptionCatalog = {
  destroy: (id: number, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/option_catalogs/${id}`, callback, setIsLoading)
  },
  putStatus: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/option_catalogs/status/${id}`, data, callback, setIsLoading)
  },
  get: async (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    let url = `/vendor/option_catalogs?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/option_catalogs`, data, callback, setIsLoading)
  },
  put: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/option_catalogs/${id}`, data, callback, setIsLoading)
  },
}
export default OptionCatalog
