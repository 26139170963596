export const isValidLanguages = (x: any) => {
  //    check  if  type of x is object

  return (
    typeof x == 'object' &&
    Object.keys(x)?.every((lang) => {
      const value = x[lang] as any
      return value !== undefined && typeof value == 'string' && value.trim().length > 0
    })
  )
}
