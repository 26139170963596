import * as yup from 'yup'

export const VarationShcema = yup.object().shape({
  height: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('Height is required'),
    otherwise: yup.number(),
  }),
  image: yup.string().required('Image is required'),
  is_shipping_pickup: yup.number().oneOf([0, 1]).required('Shipping pickup is required'),
  is_unlimited: yup.number().oneOf([0, 1]).required('Unlimited is required'),
  length: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('Length is required'),
    otherwise: yup.number(),
  }),

  price: yup.number().required('Price is required'),
  product_id: yup.string().required('Product id is required'),
  // quantity: yup.number().required('Quantity is required'),
  weight: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('Weight is required'),
    otherwise: yup.number(),
  }),
  quantity: yup.number().when('is_unlimited', {
    is: 0,
    then: yup
      .number()
      .required('Quantity is required')
      .test(
        'is-greater-than-zero',
        'Quantity must be greater than zero',
        (value: any) => value > 0
      ),
    otherwise: yup.number(),
  }),
  width: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('Width is required'),
    otherwise: yup.number(),
  }),
})
