import {Stack, Typography} from '@mui/material'
import React from 'react'
import TextErea from '../../customTable/TextErea'

export default function Card({
  title,
  subTitle,
  name,
  setting,
  setSetting,
}: {
  title: string
  subTitle: string
  name: string
  setting: any
  setSetting: any
}) {
  return (
    <Stack
      p='24px'
      borderRadius={'8px'}
      bgcolor={'var(--bs-app-header-base-bg-color)'}
      width={'100%'}
    >
      <Typography
        sx={{
          fontSize: '16.5px',
          color: 'var(--bs-heading-color, inherit)',
          fontWeight: 600,
          lineHeight: '16px',
          textTransform: 'capitalize',
        }}
      >
        {title}
      </Typography>
      <Typography
        m='32px 0 8px 0'
        sx={{
          fontSize: '14px',
          color: 'var(--bs-heading-color, inherit)',
          fontWeight: 400,
          lineHeight: '16px',
          textTransform: 'capitalize',
        }}
      >
        {subTitle}
      </Typography>
      <TextErea value={setting} name={name} change={setSetting} />
      
    </Stack>
  )
}
