import {CircularProgress, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import HeadTable from './HeadTable'
import Row from './RowTable'
import AddRow from './AddRowOption'
import Product from '../../../../../Api/product'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import SimpleLoading from '../../../../customTable/SimpleLoading'

export default function Table({
  type,
  setOptionValue,
  optionValue,
  option_id,
  stateImage,
  values,
}: any) {
  const [productOptionValue, setProductOptionValue] = useState(values.product_option_values || 0)
  const {id} = useParams()

  useEffect(() => {
    if (values.product_option_values) {
      setProductOptionValue(values.product_option_values.length)
    }
  }, [values.product_option_values])
  const [isSelected, setIsSelected] = React.useState<number[]>([-2])
  //
  const {t} = useTranslation()
  const [isLoadingSelected, setIsLoadingSelected] = useState(false)
  const getSelectedOptions = () => {
    if (id && values.id) {
      Product.getSelectedOptions(
        values.id,
        id,
        (res: any) => {
          setIsSelected([...isSelected, ...res.data])
        },
        setIsLoadingSelected
      )
    }
  }
  //   const [open, setOpen] = React.useState(false)

  // useEffect(() => {

  //   getSelectedOptions()
  // }, [])
  const [options, setOptions] = React.useState([])
  const [isLoadingOptionValue, setIsLoadingOptionValue] = useState(false)
  const getOptionsValue = () => {
    Product.getOptionValue(
      option_id,
      (res: any) => {
        setOptions(res.data)
      },
      setIsLoadingOptionValue
    )
  }
  const [isGetOption, setIsGetOption] = useState(false)
  const [loading, setLoading] = useState(false)
  React.useEffect(() => {
    if (isGetOption) {
      getOptionsValue()
    }
  }, [isGetOption])

  return (
    <Stack
      width={'100%'}
      sx={{
        background: 'var(--bs-body-bg)',
        border: '1px solid var(--bs-gray-300)',
        borderRadius: '8px',
      }}
    >
      <HeadTable />
      <>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {isLoadingOptionValue ? (
              <SimpleLoading />
            ) : (
              values.product_option_values &&
              values.product_option_values.map((e: any, i: number) => (
                <Row
                  setOptionValue={setOptionValue}
                  type={type}
                  values={values}
                  option_id={option_id}
                  optionValue={optionValue}
                  item={e}
                  key={i}
                  count={productOptionValue}
                  setCount={setProductOptionValue}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  setLoading={setLoading}
                  setIsGetOption={setIsGetOption}
                  options={options}
                  getSelectedOptions={getSelectedOptions}
                />
              ))
            )}
            {optionValue &&
              optionValue.map((e: any, i: number) => (
                <Row
                  setOptionValue={setOptionValue}
                  type={type}
                  values={values}
                  option_id={option_id}
                  optionValue={optionValue}
                  item={e}
                  key={i}
                  count={0}
                  setCount={setProductOptionValue}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  options={options}
                  setIsGetOption={setIsGetOption}
                  setLoading={setLoading}
                  getSelectedOptions={getSelectedOptions}
                />
              ))}
          </>
        )}
      </>

      <AddRow
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        setOptionValue={setOptionValue}
        option_id={option_id}
        optionValue={optionValue}
        type={type}
        values={values}
        count={0}
        options={options}
        setIsGetOption={setIsGetOption}
        getSelectedOptions={getSelectedOptions}
      />
    </Stack>
  )
}
