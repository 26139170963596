import React, {useState, useRef, useEffect} from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {addDays, format} from 'date-fns'
import {DateRange, DateRangePicker} from 'react-date-range'
import HomeApi from '../../../Api/home'
import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import DateIcon from '../../../svg/Date'

function MyComponent({setCards, setLoading}: any) {
  // تعيين تاريخ قبل 5 أيام

  // تعيين نطاق التاريخ المراد اختياره

  const [showPicker, setShowPicker] = useState(false)
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const getAnalysis = async (date: any) => {
    try {
      let dataDate = date
      let startDate = new Date(date.start)
      let endDate = new Date(date.end)

      if (startDate.getTime() === endDate.getTime()) {
        startDate.setDate(startDate.getDate() - 1)

        dataDate = {
          ...date,
          start: startDate.toISOString().split('T')[0],
        }
      }
      HomeApi.getAnalysis(
        dataDate,
        (res: any) => {
          setCards(res.data)
        },
        setLoading
      )
    } catch (err: any) {
      setLoading(false)
    }
  }
  const store = useSelector((state: RootState) => state.vender)

  const pickerRef = useRef<any>(null)

  const handleDateSelect = (ranges: any) => {
    var currentDate = new Date()

    var daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    // أسماء الشهور بالأحرف الاختصارية
    var monthsOfYear = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    // الحصول على أيام الأسبوع وأسماء الشهور من الكائن Date
    var dayOfWeek = daysOfWeek[currentDate.getDay()]
    var month = monthsOfYear[currentDate.getMonth()]
    var day = currentDate.getDate()
    var year = currentDate.getFullYear()

    // تنسيق التاريخ بالصيغة المطلوبة
    var formattedDate = dayOfWeek + ' ' + month + ' ' + day + ' ' + year
    let startDay = `${ranges.selection.startDate}`.split(' ').slice(0, 4).join(' ')
    let endDay = `${ranges.selection.endDate}`.split(' ').slice(0, 4).join(' ')

    setSelectedRange(ranges.selection)
  }

  const togglePicker = () => {
    setShowPicker(!showPicker)
  }
  useEffect(() => {
    getAnalysis({
      start: format(selectedRange.startDate, 'y-MM-dd'),
      end: format(selectedRange.endDate, 'y-MM-dd'),
    })
  }, [])
  const [isDay, setIsDay] = useState<any>(null)
  const handlePrintRange = async () => {
    if (isDay) {
      getAnalysis({
        type: isDay,
      })
      setIsDay(null)
    } else {
      getAnalysis({
        start: format(selectedRange.startDate, 'y-MM-dd'),
        end: format(selectedRange.endDate, 'y-MM-dd'),
      })
    }

    setShowPicker(false)
  }

  const handleOutsideClick = (e: any) => {
    if (pickerRef.current && !pickerRef.current.contains(e.target)) {
      setShowPicker(false)
      setSelectedRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      })
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const {t} = useTranslation()

  return (
    <div style={{position: 'relative', background: 'var(--bs-app-bg-color)'}}>
      <button
        style={{
          display: 'flex',
          gap: '5px',
          border: 'none',
          alignItems: 'center',
          height: '46px',
          justifyContent: 'center',
          width: '120px',
          borderRadius: '8px',
          background: 'var(--main-color)',
          color: 'var(--w-color)',
          zIndex: 1,
        }}
        onClick={togglePicker}
      >
        <Typography>{format(selectedRange.startDate, 'y-MM-dd')}</Typography>
        <DateIcon />{' '}
      </button>
      <Stack
        style={{
          position: 'absolute',
          right: store.isRtl === 0 ? '0' : 'auto',
          left: store.isRtl === 1 ? '0' : 'auto',
          zIndex: '100',
          background: 'var(--bs-app-footer-bg-color)',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
        dir='ltr'
      >
        {' '}
        {showPicker && (
          <div ref={pickerRef}>
            <Stack
              sx={{display: {md: 'block', xs: 'none'}, background: 'var(--bs-app-footer-bg-color)'}}
            >
              <DateRangePicker
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[selectedRange]}
                direction='horizontal'
                preventSnapRefocus={true}
                calendarFocus='backwards'
              />
            </Stack>
            <Stack sx={{display: {md: 'none', xs: 'block'}}}>
              <DateRange
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[selectedRange]}
                // direction='horizontal'

                // preventSnapRefocus={true}
                calendarFocus='backwards'
              />
            </Stack>

            <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'flex-start'}>
              <button
                style={{
                  display: 'flex',
                  gap: '5px',
                  border: 'none',
                  alignItems: 'center',
                  height: '46px',
                  justifyContent: 'center',
                  width: '120px',
                  borderRadius: '8px',
                  margin: '20px 0',
                  // background: '#3d91ff',
                  // color: 'white',
                }}
                onClick={() => {
                  setSelectedRange({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                  })
                  setShowPicker(false)
                }}
              >
                {t('cancel')}
              </button>
              <button
                style={{
                  display: 'flex',
                  gap: '5px',
                  border: 'none',
                  alignItems: 'center',
                  height: '46px',
                  justifyContent: 'center',
                  width: '120px',
                  borderRadius: '8px',
                  margin: '20px',
                  background: '#3d91ff',
                  color: 'white',
                }}
                onClick={handlePrintRange}
              >
                {t('apply')}
              </button>
            </Stack>
          </div>
        )}
      </Stack>
    </div>
  )
}

export default MyComponent
