import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../../index'
import {FetchStatus} from '../../types'
import {
     addManufacturerAction,
  changeManufacturerStatusAction,
  delateManufacturerAction,
  manufacturerAction,
  manufacturerNameAction,
  updateManufacturerAction,
} from './thunk'

import {IinitialState} from './types'

const initialState: IinitialState = {
  manufacturers: {
    data: [],
    pages: null,
  },
  option: {},
  manufacturerName: [],
  status: FetchStatus.idle,
  error: null,
}

const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(manufacturerAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.manufacturers = action.payload
      })
      // .addCase(manufacturerAction.rejected, (state, action) => {
      //   state.status = FetchStatus.error
      //   state.error = action.payload as string
      // })
      // .addCase(manufacturerAction.pending, (state, _) => {
      //   state.status = FetchStatus.pending
      // })
      .addCase(changeManufacturerStatusAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
      })
      //    update  the  option catolog
      .addCase(updateManufacturerAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.manufacturers.data = action.payload
      })
    //   addOptionAction
    builder.addCase(addManufacturerAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })
    builder.addCase(addManufacturerAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    builder.addCase(addManufacturerAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.manufacturers.data.push(action.payload)
    })
    //   dealteOptionAction

    builder.addCase(delateManufacturerAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed

      state.manufacturers.data = action.payload.data
    })

    builder.addCase(delateManufacturerAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(delateManufacturerAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })

    // manufacturerNameAction 
    builder.addCase(manufacturerNameAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.manufacturerName = action.payload
    })
  }
})

export default manufacturerSlice.reducer

export const selectmanufacturers = (state: RootState) => state.manufacturer
