import {Button, Stack, Tooltip, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import LabelInput from '../../customTable/LabelInput'

export default function UploadImage({
  base64Image,
  setBase64Image,
  text,
  setting,
  setSeeting,
  name,
}: any) {
  const [image, setImage] = useState(base64Image || null)

  useEffect(() => {
    if (base64Image) {
      setImage(base64Image)
    }
  }, [base64Image])
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    setSeeting({...setting, [`${name}`]: event.target.files[0]})
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setBase64Image(reader.result)
      setImage(reader.result as string)
    }
    reader.onerror = (error) => {}
  }

  return (
    <>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack width={'100%'} height={'100%'}>
          <Stack component='label'>
            {image ? (
              <Stack
                width={{xs: '100%', sm: '100%'}}
                height={{xs: '100%'}}
                position={'relative'}
                className='upload-box-img'
              >
                <img
                  style={{width: '100%', height: '100%', borderRadius: '7px'}}
                  src={image}
                  alt=''
                />
                <Stack
                  display={'none'}
                  className='upload-box-img-hover'
                  width={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'100%'}
                  position={'absolute'}
                >
                  <Stack
                    borderRadius={'8px'}
                    alignItems={'center'}
                    padding='22px'
                    border={'1px solid #D8D8D8'}
                    width={'156px'}
                  >
                    <img
                      src={'/media/avatars/icons/uploadred.svg'}
                      alt='SVG Image'
                      style={{width: '32px'}}
                    />
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack p={'24px'} gap={'12px'}>
                <LabelInput text={text} />
                <Stack
                  borderRadius={'8px'}
                  alignItems={'center'}
                  padding='22px'
                  border={'1px solid #D8D8D8'}
                  width={'156px'}
                >
                  <img
                    src={'/media/avatars/icons/uploadred.svg'}
                    alt='SVG Image'
                    style={{width: '32px'}}
                  />
                </Stack>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#777777',
                    fontWeight: 300,
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                  }}
                >
                  Allowed JPG, GIF or PNG. max_size_of 800kB
                </Typography>
              </Stack>
            )}
            <input
              hidden
              accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
            />
          </Stack>
        </Stack>
      </Tooltip>
    </>
  )
}
