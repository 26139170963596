import {CircularProgress} from '@mui/material'
import React from 'react'

export default function SimpleLoading() {
  return (
    <div>
      <CircularProgress size={30} />
    </div>
  )
}
