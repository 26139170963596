import {Checkbox, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import InputWithLabel from '../../customTable/LabelWithText'
import UploadImg from '../../UploadImg'
import CheckInputWithLabel from '../../customTable/CheckInputWithLabel'
import UploadImgBtn from '../../customTable/UploadImgBtn'
import StaticInputFile from '../../customTable/StaticInputFile'
import EmptyInput from '../../customTable/EmptyInput'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'

export default function IHaveAccountsInStoresForm({setIsSubmit, android}: any) {
  const [data, setData] = useState<any>({check: 1})
  const [base64Image, setBase64Image] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  return (
    <Stack gap='24px' p='25px' borderRadius={'8px'} bgcolor={'#fff'} mt='24px'>
      <Label text='haveAccountTitle' />
      <Label fs='12px' fw='400' text='haveAccountSubTitle' />
      <Stack direction={'row'} gap='5px' flexWrap={'wrap'}>
        <Label fs='12px' fw='400' text='checkLink' />
        <Label fs='12px' fw='400' color='#009EF7' text='followingLink' />
      </Stack>
      <Stack gap='40px'>
        <img src='./media/1.svg' alt='' />
        <Label fs='14px' text='itIsNotAcceptableIfTheAccountIsIndividual' />

        <img src='./media/2.svg' alt='' />
        <InputWithLabel
          width={{md: '521px', xs: '100%'}}
          name={'test'}
          data={data}
          setData={setData}
          text='D-U-N-S number'
        />
        <Stack bgcolor={'#C5C5D8'} height={'1px'} width={'100%'}></Stack>
        <Stack gap='32px'>
          <Label text={android ? 'androidApp' : 'isoApp'} />
          <Stack gap='15px'>
            <Label text='applicationIcon' />
            <UploadImg
              name='image'
              data={data}
              setData={setData}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
            />
          </Stack>
          <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
            <InputWithLabel
              width={{md: 'calc(50% - 16px)', xs: '100%'}}
              name={'test'}
              data={data}
              setData={setData}
              text='appleIDAccount'
            />{' '}
            <InputWithLabel
              width={{md: 'calc(50% - 16px)', xs: '100%'}}
              name={'test'}
              data={data}
              setData={setData}
              text='password'
            />
          </Stack>
          <Stack bgcolor={'#C5C5D8'} height={'1px'} width={'100%'}></Stack>
        </Stack>
        <Stack width='100%' gap='24px' direction={'row'}>
          <CheckInputWithLabel
            onClick={() => setData({...data, check: 1})}
            check={data.check}
            value={1}
            text={'citizen'}
          />
          <CheckInputWithLabel
            onClick={() => setData({...data, check: 0})}
            check={data.check}
            value={0}
            text={'resident'}
          />
        </Stack>
        {data.check === 1 ? (
          <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
            <Stack position={'relative'} width={{xs: '100%', md: 'calc(50% - 16px)'}}>
              {data.frontImage ? (
                <StaticInputFile text='frontImage' />
              ) : (
                <EmptyInput text='frontImage' />
              )}
              <Stack
                sx={{
                  left: store.isRtl !== 0 ? '24px' : 'auto',
                  right: store.isRtl === 0 ? '24px' : 'auto',
                  bottom: '-43px',
                }}
                position={'absolute'}
              >
                {' '}
                <UploadImgBtn
                  base64Image={base64Image}
                  setBase64Image={setBase64Image}
                  name='frontImage'
                  data={data}
                  setData={setData}
                />
              </Stack>
            </Stack>
            <Stack position={'relative'} width={{xs: '100%', md: 'calc(50% - 16px)'}}>
              {data.backImage ? (
                <StaticInputFile text='backImage' />
              ) : (
                <EmptyInput text='backImage' />
              )}
              <Stack
                sx={{
                  left: store.isRtl !== 0 ? '24px' : 'auto',
                  right: store.isRtl === 0 ? '24px' : 'auto',
                  bottom: '-43px',
                }}
                position={'absolute'}
              >
                {' '}
                <UploadImgBtn
                  base64Image={base64Image}
                  setBase64Image={setBase64Image}
                  name='backImage'
                  data={data}
                  setData={setData}
                />
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <>
            {' '}
            <Stack position={'relative'} width={{xs: '100%', md: 'calc(50% - 12px)'}}>
              {data.backImage ? (
                <StaticInputFile text='pasportImg' />
              ) : (
                <EmptyInput text='pasportImg' />
              )}
              <Stack
                sx={{
                  left: store.isRtl !== 0 ? '24px' : 'auto',
                  right: store.isRtl === 0 ? '24px' : 'auto',
                  bottom: '-43px',
                }}
                position={'absolute'}
              >
                {' '}
                <UploadImgBtn
                  base64Image={base64Image}
                  setBase64Image={setBase64Image}
                  name='pasportImg'
                  data={data}
                  setData={setData}
                />
              </Stack>
            </Stack>
          </>
        )}
        <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}}>
          <StaticInputFile text='commercialRegister' />
        </Stack>
      </Stack>
      <Stack>
        <Stack alignContent={'center'} gap='3px' direction={'row'}>
          <Checkbox
            defaultChecked
            sx={{
              color: 'var(--main-color)',
              '&.Mui-checked': {
                color: 'var(--main-color)',
              },
            }}
          />
          <Stack mt='8px' alignContent={'center'} gap='5px' direction={'row'}>
            <Label fs='14px' text='iAgreeTo' />
            <Label fs='14px' color='#009EF7' text='theAppStorePublishingPolicy' />
          </Stack>
        </Stack>
        <Stack mt='41px' alignItems={'flex-end'}>
          <MainBtn
            width='auto'
            text='submit'
            handelSubmit={() => {
              setIsSubmit(true)
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
