import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import CheckIcon from '@mui/icons-material/Check'
export default function CheckBox() {
  const checkBox = [
    {name: 'Enable', value: 1},
    {name: 'Disable', value: 0},
  ]
  const [isCheck, setIsCheck] = useState(0)
  return (
    <Stack alignItems={'center'} gap={'24px'} direction={'row'}>
      <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
        <LabelInput text={'US DOLLAR'} />
      </label>
      <Stack gap={'24px'} direction={'row'}>
        {checkBox.map((e, i) => (
          <Stack
            onClick={() => setIsCheck(e.value)}
            alignItems={'center'}
            gap={'8px'}
            direction={'row'}
          >
            <Stack
              width={'24px'}
              borderRadius={'6px'}
              height={'24px'}
              bgcolor={'var(--bs-app-bg-color)'}
            >
              {isCheck === e.value && (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'100%'}
                  borderRadius={'6px'}
                  bgcolor={'var(--main-color)'}
                >
                  <CheckIcon sx={{color: 'white'}} />
                </Stack>
              )}
            </Stack>
            <Typography sx={{fontSize: '14px'}}>{e.name}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
