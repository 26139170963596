/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from '../../../partials/layout/header-menus/Languages'
import {toAbsoluteUrl} from '../../../helpers'
import JwtService from '../../../../app/utils/TokenServices'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../app/store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import {Avatar, Box, Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/store'
import Vendor from '../../../../app/Api/vendor'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handelLogout = async () => {
    await Vendor.logout(() => {
      JwtService.destroyToken()
      localStorage.clear()
      dispatch(actions.login(null))
    }, setIsLoading)
  }
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(
      actions.getUserInfo({
        name: localStorage.getItem('name'),
        image: localStorage.getItem('image'),
      })
    )
  }, [])
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Box
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <Avatar
              sx={{borderRadius: '8px'}}
              alt='Logo'
              src={store.userInfo?.image || '/media/avatars/300-1.jpg'}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {store.userInfo?.name}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          {t('myProfile')}
        </Link>
      </div>
      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>{t("myProfile")}</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}
      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}
      <div className='separator my-2'></div>
      <Stack sx={{display: {md: 'none', xs: 'block'}}}>
        <Languages />
      </Stack>
      <div className='menu-item px-5 my-1'>
        <Link to='/settings' className='menu-link px-5'>
          {t('storeSettings')}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={handelLogout} className='menu-link px-5'>
          {t('signOut')}
        </a>
      </div>
    </Box>
  )
}

export {HeaderUserMenu}
