import React from 'react'
import IUpLoadImg from '../interface/IUpdloadImg'
import {Stack, Tooltip} from '@mui/material'
import ImgMsg from '../modules/customTable/ImgMsg'
import ErrorMsg from '../modules/customTable/NewMsgErr'
function UploadImg({
  img,
  isAllowToEdit,
  rowSlectedToEdit,
  index,
  option,
  formik,
  isActive,
  id,
  errors,
  Adderrors,
  name,
  addName,
}: any) {
  return (
    <div
      style={{display: 'flex', alignItems: 'center', marginTop: '0'}}
      className='col-lg-2 mb-3 d-flex justify-content-center align-items-center '
    >
      {isActive && (
        <>
          <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
            <Stack height={'100px'} width={'100px'}>
              <img
                className='w-100 h-100'
                src={typeof img === 'object' ? URL.createObjectURL(img as any) : img}
                // alt={option.option_image[index.toString()] as string}
                alt='user-info'
                style={{maxWidth: '100%', maxHeight: '100%', borderRadius: '8px'}}
              />
            </Stack>
          </Tooltip>
        </>
      )}

      {!isActive && (
        <label
          // style={{position: 'absolute', top: '0'}}
          htmlFor={`option_image_${index}`}
          // className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        >
          <div className='image-input-wrapper w-100px h-100px shadow-sm'>
            {img ? (
              <>
                <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
                  <>
                    <img
                      className='w-100 h-100'
                      src={typeof img === 'object' ? URL.createObjectURL(img as any) : img}
                      // alt={option.option_image[index.toString()] as string}
                      alt='user-info'
                      style={{maxWidth: '100%', maxHeight: '100%', borderRadius: '8px'}}
                    />
                    {(errors && errors[`option_value.${name}`] && (
                      <ErrorMsg msgErr={errors[`option_value.${name}`]} />
                    )) ||
                      (Adderrors && Adderrors[`optionDetails[${index}].${name}`] && (
                        <ErrorMsg msgErr={Adderrors[`optionDetails[${index}].${name}`]} />
                      ))}
                  </>
                </Tooltip>
              </>
            ) : (
              <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
                <>
                  <img src={'/media/avatars/img.svg'} alt='user-info' className='w-100 h-100' />
                  {(errors && errors[`option_value.${name}`] && (
                    <ErrorMsg msgErr={errors[`option_value.${name}`]} />
                  )) ||
                    (Adderrors && Adderrors[`optionDetails[${index}].${name}`] && (
                      <ErrorMsg msgErr={Adderrors[`optionDetails[${index}].${name}`]} />
                    ))}
                </>
              </Tooltip>
            )}
          </div>
          {/* <i className='bi bi-pencil-fill fs-7'></i> */}
          {id ? (
            <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
              <>
                <input
                  id={`option_image_${index}`}
                  type='file'
                  className='w-100 h-100'
                  multiple={false}
                  style={{display: 'none'}}
                  accept='.jpg, .jpeg, .png, .svg'
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0]
                      const reader = new FileReader()
                      reader.onload = () => {
                        formik.setFieldValue(`optionDetails.${index}.option_image`, reader.result)
                      }
                      reader.readAsDataURL(file)
                    }
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched(`options.${index}.option_image`, true)
                  }}
                />{' '}
                {(errors && errors[`${name}`] && <ErrorMsg msgErr={errors[`${name}`]} />) ||
                  (Adderrors && Adderrors[`optionDetails[${index}].${name}`] && (
                    <ErrorMsg msgErr={Adderrors[`optionDetails[${index}].${name}`]} />
                  ))}
              </>
            </Tooltip>
          ) : (
            <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
              <>
                <input
                  id={`option_image_${index}`}
                  type='file'
                  className='w-100 h-100'
                  multiple={false}
                  style={{display: 'none'}}
                  accept='.jpg, .jpeg, .png, .svg'
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0]
                      const reader = new FileReader()

                      reader.onloadend = () => {
                        const base64String = reader.result // This contains the base64 string

                        console.log('Base64 Image:', base64String)

                        formik.setFieldValue(`optionDetails.${index}.option_image`, base64String)
                      }

                      reader.readAsDataURL(file) // Converts the file to base64 string
                    }
                    return
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched(`options.${index}.option_image`, true)
                  }}
                />
              </>
            </Tooltip>
          )}
        </label>
      )}
    </div>
  )
}

export default UploadImg
