import {Box, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'

import Map from '../../sittings/address/GoogleMapReact'

import {useTranslation} from 'react-i18next'

export default function GoogleMap({location}: any) {
  const [center, setCenter] = useState({lat: 0, lng: 0})

  useEffect(() => {
    setCenter(location)
  }, [location])
  const {t} = useTranslation()

  return (
    <Stack>
      <Stack mt={'50px'} sx={{width: '100%', height: '60vh'}}>
        <Map center={location} setCenter={setCenter} />
      </Stack>
    </Stack>
  )
}
