import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import Cards from './Cards'
import TitlePage from '../../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import ButtonPrient from './ActionBtn'
import {useParams} from 'react-router'
import OrderApi from '../../../Api/order'
import AddressDetails from './AddressDetails'
import OrderDetails from './OrderDetails'
import Table from './Table'
import Address from '../../../Api/SettingAddress'
import axios from 'axios'
import JwtService from '../../../utils/TokenServices'
import Loading from '../../customTable/LoadingForm'
import SimpleLoading from '../../customTable/SimpleLoading'
import Row from './Row'
import TableHead from './TablHeadInvo'
import Label from '../../customTable/Label'
import ServiseFees from '../../../Api/serviseFees'
import {PaginationSection} from '../../customTable/pagnationSection'

export default function Invoice({id, editItem}: any) {
  const {t} = useTranslation()
  const invoiceData = {
    invoiceNumber: '123',
    customerName: 'John Doe',
  }
  const bannerInfo = {
    title: t('orderDetails'),
    home: t('home'),
    linkHome: '/',
  }
  const [order, setOrder] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(1)
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  const getOrder = (rowsPerPage: any, page: any) => {
    ServiseFees.getOrders(
      id,
      (res: any) => {
        setOrder(res.data)
        setTotal(res.pages.total)
      },
      setIsLoading,
      rowsPerPage,
      page
    )
  }

  const [dataStore, setDataStore] = useState({})
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const getAddress = async () => {
    const data = await Address.get((res: any) => {
      setDataStore(res.data)
    }, setIsLoadingAddress)
  }
  useEffect(() => {
    getOrder(rowsPerPage, page)
  }, [rowsPerPage, page])
  // useEffect(() => {
  //   getOrder()
  //   getAddress()
  // }, [])
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)

  const getPdfInvo = async () => {
    OrderApi.getOrderPdf(id, () => {}, setIsLoadingPdf)
  }

  return (
    <Stack position={'relative'} minHeight={'80vh'} width={'100%'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack p='24px'>
          <Stack gap='24px' mb='34px' justifyContent={'flex-end'} direction={'row'} width={'100%'}>
            {isLoadingPdf ? (
              <SimpleLoading />
            ) : (
              <button
                style={{
                  border: 'none',
                  background: '#009EF7',
                  color: 'white',
                  borderRadius: '5.5px',
                  padding: '7px 17px',
                }}
                onClick={getPdfInvo}
              >
                {t('downLoad')}
              </button>
            )}
          </Stack>
          <Stack gap='16px' p='20px' borderRadius={'12px'} bgcolor={'#F2F5F9'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Label fs='20px' fw='700' text='invoice' />
              <Stack justifyContent={'flex-end'}>
                <Label color='#60737D' fs='10px' fw='400' text='invoicesID' />
                <Label fs='13px' fw='700' text={editItem.code} />
              </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack>
                <Label color='#60737D' fs='10px' fw='400' text='date' />
                <Label fs='10px' fw='400' text={editItem.created_at} />
              </Stack>{' '}
              <Stack>
                <Label color='#60737D' fs='10px' fw='400' text='dueDate' />
                <Label fs='10px' fw='400' text={editItem.due_date} />
              </Stack>
            </Stack>
          </Stack>
          <div className='table-responsive overflow-auto  '>
            <Stack m='24px 0'>
              <Label fs='13px' fw='700' text='orders' />
            </Stack>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <TableHead />
              {order && order.map((e: any, i: any) => <Row key={i} item={e} />)}
            </table>
            <PaginationSection
              rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
              count={total || 1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Stack>
      )}
    </Stack>
  )
}
