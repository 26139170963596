import {Stack} from '@mui/material'
import React, {useState} from 'react'
import MainBtn from '../../customTable/MainBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import InputWithLabel from '../../customTable/LabelWithText'
import StaticInputFile from '../../customTable/StaticInputFile'
import Label from '../../customTable/Label'
import Status from '../../customTable/Status'
import UploadImg from '../../UploadImg'
import DeleteIcon from '../../../svg/DeleteIcon'
import DeletedMsg from '../../customTable/DeleteMsg'
import Delete from '../../../svg/Delete'

export default function IHaveAftterSubmit({IDontHave, android}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const [data, setData] = useState({})
  const [base64Image, setBase64Image] = useState('')
  const [status, setStatus] = useState('accept')
  const [open, setOpen] = useState(false)
  return (
    <Stack position={'relative'}>
      <Stack
        sx={{
          left: store.isRtl !== 0 ? '0px' : 'auto',
          right: store.isRtl === 0 ? '0px' : 'auto',
          top: '-72px',
        }}
        position={'absolute'}
      >
        <MainBtn width='auto' text='massage' />
      </Stack>
      <Stack gap='24px' p='25px' borderRadius={'8px'} bgcolor={'#fff'} mt='0'>
        <InputWithLabel
          width={{md: 'calc(50% - 16px)', xs: '100%'}}
          name={'test'}
          data={data}
          setData={setData}
          text={!IDontHave ? 'D-U-N-S number' : 'workMail'}
        />{' '}
        {IDontHave && (
          <Stack direction={'row'} gap='32px'>
            <Stack width={{md: 'calc(50% - 16px)', xs: '100%'}}>
              <StaticInputFile text='commercialRegister' />
            </Stack>
            <Stack width={{md: 'calc(50% - 16px)', xs: '100%'}}>
              <StaticInputFile text='passport' />
            </Stack>
          </Stack>
        )}
        <Stack width={'100%'} height={'1px'} bgcolor={'#C5C5D8'}></Stack>
        {status === 'pendingReq' && (
          <Stack
            p='17px 24px'
            bgcolor='rgba(216, 25, 35, 0.10)'
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            borderRadius={'8px'}
            border='1px solid var(--2, #D8D8D8)'
          >
            <Label fs='16px' fw='600' text='cancelReqMsg' />
            <MainBtn
              handelSubmit={() => setStatus('accept')}
              without
              width='auto'
              text='cancelReq'
            />
          </Stack>
        )}
        <Stack alignItems={'center'} direction={'row'} gap='25px'>
          <Label text={android ? 'androidApp' : 'isoApp'} />
          {status === 'accept' && (
            <Stack
              onClick={() => {
                setOpen(true)
              }}
              sx={{cursor: 'pointer'}}
            >
              <Delete />
            </Stack>
          )}
        </Stack>
        <Stack alignItems={'center'} direction='row' gap='5px'>
          {status === 'pending' && (
            <>
              <Label text='applicationSubmissionStatus' /> : <Status text={status} />
            </>
          )}
        </Stack>
        <Stack gap='15px'>
          <Label text='applicationIcon' />
          <UploadImg
            name='image'
            data={data}
            setData={setData}
            base64Image={base64Image}
            setBase64Image={setBase64Image}
          />
        </Stack>
        <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
          <InputWithLabel
            width={{md: 'calc(50% - 16px)', xs: '100%'}}
            name={'test'}
            data={data}
            setData={setData}
            text='appleEnterpriseAccount'
          />{' '}
          <InputWithLabel
            width={{md: 'calc(50% - 16px)', xs: '100%'}}
            name={'test'}
            data={data}
            setData={setData}
            text='password'
          />
        </Stack>
      </Stack>
      <DeletedMsg
        deleteMsg={'deleteAppMsg'}
        onClick={() => {
          setStatus('pendingReq')
        }}
        open={open}
        setOpen={setOpen}
      />
    </Stack>
  )
}
