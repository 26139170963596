import {useFormik} from 'formik'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import * as yup from 'yup'
import {customToastr} from '../../../componet/Toastr'
import {RootState, useDispatch, withToastr} from '../../../store'
import {addOptionDetails} from '../../../store/vender/option/slice'
import {
  addOptionAction,
  createItemOptionAction,
  getCategoryNames,
  getOptionById,
  removeItemOptionAction,
  updateItemOptionAction,
  updateOptionOne,
} from '../../../store/vender/option/thunk'
import {Language, languages, optionTypes, TInputType} from '../../../types'
import {RadioInput} from './from/Custom'
import {LanguageRowInput} from './from/languageRow'
import {ItemOptionColor, ItemOptionImage, ItemOptionText, OptionDetails} from './optionsDetails'
import {OptionColorDetails} from './optionsDetailsColors'
import {OptionTextDetails} from './optionsDetailsText'
import {Boundary} from '../../../componet/Bondary'
import {FetchStatus} from '../../../store/types'
import Options from '../../../Api/options'
import {Stack} from '@mui/material'
import ErrorMsg from '../../customTable/ErrorMsg'
import SetLang from '../../../utils/SetLangs'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {OptionSchema} from '../../../yup/Option'
import * as Yup from 'yup'
import MainBtn from '../../customTable/MainBtn'
import SimpleLoading from '../../customTable/SimpleLoading'
import ValidationFun from '../../../yup/ValidationFun'
import OldSelect from '../../customTable/OldSelect'

const animatedComponents = makeAnimated()

type Props = {
  isEdit?: boolean
}

export interface InitialValues {
  name: {
    [key in Language]: string
  }
  option_catalog_id: {
    value: string
    label: string
  }
  optionDetails: Array<any>
  input_type: 'single_select' | 'multi_select' | 'date' | 'text' | 'file'
  value_type: 'image' | 'color' | 'text' | 'tex' | 'textt'
}

export const ValuesSchema = () => {
  let languages = SetLang()
  return yup.object().shape({
    option_catalog_id: yup
      .object()
      .shape({
        value: yup.number().required('Category is required.'),
        label: yup.string().required('Category is required.'),
      })
      .required('Category is required.'),

    input_type: yup
      .string()
      .oneOf(['single_select', 'multi_select', 'date', 'text', 'file'])
      .required(
        'Input should be one of "single_select", "multi_select", "date", "text", or "file".'
      ),
    value_type: yup
      .string()
      .oneOf(['image', 'color', 'text', 'textt'])
      .required('Input should be one of "image", "color", or "text".'),
    optionDetails: yup
      .array()
      .nullable()
      .when('value_type', {
        is: 'image',
        then: yup
          .array()
          .of(
            yup.object().shape({
              option_image: yup.string().required('English option image is required.'),
              sort: yup.number().required('sort order is required.'),
              option_value: yup.object().shape(
                languages.reduce((acc: any, lang: any) => {
                  return {
                    ...acc,
                    [lang]: yup.string().required(`${lang} option value is required.`),
                  }
                }, {})
              ),
            })
          )
          .required('Option details are required for image input type.'),
        otherwise: yup
          .array()
          .of(
            yup.object().shape({
              color: yup.string().optional(),
              sort: yup.number().required('sort order is required.'),
              option_value: yup.object().shape(
                languages.reduce((acc: any, lang: any) => {
                  return {
                    ...acc,
                    [lang]: yup.string().required(`${lang} option value is required.`),
                  }
                }, {})
              ),
            })
          )
          .required('Option details are required for color or text input type.'),
      }),
    name: yup.object().shape(
      languages.reduce(
        (schema: any, lang: any) => ({
          ...schema,
          [lang]: yup.string().required(`name in ${lang} is required`),
        }),
        {}
      )
    ),
  })
}

export const AddOption = ({isEdit}: Props) => {
  const [activeLang, setActiveLang] = useState<Language>('en')
  const {optionDetails, catogory, error} = useSelector((state: RootState) => state.option)

  const location = useLocation()
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  useEffect(() => {
    setActiveLang(store.defLang)
  }, [store.defLang])
  const isEditRoute = location.pathname.includes('edit-option')

  const dispatch = useDispatch()
  //   base  of  route  if  there  are  id
  const {id} = useParams<{
    id?: string
  }>()

  //   will  be  fetch  data  base  if there  id  or  not
  useEffect(() => {
    if (id) {
      dispatch(getOptionById({id}))
    }
    //  return
  }, [id, dispatch])
  const [optionsName, setOptionsName] = useState([])
  const [isLoadingOptionName, setIsLoadingOptionName] = useState(false)
  const getOptionName = () => {
    Options.getOptionsName((res: any) => {
      setOptionsName(
        res.data.map((item: any) => ({
          value: item?.id?.toString(),
          label: item.name && (item.name[store.lang] || item.name[store.defLang]),
        }))
      )
    }, setIsLoadingOptionName)
  }
  useEffect(() => {
    getOptionName()
  }, [store.lang])
  const handleRemoveOption = async (itemId: string | number): Promise<void> => {
    if (!id || !itemId) return
    dispatch(
      withToastr(
        removeItemOptionAction({
          id: itemId,
          option_id: id,
        }),
        {
          success: {
            title: 'Success to fetch data',
          },
        }
      )
    )
      .then((res) => {})
      .catch((err) => {})
  }

  const handleEditOption = async (id: string | number, data: Object): Promise<void> => {
    dispatch(
      withToastr(updateItemOptionAction({data}), {
        success: {
          title: 'Success to  update  data',
        },
      })
    )
      .then((res) => {})
      .catch((err) => {})
  }

  const handelSaveOption = async (data: {[key: string]: any}): Promise<void> => {
    if (id) {
      data.option_id = id

      dispatch(
        withToastr(
          createItemOptionAction({
            data,
          }),
          {
            success: {
              title: 'Success to  update  data',
            },
          }
        )
      )
        .then((res) => {})
        .catch((err) => {})
    }
  }
  //   fetch  data
  const fetchCategoryNames = useCallback(async () => {
    // const data = await dispatch(getCategoryNames({}))
  }, [dispatch])

  //    useMemo  for  reformat
  const reformated = useMemo(() => {
    const result = catogory?.map((item: any) => ({
      value: item?.id?.toString(),
      label: item.name && (item.name[store.lang] || item.name[store.defLang]),
    })) as Array<{value: string; label: string}>
    console.log(catogory, 'catogory', optionDetails)

    return result || []
  }, [catogory, activeLang])

  // useeFFECT
  useEffect(() => {
    fetchCategoryNames()
  }, [fetchCategoryNames])
  const defaultOptionCatalog = {
    value: '',
    label: '',
  }
  const initialOptionCatalog =
    id && optionDetails.option_catalog?.id
      ? {
          value: id && optionDetails.option_catalog.id,
          label:
            reformated.find((x) => x.value === id && optionDetails.option_catalog.id)?.label || '',
        }
      : defaultOptionCatalog

  const initialValues = useMemo(() => {
    let obj = {
      store_id: (id && optionDetails['store_id']) || 0,
      name: (id && optionDetails.name) || ({} as {[key in Language]: string}),
      option_catalog_id: initialOptionCatalog,
      input_type: (id && optionDetails.input_type) || 'single_select',
      value_type: (id && optionDetails.value_type) || 'tex',
      optionDetails: (id && optionDetails.option_details) || [],
    } as InitialValues
    if (!optionDetails['name']) {
      for (const lang of store.storeLangs) {
        obj.name[lang] = ''
      }
    }
    return obj as InitialValues
  }, [id, optionDetails, reformated])

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [languagess, setlanguagess] = useState(['en'])
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    setlanguagess(store.storeLangs)
  }, [store.storeLangs])
  const formik = useFormik<InitialValues>({
    initialValues: initialValues,
    // validationSchema: ValuesSchema(),
    //   REST  FORM

    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        if (id) {
          if (
            (values.input_type === 'single_select' && values.optionDetails.length === 0) ||
            (values.input_type === 'multi_select' && values.optionDetails.length === 0)
          ) {
            setMsgErr(`You must add a options`)
          } else {
            ValidationFun(
              {
                option_catalog_id: values.option_catalog_id,
                name: values.name,
                input_type: values.input_type,
                optionDetails: values.optionDetails || [],
              },
              OptionSchema(languagess),
              setErrors,
              (value: any) => {
                Options.update(
                  id,
                  {
                    option_catalog_id: values.option_catalog_id.value,
                    name: values.name,
                    input_type: values.input_type,
                  },
                  () => {
                    // navigate('/catalog/options')
                  },
                  setIsLoading
                )
              }
            )
          }
        } else {
          if (
            (values.input_type === 'single_select' && values.optionDetails.length === 0) ||
            (values.input_type === 'multi_select' && values.optionDetails.length === 0)
          ) {
            setMsgErr(`You must add a options`)
          } else {
            ValidationFun(
              {
                ...values,
                value_type: values.value_type === 'textt' ? 'text' : values.value_type,
              },
              OptionSchema(languagess),
              setErrors,
              (value: any) => {
                Options.add(
                  {
                    ...values,
                    value_type: values.value_type === 'textt' ? 'text' : values.value_type,
                    option_catalog_id: values.option_catalog_id && values.option_catalog_id.value,
                  },
                  () => {
                    navigate('/catalog/options')
                  },
                  setIsLoading
                )
              }
            )
          }
        }
      } catch (err) {}
    },
  })

  const isValidLastItemInOptionDetails = useMemo(() => {
    const lastItem = formik.values.optionDetails[formik.values.optionDetails.length - 1]
    // If it is the first item we need to return true
    const hasFormikErrors =
      formik.errors.option_catalog_id || formik.errors.input_type || formik.errors.value_type
    if (!lastItem) {
      return true
    }

    // Check if the last item exists and is valid according to Formik validation
    const isLastItemValid =
      lastItem && formik.errors.optionDetails?.[formik.values.optionDetails.length - 1] == null

    // Check if the last item has a value for the active language and a positive sort value
    let isLastItemComplete = false
    let allFieldsFilled = true
    for (const key in lastItem) {
      if (
        // key !== 'option_code' &&
        // key !== 'option_image' &&
        key !== 'color' &&
        (lastItem[key] === undefined || lastItem[key] === null || lastItem[key] === '')
      ) {
        allFieldsFilled = false
        break
      }
    }

    // Specifically check the option_value object
    if (lastItem.option_value) {
      for (const lang in lastItem.option_value) {
        if (!lastItem.option_value[lang]) {
          allFieldsFilled = false
          break
        }
      }
    }

    if (allFieldsFilled) {
      isLastItemComplete = true
    }

    console.log(isLastItemComplete, lastItem, 'isLastItemComplete')

    // The button should be disabled if the last item is valid and complete, or if there are errors in the formik fields
    return isLastItemValid && isLastItemComplete
  }, [formik.values.optionDetails, formik.errors, activeLang])
  const {isAddMore} = useSelector((state: RootState) => state.vender)
  const [activeAdd, setActiveAdd] = useState(false)
  console.log(activeAdd)

  const handleAddOption = useCallback(() => {
    if (id) {
      setActiveAdd(true)
    }
    if (
      formik.values?.value_type === 'text' ||
      formik.values?.value_type === 'color' ||
      formik.values?.value_type === 'image'
    ) {
      setMsgErr('')
    } else {
      setMsgErr('You must choose a type')
    }
    if (
      !isValidLastItemInOptionDetails ||
      !formik.values.option_catalog_id ||
      !formik.values.input_type ||
      !formik.values.value_type
    ) {
      customToastr({
        type: 'error',
        title: 'You have one option not completed',
        description: 'Please complete the option before adding a new one',
      })

      return
    }

    const isNewImageOption =
      optionDetails.value_type === 'image' || formik.values.value_type === 'image'
    const isNewColorOption =
      optionDetails.value_type === 'color' || formik.values.value_type === 'color'
    const isNewTextOption =
      optionDetails.value_type === 'text' || formik.values.value_type === 'text'

    const haveOneNotCompalted = optionDetails?.option_details?.find((x: any) => x?.id === 'created')

    if (isAddMore && id && haveOneNotCompalted) {
      customToastr({
        type: 'error',
        title: 'You have one option not completed',
        description: 'Please complete the option before adding a new one',
      })
      return
    }

    if (isNewImageOption) {
      const newOption = {
        option_image: '',
        sort: 0,
        deleteId: new Date(),

        option_value: {},
      } as ItemOptionImage

      for (const lang of store.storeLangs) {
        newOption.option_value[lang] = ''
      }
      if (isAddMore && id) {
        dispatch(addOptionDetails(newOption))
        return
      }
      if (!isAddMore || !id) {
        formik.setFieldValue('optionDetails', [
          ...formik.values.optionDetails,
          {...newOption, id: -1},
        ])
      }
      return
    }
    //  for  color
    if (isNewColorOption) {
      const newOption = {
        color: '',
        sort: 0,
        deleteId: new Date(),
        option_value: {},
        option_code: '#000',
      } as ItemOptionColor
      for (const lang of store.storeLangs) {
        newOption.option_value[lang] = ''
      }
      if (isAddMore && id) {
        dispatch(addOptionDetails(newOption))
        return
      }
      if (!isAddMore || !id) {
        formik.setFieldValue('optionDetails', [
          ...formik.values.optionDetails,
          {...newOption, id: -1},
        ])
      }
      return
    }
    //  text
    if (isNewTextOption) {
      const newOption = {
        sort: 0,
        option_value: {},
        deleteId: new Date(),
      } as ItemOptionText
      for (const lang of store.storeLangs) {
        newOption.option_value[lang] = ''
      }
      if (isAddMore && id) {
        dispatch(addOptionDetails(newOption))
        return
      }
      if (!isAddMore || !id) {
        formik.setFieldValue('optionDetails', [
          ...formik.values.optionDetails,
          {
            ...newOption,
            id: -2,
            option_image: 'https://vendor.api.koninstore.com/storage/store/options',
          },
        ])
      }
      return
    }
  }, [formik, id, optionDetails, dispatch, isValidLastItemInOptionDetails])
  const [loading, setLoading] = useState(false)
  const updateOptionInfo = async () => {
    console.log(formik.values, 22)

    // try {
    //   await Options.put(formik.values, id, () => {}, setLoading)

    //   setMsgErr('') // طباعة الخطأ في وحدة التحكم
    // } catch (error: any) {
    //   setLoading(false)
    //   toast.error(error.response.data.message)

    //   setMsgErr(error.response.data.message) // طباعة الخطأ في وحدة التحكم
    // }
  }
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'var(--bs-gray-800)',
    }),
  }

  const [msgErr, setMsgErr] = useState('')

  const [value, setValue] = useState<any>({})
  useEffect(() => {
    if (optionsName.length > 0) {
      setValue(
        optionsName.find((item: any) => {
          return item.value == formik.values.option_catalog_id.value
        })
      )
    }
  }, [optionsName, formik.values.option_catalog_id])
  const [itActive, setActive] = useState(false)
  useEffect(() => {
    if (itActive && formik.values.value_type !== 'textt' && !id) {
      handleAddOption()
    }
    setActive(true)
  }, [formik.values.value_type])
  const optionTypes: Array<{
    label: string
    value: TInputType
  }> = [
    {label: t('singleSelect'), value: 'single_select'},
    {label: t('multiSelect'), value: 'multi_select'},
    {label: t('date'), value: 'date'},
    {label: t('text'), value: 'text'},
    {label: t('file'), value: 'file'},
  ]
  return (
    <Stack style={{marginTop: '30px'}} className={`card bg-gray-100`}>
      <Boundary
        isLoading={(id && isEditRoute && !(optionDetails as any).id) || false}
        error={error}
      >
        <>
          <div className='card-header border-0 pt-5 custom-box '>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                {id ? 'Edit' : 'Add'} {t('options')}
              </span>
            </h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            // className='form d-flex flex-column flex-lg-row'
            noValidate
          >
            <div className='card-body py-8 pb-5 custom-box'>
              <div className='row mb-4 '>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <label className='required form-label'>{t('optionCatlogueName')} </label>

                  <OldSelect
                    name='option_catalog_id.value'
                    errors={errors}
                    isLoading={isLoadingOptionName}
                    styles={customStyles}
                    isDisabled={!isEdit}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    components={animatedComponents}
                    options={optionsName as any}
                    isMulti={false}
                    onChange={(item: any) => {
                      if (item && typeof item === 'object' && !Array.isArray(item)) {
                        formik.setFieldValue('option_catalog_id', item)
                      }
                    }}
                    value={value || ''}
                    onBlur={() => formik.setFieldTouched('option_catalog_id', true)}
                  />

                  {formik.touched.option_catalog_id && formik.errors.option_catalog_id ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.option_catalog_id.label}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <label className='required form-label'> {t('optionType')} </label>
                  <OldSelect
                    styles={customStyles}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    isDisabled={id ? true : false}
                    isMulti={false}
                    components={animatedComponents}
                    options={optionTypes}
                    onChange={(item: any) => {
                      formik.setFieldValue('value_type', 'tex')
                      formik.setFieldValue('optionDetails', [])

                      if (item && typeof item === 'object' && !Array.isArray(item)) {
                        formik.setFieldValue('input_type', item.value as TInputType)
                        if (item.label === 'single_select' || item.label === 'multi_select') {
                          formik.setFieldValue('value_type', 'tex' as TInputType)
                        } else {
                          formik.setFieldValue('value_type', 'textt' as TInputType)
                        }
                      }
                    }}
                    value={{
                      value: formik.values.input_type,
                      label: optionTypes.find((option) => option.value === formik.values.input_type)
                        ?.label,
                    }}
                    onBlur={() => formik.setFieldTouched('input_type', true)}
                  />

                  {formik.touched.input_type && formik.errors.input_type ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.input_type}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <LanguageRowInput<any>
                isEdit={!isEdit}
                msgErr={msgErr}
                formik={formik}
                name={'name'}
                errors={errors}
              />
              {id && (
                <div style={{display: 'flex'}}>
                  <div className='py-6'>
                    <MainBtn
                      width='auto'
                      handelSubmit={updateOptionInfo}
                      isLoading={loading}
                      text='save'
                    />
                  </div>
                </div>
              )}
              {(formik.values.input_type === 'single_select' ||
                formik.values.input_type === 'multi_select') && (
                <>
                  {!id &&
                    (formik.values.input_type === 'single_select' ||
                      formik.values.input_type === 'multi_select') && (
                      <div className='my-4'>
                        <label className='required form-label mt-2'>{t('optionValueType')} </label>

                        <RadioInput
                          name='value_type'
                          options={[
                            {value: 'image', label: t('image')},
                            {value: 'color', label: t('color')},
                            {value: 'text', label: t('text')},
                          ]}
                          onChange={(value) => {
                            //  need  to  optionDetails as  empty optionDetails
                            formik.setFieldValue('value_type', value.target.value as TInputType)
                            console.log(value)

                            formik.setFieldValue('optionDetails', [])
                          }}
                          value={{
                            value: formik.values.value_type,
                            label: formik.values.value_type,
                          }}
                          // onClick={() => {}}
                          errors={formik.errors.value_type as string}
                          touched={formik.touched.value_type}
                          checked={formik.values.value_type}
                        />
                      </div>
                    )}
                  <ErrorMsg msgErr={msgErr} name='You must add a options' />
                  <ErrorMsg msgErr={msgErr} name='You must choose a type' />
                </>
              )}
            </div>

            {formik.values.value_type === 'image' && formik?.values?.optionDetails && (
              <OptionDetails
                optionDetails={formik.values?.optionDetails || []}
                activeLang={activeLang}
                handleRemoveOption={handleRemoveOption}
                formik={formik}
                disabled={!isEdit || !isValidLastItemInOptionDetails}
                handleEditOption={handleEditOption}
                handelSaveOption={handelSaveOption}
                msgErr={msgErr}
                Adderrors={errors}
                setActiveAdd={setActiveAdd}
              />
            )}
            {formik.values.value_type === 'color' && formik?.values?.optionDetails && (
              <OptionColorDetails
                disabled={!isEdit}
                handleRemoveOption={handleRemoveOption}
                optionDetails={(formik.values?.optionDetails as Array<ItemOptionColor>) || []}
                activeLang={activeLang}
                formik={formik}
                handleEditOption={handleEditOption}
                msgErr={msgErr}
                Adderrors={errors}
                setActiveAdd={setActiveAdd}
              />
            )}
            {formik.values.value_type === 'text' && formik?.values?.optionDetails && (
              <OptionTextDetails
                disabled={!isEdit}
                Adderrors={errors}
                handleRemoveOption={handleRemoveOption}
                optionDetails={(formik.values?.optionDetails as Array<ItemOptionText>) || []}
                activeLang={activeLang}
                formik={formik}
                handleEditOption={handleEditOption}
                msgErr={msgErr}
                setActiveAdd={setActiveAdd}
              />
            )}
            {(formik.values.input_type === 'single_select' ||
              formik.values.input_type === 'multi_select') &&
            isEdit ? (
              <>
                {formik.values.value_type !== 'tex' && (
                  <div style={{padding: '20px'}} className='py-6'>
                    <MainBtn
                      width='auto'
                      handelSubmit={handleAddOption}
                      isLoading={loading}
                      text='addMore'
                      disabled={!Boolean(isValidLastItemInOptionDetails) || activeAdd}
                    />
                  </div>
                )}
              </>
            ) : null}
            {!id && isEdit && (
              <>
                {' '}
                <div
                  style={{gap: '20px', padding: '41px 20px'}}
                  className=' card-footer d-flex justify-content-end '
                >
                  <Link
                    to='/catalog/options'
                    className='btn btn-light btn-active-light-primary me-2'
                  >
                    {t('cancel')}
                  </Link>
                  <MainBtn
                    type='submit'
                    width='auto'
                    handelSubmit={() => {}}
                    isLoading={isLoading}
                    text='saveChange'
                  />
                </div>
                <Stack alignItems={'flex-end'}>
                  <ErrorMsg msgErr={msgErr} name='' />
                </Stack>
              </>
            )}
          </form>
        </>
      </Boundary>
      {/* begin::Header */}
    </Stack>
  )
}
