import Apiservices from '../../lib/ApiServices'

const ChatApi = {
  post: async (data: any) => {
    const coupons = await Apiservices.post(`/vendor/customers/get/all`, {ids: data})

    return coupons
  },
}
export default ChatApi
