import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import ErrorMsg from './NewMsgErr'

const Editor: React.FC<any> = ({name, formik, child, errors}: any) => {
  const store = useSelector((state: RootState) => state.vender)

  const [languages, setlanguages] = useState(['en'])

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const handleChange = (e: string) => {
    if (formik) {
      formik.setFieldValue(`${child}[${name}]`, e)
    }
  }

  return (
    <div>
      {languages.map((ele, i) => (
        <React.Fragment key={ele}>
          {name === ele && (
            <ReactQuill
              value={formik.values[child] && formik.values[child][name]}
              onChange={handleChange}
              modules={{
                toolbar: [
                  [{header: [1, 2, false]}],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{list: 'ordered'}, {list: 'bullet'}],
                  // ['link', 'image', 'video'],
                  ['clean'],
                ],
              }}
            />
          )}
        </React.Fragment>
      ))}
      {errors && !name && errors[child] && typeof errors[child] === 'string' ? (
        <ErrorMsg msgErr={errors[child]} />
      ) : errors &&
        child &&
        errors[`${child}.${name}`] &&
        typeof errors[`${child}.${name}`] === 'string' ? (
        <ErrorMsg child={name} msgErr={errors[`${child}.${name}`]} />
      ) : (
        ''
      )}
    </div>
  )
}

export default Editor
