import ErrorSection from './Error'

interface RadioInputProps {
  name: string
  options: {value: string; label: string}[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  touched?: boolean
  errors?: string
  checked?: any
  value?: {
    label: string
    value: string
  }
}

export const RadioInput: React.FC<RadioInputProps> = ({
  name,
  options,
  onChange,
  errors,
  touched,
  checked,
}) => {
  return (
    <div className='d-flex gap-3 my-4'>
      {options?.map((option) => (
        <div key={option.value} className='form-check form-check-danger'>
          <input
            id={`${name}-${option.value}`}
            name={name}
            type='radio'
            className='form-check-input'
            onChange={onChange}
            value={option.value}
            checked={option.value === checked ? true : false}
          />
          <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
        </div>
      ))}
      <ErrorSection errors={errors} touched={touched} />
    </div>
  )
}
