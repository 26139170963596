import {Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../customTable/TitlePage'
import InputWithLabel from '../customTable/LabelWithText'
import Label from '../customTable/Label'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function InvoiceForm() {
  const {t} = useTranslation()
  const [data, setData] = useState({})
  const bannerInfo = {
    title: t('servesFeesValue'),
    home: t('home'),
    linkHome: '/',
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack>
      {' '}
      <TitlePage info={bannerInfo} />
      <Stack mt='21px' bgcolor={'#fff'} p='32px' borderRadius={'8px'}>
        <Stack gap='32px'>
          <InputWithLabel
            width={{md: '400px'}}
            name='servesFees'
            type='number'
            data={data}
            setData={setData}
            text='servesFees'
          />{' '}
          <InputWithLabel
            width={{md: '400px'}}
            name='percentageOfTheBillValue'
            type='number'
            data={data}
            setData={setData}
            text='percentageOfTheBillValue'
          />{' '}
          <InputWithLabel
            width={{md: '400px'}}
            name='payableTaxPaid'
            type='number'
            data={data}
            setData={setData}
            text='payableTaxPaid'
          />
        </Stack>
        <Stack mt='54px' gap='32px'>
          <Label text='ServiceFeeCalculation' />
          <InputWithLabel
            width={{md: '400px'}}
            name='invoiceValue'
            type='number'
            data={data}
            setData={setData}
            text='invoiceValue'
          />{' '}
          <Stack width={{md: '400px'}} position={'relative'}>
            <InputWithLabel
              width={{md: '400px'}}
              name='servesFeesFixed'
              type='number'
              data={data}
              setData={setData}
              text='servesFees'
            />{' '}
            <Stack
              sx={{
                left: store.isRtl !== 0 ? '0px' : 'auto',
                right: store.isRtl === 0 ? '0px' : 'auto',
                bottom: '0',
                bgcolor: 'var(--orange-color)',
                p: '13px',
                color: '#fff',
                borderRadius: store.isRtl === 0 ? '0 6.18px 6.18px 0' : '6.18px 0 0 6.18px',
              }}
              position={'absolute'}
            >
              {' '}
              (3%){' '}
            </Stack>
          </Stack>
          <Stack width={{md: '400px'}} position={'relative'}>
            <InputWithLabel
              width={{md: '400px'}}
              name='percentageOfTheBillValue'
              type='number'
              data={data}
              setData={setData}
              text='percentageOfTheBillValue'
            />
            <Stack
              sx={{
                left: store.isRtl !== 0 ? '0px' : 'auto',
                right: store.isRtl === 0 ? '0px' : 'auto',
                bottom: '0',
                bgcolor: 'var(--orange-color)',
                p: '13px',
                color: '#fff',
                borderRadius: store.isRtl === 0 ? '0 6.18px 6.18px 0' : '6.18px 0 0 6.18px',
              }}
              position={'absolute'}
            >
              {' '}
              (5%){' '}
            </Stack>
          </Stack>
          <InputWithLabel
            width={{md: '400px'}}
            name='totalInvoiceValue'
            type='number'
            data={data}
            setData={setData}
            text='totalInvoiceValue'
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
