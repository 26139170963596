import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../../index'
import {FetchStatus} from '../../types'
import {
  addOptionAction,
  changeStatusAction,
  dealteOptionAction,
  optionsAction,
  updateOptionAction,
} from './thunk'
import {IinitialState} from './types'

const initialState: IinitialState = {
  options: {
    data: [],
    pages: null,
  },
  option: {},
  status: FetchStatus.idle,
  error: null,
}

const OptionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(optionsAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.options = action.payload
      })
      .addCase(optionsAction.rejected, (state, action) => {
        state.status = FetchStatus.error
        state.error = action.payload as string
      })
      .addCase(optionsAction.pending, (state, _) => {
        state.status = FetchStatus.pending
      })
      .addCase(changeStatusAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
      })
      //    update  the  option catolog
      .addCase(updateOptionAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.options.data = state.options.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      })
    //   addOptionAction
    builder.addCase(addOptionAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })
    builder.addCase(addOptionAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    builder.addCase(addOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.options.data.push(action.payload)
    })
    //   dealteOptionAction

    builder.addCase(dealteOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed

      state.options.data = state.options.data.filter((item) => {
        return item.id !== action.payload
      })
    })

    builder.addCase(dealteOptionAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(dealteOptionAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
  },
})

export default OptionsSlice.reducer

export const selectOptions = (state: RootState) => state.optionCategory
