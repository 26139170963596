import {Stack, Typography} from '@mui/material'
import React from 'react'
import SelectLangs from './SelectLang'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Navbar} from './Navbar'
import {useTranslation} from 'react-i18next'
import MainBtn from '../customTable/MainBtn'

export default function GetStart() {
  const languages = [
    {
      id: 1,
      lang: 'en',
      name: 'English',
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
      isRtl: 0,
    },
    {
      id: 2,
      lang: 'ar',
      name: 'العربيه',
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/220px-Flag_of_Iraq.svg.png',
      isRtl: 1,
    },
    {
      id: 3,
      lang: 'ku',
      name: 'Kurdish',
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Kurdistan.svg/280px-Flag_of_Kurdistan.svg.png',
      isRtl: 1,
    },
  ]
  const {t} = useTranslation()
  const language = navigator.language
  const myLang = languages.find((e: any) => e.lang === language.split('-')[0])

  return (
    <Stack alignItems={'center'} gap={{md: '46px', xs: '20px'}} direction={'row'}>
      <Stack display={{md: 'block', xs: 'none'}} width={'120px'}>
        <SelectLangs
          value={{
            id: localStorage.getItem('lang')
              ? +JSON.parse(localStorage.getItem('lang') || `{id: 1}`).id
              : myLang
              ? myLang.id
              : 1,
          }}
          option={languages}
          color={'var(--bs-app-bg-color)'}
        />
      </Stack>
      <Typography sx={{fontSize: '14px', fontWeight: '600', display: {md: 'block', xs: 'none'}}}>
        {t('login')}
      </Typography>
      <a
        href='https://vendor.api.koninstore.com/en/create-store'
        style={{
          border: 'none',
        }}
      >
        <MainBtn text={'getStarted'} handelSubmit={() => {}} />
      </a>
      <Stack display={{md: 'none', xs: 'block'}}>
        <Navbar />
      </Stack>
    </Stack>
  )
}
