import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Vendor = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/profile`, callback, setIsLoading)
  },

  put: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/profile`, data, callback, setIsLoading)
  },
  signin: async (data: any, callback: any, setIsLoading: any) => {
    console.log(data)

    ApisMethods().post(
      `/vendor/login`,
      {...data, phone_code: data.phone_code.phone_code},
      callback,
      setIsLoading
    )
  },
  forgetPassword: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(
      `/vendor/forget-password`,
      {...data, phone_code: data.phone_code.phone_code},
      callback,
      setIsLoading
    )
  },
  checkCode: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/check-reset-code`, {...data}, callback, setIsLoading)
  },
  resetPassword: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/reset-password`, data, callback, setIsLoading)
  },
  logout: (callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/logout`, {}, callback, setIsLoading)
  },
  resetVendorPassword: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/profile/edit-password`, data, callback, setIsLoading)
  },
}
export default Vendor
