import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Stack, Typography} from '@mui/material'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {useDispatch} from 'react-redux'
import MainBtn from '../../customTable/MainBtn'

type Props = {
  search: string
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  categories: any
}

export const TooBar = ({search, handleSearch, num, numSub}: any) => {
  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const {categories, status, error} = useSelector((state: RootState) => state.category)
  const dispatch = useDispatch()
  return (
    <div className='card-header border-0 pt-5'>
      <div className='card-toolbar w-100'>
        <div
          style={{gap: '10px'}}
          className='d-flex flex-wrap justify-content-between align-items-center w-100'
        >
          <div className='mb-3 mb-md-0'>
            <div className='position-relative'>
              <Stack m={store.isRtl === 0 ? '0 0 0 15px' : '0 15px 0 0'}>
                {' '}
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50  translate-middle-y'
                />
              </Stack>

              <input
                style={{
                  textIndent: '2rem',
                }}
                value={search}
                type='text'
                className='form-control'
                name='search'
                onChange={handleSearch}
                placeholder={t('search') || 'search'}
              />
            </div>
          </div>
          <div className='d-flex flex-wrap align-items-center'>
            <Stack
              flexWrap={'wrap'}
              direction={'row'}
              justifyContent={'flex-end'}
              gap='10px'
              className='d-flex flex-wrap align-items-center'
            >
              {(!num || num > 0) && (
                <MainBtn
                  width='auto'
                  num={numSub}
                  backageKey={'SUBCATEGORY_NUM'}
                  without
                  handelSubmit={() => {
                    navigate('/catalog/add-sub-category')
                  }}
                  text='createSubCategory'
                />
              )}
              {categories.pages && categories.pages.total === 0 && !search && (
                <Typography sx={{color: 'orange'}}>{t('emtyMsg')} </Typography>
              )}
              {store.permissions && store.permissions.includes('categories_create') && (
                <MainBtn
                  width='auto'
                  num={num}
                  backageKey={'CATEGORY_NUM'}
                  handelSubmit={() => {
                    dispatch(actions.setCashData({}))
                    dispatch(actions.setCashCataData({description: {}}))
                    navigate('/catalog/add-category')
                  }}
                  text='addCategory'
                />
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  )
}
