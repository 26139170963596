import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import iMedia from '../../../interface/iMedia'
import {Link, useNavigate} from 'react-router-dom'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import MainBtn from '../../customTable/MainBtn'
export default function Menu() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('menu'),
    home: t('home'),
    linkHome: '/',
  }
  const [rows, setRows] = useState<iMedia[]>([])
  const [added, setAdded] = useState(false)
  const [total, setTotal] = useState(1)

  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const [num, setNum] = useState(0)

  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])
  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />

        <Stack gap={'10px'} direction={'row'}>
          {store.permissions && store.permissions.includes('menus_create') && (
            <MainBtn
              backageKey='MENU_NUM'
              num={num}
              handelSubmit={() => navigate('/storeManagement/create-menu')}
              text='create'
            />
          )}{' '}
        </Stack>
      </Stack>
      <Table
        setNum={setNum}
        total={total}
        setTotal={setTotal}
        added={added}
        rows={rows}
        setRows={setRows}
      />
    </Stack>
  )
}
