import React, {useState} from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import EditIcon from '@mui/icons-material/Edit'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined'
import SaveIcon from '@mui/icons-material/Save'
import PrintIcon from '@mui/icons-material/Print'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {useParams} from 'react-router-dom'
import Variations from '../../../../../Api/variations'
import DeletedMsg from '../../../../customTable/DeleteMsg'
const actions = [
  {icon: <EditIcon />, name: 'Edit'},
  {icon: <DeleteIcon />, name: 'Delete'},
]

export default function VariationsAction({
  item,
  setIsDelete,
  setOpen,
  setAdded,
  setRows,
  rows,
  setOpenMsg,
}: any) {
  const handelEdit = () => {
    setOpen(true)
  }
  return (
    <Box sx={{height: 20, transform: 'translateZ(0px)', flexGrow: 1}}>
      <SpeedDial
        ariaLabel='SpeedDial basic example'
        FabProps={{className: 'actions'}}
        sx={{
          position: 'absolute',
          bottom: '13px',
          right: '5px',
          padding: '0',
          zIndex: '50',
        }}
        icon={<MoreVertIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            onClick={() => {
              if (action.name === 'Delete') {
                // handelDelete()
                setOpenMsg(true)
              } else {
                handelEdit()
              }
            }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}
