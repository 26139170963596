import React, {useState} from 'react'
import {ActionsCell} from '../../componet/Menu'
import {Stack} from '@mui/material'
import IBanner from '../../interface/IBanner'
import Switch from '../../componet/Toastr/Switch'
import Banner from '../../Api/banner'
import Edit from './Edit'
import Manufacturers from '../../Api/manufacturers'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../customTable/SimpleLoading'
import OptionCatalog from '../../Api/optionCatalog'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setIsLoading,
  setAdded,
  setNum,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
  setNum: any
  setAdded: any
}) {
  const [state, setState] = useState<any>(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelEdit = () => {
    setOpen(true)
  }
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    OptionCatalog.putStatus(
      item.id,
      {status: state === 1 ? 0 : 1},
      () => {
        setRows(
          [
            ...rows.filter((e: any) => e.id != item.id),
            {...editItem, status: state === 1 ? 0 : 1},
          ].sort((a, b) => a.id - b.id)
        )
        setState(state === 1 ? 0 : 1)
      },
      setIsLoadingStatus
    )
  }
  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const openForm = () => {
    setOpen(true)
  }
  const handelDelete = async () => {
    OptionCatalog.destroy(
      item.id,
      () => {
        setNum((prev: any) => prev - 1)

        setRows(rows.filter((e: any) => e.id !== item.id))
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(!true)
        }, 1)
      },
      setIsLoadingDelete
    )

    // setDeleted(false)
  }
  const {lang, defLang, permissions} = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td className=''>
              {typeof item.name === 'object'
                ? (editItem.name[lang] || editItem.name[defLang]) &&
                  (editItem.name[lang] || editItem.name[defLang]).slice(0, 15)
                : editItem.name}{' '}
              {typeof editItem.name === 'object' &&
                (editItem.name[lang] || editItem.name[defLang]) &&
                (editItem.name[lang] || editItem.name[defLang]).length > 15 &&
                '...'}
            </td>
            <td>
              {isLoadingStatus ? (
                <SimpleLoading />
              ) : (
                <Switch
                  name='status'
                  value={state === 1 ? true : false}
                  handleChange={
                    permissions &&
                    permissions.includes('option_catalogs_update') &&
                    handleStatusChange
                  }
                />
              )}
            </td>

            <td className='text-end'>
              {isLoadingDelete ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={permissions && permissions.includes('option_catalogs_update') && openForm}
                  onDelete={
                    permissions && permissions.includes('option_catalogs_delete') && handelDelete
                  }
                  onView={null}
                />
              )}
            </td>
          </tr>
          <Edit
            setRows={setRows}
            rows={rows}
            setItem={setEditItem}
            item={editItem}
            open={open}
            setOpen={setOpen}
          />
        </>
      )}
    </>
  )
}
