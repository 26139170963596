import {Avatar, IconButton, Stack, Typography} from '@mui/material'
import React, {useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'

export default function ChatBox({user, messages, sendMessage, message, setMessage}: any) {
  const imageStore = localStorage.getItem('image')
  const messagesRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollToBottom()
  }, [messages])
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const scrollToBottom = () => {
    const messagesContainer = ReactDOM.findDOMNode(messagesRef.current) as HTMLDivElement
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }
  if (!user.id)
    return (
      <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
        <Typography sx={{color: '#A1A5B7'}}>
          {' '}
          <img style={{height: '400px'}} src='/media/avatars/msg.svg' alt='' />
        </Typography>
      </Stack>
    )

  return (
    <Stack p='22px 29px'>
      <Stack direction={'row'}>
        <Stack mb='41px'>
          <Typography sx={{fontSize: '17px', fontWeight: '600'}}>{user.full_name}</Typography>
        </Stack>
      </Stack>
      <Stack gap='20px'>
        <Stack gap='32px' height={'60vh'} ref={messagesRef} sx={{overflowY: 'scroll'}}>
          {messages.map((msg: any, index: any) => (
            <Stack
              gap='7px'
              alignItems={msg.sender === 'user' ? 'flex-start' : 'flex-end'}
              key={index}
            >
              <Stack
                alignItems={'center'}
                gap='11px'
                direction={msg.sender === 'user' ? 'row' : 'row-reverse'}
              >
                <Avatar src={msg.sender === 'user' ? 'user' : imageStore || ''} />
                <Typography>{msg.sender === 'user' ? user.full_name : t('me')}</Typography>
              </Stack>
              <Stack
                borderRadius={'6.5px'}
                bgcolor={msg.sender === 'user' ? 'var(--bs-gray-300)' : 'var(--bs-app-bg-color)'}
                p='16px'
                minWidth={'200px'}
              >
                <Typography
                  dir={msg.sender !== 'user' ? 'rtl' : 'ltr'}
                  sx={{
                    color:
                      msg.sender === 'vendor' ? 'var(--bs-body-color)' : 'var(--bs-body-color)',
                  }}
                >
                  {msg.message}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        {store.permissions && store.permissions.includes('chats_reply') && (
          <Stack
            onSubmit={sendMessage}
            direction={'row'}
            gap='20px'
            alignItems={'center'}
            component={'form'}
          >
            <input
              style={{
                padding: '8px',
                background: 'var(--bs-body-bg)',
                border: '1px solid var(--bs-gray-300)',
                borderRadius: '6.18px',
                width: 'calc(100% - 40px)',
                color: 'var(--bs-text-dark)',
              }}
              type='text'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <IconButton
              onClick={sendMessage}
              disabled={!message ? true : false}
              sx={{background: '#ff000000', width: '24px', height: '24px'}}
            >
              {message ? (
                <img
                  style={{width: '24px', height: '24px'}}
                  src='./media/avatars/icons/activeSend.svg'
                  alt=''
                />
              ) : (
                <img
                  style={{width: '24px', height: '24px'}}
                  src='./media/avatars/icons/send.svg'
                  alt=''
                />
              )}
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
