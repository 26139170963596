import {Stack} from '@mui/material'
import React from 'react'
import MainBtn from './MainBtn'

export default function RestSaveButton({
  SaveText,
  cancelText,
  submit,
  cancel,
  isLoading,
  isCancel,
}: {
  SaveText: string
  cancelText: string
  submit: any
  cancel: any
  isLoading: boolean
  isCancel?: any
}) {
  return (
    <Stack mt={'20px'} gap='20px' width={'100%'} direction={'row'} justifyContent={'flex-end'}>
      {isCancel && <MainBtn width='auto' without handelSubmit={cancel} text={cancelText} />}{' '}
      <MainBtn width='auto' isLoading={isLoading} handelSubmit={submit} text={SaveText} />
      {/* <button
        disabled={isLoading}
        type='submit'
        onClick={submit}
        // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}

        className='btn btn-danger'
      >
        <span className='indicator-label'>{isLoading ? 'loading...' : SaveText}</span>
      </button> */}
    </Stack>
  )
}
