import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../customTable/InputType'
import Vendor from '../../Api/vendor'
import {useDispatch} from 'react-redux'
import {actions} from '../../store/vender/Auth/auth.slice'
import {LoginSchema} from '../../yup/vendorSchema'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import MainBtn from '../customTable/MainBtn'
import ValidationFun from '../../yup/ValidationFun'
import ErrorMsg from '../customTable/NewMsgErr'
export default function LoginForm({setIsActive}: any) {
  const [userInfo, setUserInfo] = useState<any>({mobile: '0592146780', password: 'ahmad@Nahal12'})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<any>({})
  const handelSubmit = async (e: any) => {
    e.preventDefault()
    ValidationFun({...userInfo, phone_code: value}, LoginSchema, setErrors, (value: any) => {
      Vendor.signin(
        {...userInfo, phone_code: value},
        (res: any) => {
          localStorage.setItem('token', res.token)
          dispatch(actions.login(res.token))

          localStorage.setItem('name', res.full_name)
          localStorage.setItem('image', res.profile_image)
          localStorage.setItem('storeImage', res.store.logo)
          localStorage.setItem('domain', res.store.domain)
          localStorage.setItem('id', res.store?.id)
          localStorage.setItem('pay', res.pay.is_link_pay)
          localStorage.setItem('currency', res.currency?.symbol)

          setMsgErr('')
        },
        setUsLoading
      )
    })
  }
  const {t} = useTranslation()
  const [value, setValue] = useState('+964')
  const [type, setType] = useState('password')
  const store = useSelector((state: RootState) => state.vender)

  return (
    <form>
      <Stack gap='20px'>
        <Typography sx={{fontSize: '24px', fontWeight: '600', textAlign: 'center'}}>
          {t('signinTitle')}
        </Typography>
        <Stack
          direction={'row'}
          borderRadius={'6.18px'}
          border='1px solid var(--bs-gray-300)'
          width={'100%'}
          p={'0 13px'}
          bgcolor={'var(--bs-body-bg)'}
          height={'47px'}
          alignItems={'center'}
          sx={{background: 'var(--bs-body-bg)'}}
          dir='ltr'
        >
          <Stack
            height={'80%'}
            sx={{borderRight: '1px solid  var(--bs-gray-300)', background: 'var(--bs-body-bg)'}}
          >
            <PhoneInputWithCountrySelect
              style={{width: '110px', height: '38px'}}
              placeholder='Enter phone number'
              international
              defaultCountry='IQ'
              addInternationalOption={false}
              value={value}
              disableCountryCode={true}
              readOnly={true}
              onChange={(phoneNumber: any) => {
                setValue(phoneNumber)
              }}
              countries={['IQ']}
            />
          </Stack>
          <input
            style={{
              border: 'none',
              background: 'var(--bs-body-bg)',
              width: 'calc(100%)',
              padding: '12.0625px 13px 12.5px',
              color: 'var(--bs-text-dark)',
            }}
            className='login-input'
            placeholder='Number Phone'
            type='tel'
            value={userInfo.mobile}
            onChange={(e: any) => setUserInfo({...userInfo, mobile: e.target.value})}
          />
        </Stack>
        {/* <InputType
          type={'email'}
          placeholder={t('email')}
          value={userInfo}
          change={setUserInfo}
          name={'email'}
        /> */}
        <ErrorMsg msgErr={errors['mobile']} />
        <Stack position={'relative'}>
          <InputType
            errors={errors}
            type={type}
            placeholder={t('password')}
            value={userInfo}
            change={setUserInfo}
            name={'password'}
          />
          <Stack
            right={store.isRtl === 0 ? '10px' : 'auto'}
            left={store.isRtl === 1 ? '10px' : 'auto'}
            top='13px'
            position={'absolute'}
          >
            {type !== 'text' ? (
              <VisibilityOffIcon
                onClick={() => {
                  setType('text')
                }}
              />
            ) : (
              <RemoveRedEyeIcon
                onClick={() => {
                  setType('password')
                }}
              />
            )}
          </Stack>
        </Stack>

        <Typography
          onClick={() => {
            setIsActive(1)
          }}
          sx={{fontSize: '13px', color: '#3E97FF', fontWeight: '600', cursor: 'pointer'}}
        >
          {t('forgotPassword')}
        </Typography>
        <MainBtn
          isLoading={isLoading}
          text={'login'}
          handelSubmit={handelSubmit}
          disabled={isLoading}
        />
      </Stack>
    </form>
  )
}
