import React, {useState} from 'react'
import {ActionsCell} from '../../componet/Menu'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Switch from '../../componet/Toastr/Switch'
import Media from '../../Api/media'
import PageApi from '../../Api/Page'
import {useNavigate} from 'react-router-dom'
import MenuApi from '../../Api/menu'
import MainBtn from '../customTable/MainBtn'
import PopUp from '../customTable/PopUp'
import Form from './Form'
import Invoice from './invoce/View'

export default function Row({item, i}: {item: any; i: number}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [openInvice, setOpenInvice] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const navigate = useNavigate()

  const pay = () => {
    setOpenInvice(true)
  }

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // MenuApi.putStatus(item.id, state === 1 ? 0 : 1)

    setState(state === 1 ? 0 : 1)
  }
  const [isDeleted, setDeleted] = useState(true)
  const handelDelete = async () => {
    // await MenuApi.delete(item.id)
    setDeleted(false)
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td style={{cursor: 'pointer'}}>
              <a onClick={pay} style={{color: '#009EF7'}}>
                #{editItem.code}
              </a>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.start_date}</span>{' '}
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>
                {editItem.paid_amount}
                {editItem.paid_currency_symbol}
              </span>{' '}
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>
                {' '}
                {editItem.total}
                {editItem.paid_currency_symbol}
              </span>{' '}
            </td>{' '}
            <td style={{textAlign: 'start'}} className='text-start'>
              {editItem.due_date}
            </td>
            <td style={{textAlign: 'start'}} className='text-start'>
              <MainBtn
                text='pay'
                width='auto'
                handelSubmit={() => {
                  setOpen(true)
                }}
              />
            </td>
          </tr>
        </>
      )}
      <PopUp width={'sm'} open={open} setOpen={setOpen}>
        <Form />
      </PopUp>
      <PopUp width={'md'} open={openInvice} setOpen={setOpenInvice}>
        {openInvice && <Invoice editItem={editItem} id={editItem.id} />}
      </PopUp>
    </>
  )
}
