import {FormikProps} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import SelectFormControl from '../../../componet/Form/CustmSelect'
import {InitialValues} from './validationSchema'
import MySelect from '../../customTable/CustomSelectTest'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {useDispatch} from 'react-redux'
import MainBtn from '../../customTable/MainBtn'

interface Props {
  formik: FormikProps<InitialValues>
  options: Array<{label: string; value: string}>
}

export const ProductDetails: React.FC<Props> = ({formik, options}) => {
  const [test, setTest] = useState({testselect: ''})
  const {id} = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const idcata = searchParams.get('idcata')
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navagite = useNavigate()
  useEffect(() => {
    if (formik.values.parent_id) {
    } else if (idcata) {
      formik.setFieldValue('parent_id', idcata)
    } else if (test.testselect) {
      formik.setFieldValue('parent_id', test.testselect)
    } else if (options.length > 0) {
      // formik.setFieldValue('parent_id', options[0].value)
    }
  }, [test, options, idcata])
  useEffect(() => {
    if (idcata && !formik.values.parent_id) {
      formik.setFieldValue('parent_id', idcata)
    }
  }, [idcata, formik.values])
  return (
    <div className='card card-flush py-4'>
      {/*begin::Card header*/}
      <div className='card-header'>
        {/*begin::Card title*/}
        <div className='card-title'>
          <h2>{t('subCategoryDetails')}</h2>
        </div>
        {/*end::Card title*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className='card-body pt-0'>
        <p>{t('parentCategory')}</p>

        <MySelect
          disabled={idcata ? true : false}
          name='testselect'
          option={options}
          setValues={setTest}
          values={test}
          value={{value: idcata ? idcata : 0}}
        />

        <div style={{marginTop: '6px'}} className='text-muted fs-7 mb-7'>
          {t('addNewCategory')}
        </div>
        <MainBtn
          without
          num={options.length}
          backageKey={'CATEGORY_NUM'}
          handelSubmit={() => {
            dispatch(actions.setCashCataData({...formik.values, dataCashType: 'cata', id: id}))
            navagite('/catalog/add-category')
          }}
          text='createNewCategory'
        />
      </div>
      {/*end::Card body*/}
    </div>
  )
}
