import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Variations = {
  getProductOption: (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/products-options/get-options/${id}`, callback, setIsLoading)
  },
  store: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/products-variations`, data, callback, setIsLoading)
  },
  getVariations: async (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/products-variations?product_id=${id}`, callback, setIsLoading)
  },
  destoyVariations: (option: any, id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(
      `/vendor/products-variations/${option.id}?product_id=${id}`,
      callback,
      setIsLoading
    )
  },
  getSingleVariation: async (
    id: number,
    product_id: string | undefined,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/products-variations/${id}?product_id=${product_id}`,
      callback,
      setIsLoading
    )

    // const veriation = await Apiservices.get(`/vendor/products-variations/${id}`, {
    //   params: {product_id: product_id},
    // })
    // return veriation
  },
  putVariation: (
    id: number,
    product_id: string | undefined,
    data: any,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().put(
      `/vendor/products-variations/${id}`,
      {
        ...data,
        product_id: product_id,
      },
      callback,
      setIsLoading
    )
  },
}
export default Variations
