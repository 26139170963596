import {Stack} from '@mui/material'
import React from 'react'
import Label from '../customTable/Label'
import LabelWithValue from './LabelWithValue'

export default function ShowPlan() {
  const data = [
    'feature',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
    '100 products  (This package should activate Reach2Pay business account after 14 days to continue',
  ]
  return (
    <Stack gap='32px' p='32px'>
      <Label text='planFeatures' />
      <LabelWithValue gap='10px' label='SR.NO' value='feature' />
      <Stack gap='24px'>
        {data.map((e: any, i: any) => (
          <LabelWithValue label={i + 1} value={e} />
        ))}
      </Stack>
    </Stack>
  )
}
