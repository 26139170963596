import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en', 'ar']
// let languages = SetLang() || ['en']
export const PageSchema = (languages: any) => {
  return Yup.object().shape({
    description: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string()
            .required(`descriptionErr`)
            .notOneOf(['<p><br></p>'], 'descriptionErr'),
        }
      }, {})
    ),
    permalink: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`permalinkErr`),
        }
      }, {})
    ),
    title: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`titleErr`),
        }
      }, {})
    ),
  })
}
