import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'

// let languages = SetLang() || ['en']

export const CategorySchema = (languages: any) => {
  const category = Yup.object().shape({
    permalink: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`permalinkErr`),
        }
      }, {})
    ),
    description: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`descriptionErr`),
        }
      }, {})
    ),
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),
    image: Yup.string().required('imageErr'),
    sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
  })
  return category
}
