import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../customTable/InputType'
import {useTranslation} from 'react-i18next'
import Vendor from '../../Api/vendor'
import * as Yup from 'yup'
import {ResetSchema} from '../../yup/vendorSchema'
import ErrorMsg from '../customTable/ErrorMsg'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import MainBtn from '../customTable/MainBtn'
import ValidationFun from '../../yup/ValidationFun'
export default function RestPassword({code, phone, code_phone, setIsActive}: any) {
  const [userInfo, setUserInfo] = useState({})
  const [isLoading, setUsLoading] = React.useState(false)
  const [msgErr, setMsgErr] = React.useState<string>('')
  const store = useSelector((state: RootState) => state.vender)
  const [type, setType] = useState('password')
  const [typeC, setTypeC] = useState('password')
  const [errors, setErrors] = useState<any>({})

  const handelSubmit = async (e: any) => {
    e.preventDefault()
    ValidationFun(userInfo, ResetSchema, setErrors, (value: any) => {
      Vendor.resetPassword(
        {
          ...userInfo,
          code: code,
          mobile: phone,
          phone_code: code_phone,
        },
        () => {
          setUsLoading(false)
          setIsActive(0)
          setMsgErr('')
        },
        setUsLoading
      )
    })
    // ResetSchema.validate(userInfo)
    //   .then(() => {
    //     Vendor.resetPassword(
    //       {
    //         ...userInfo,
    //         code: code,
    //         mobile: phone,
    //         phone_code: code_phone,
    //       },
    //       () => {
    //         setUsLoading(false)
    //         setIsActive(0)
    //         setMsgErr('')
    //       },
    //       setUsLoading
    //     )

    //     // dispatch(actions.login(user.data.data.token))
    //   })
    //   .catch((error: Yup.ValidationError) => {
    //     setMsgErr(error.message)
    //     setUsLoading(false)
    //   })
  }
  const {t} = useTranslation()

  return (
    <form onSubmit={handelSubmit}>
      <Stack gap='20px'>
        <Typography sx={{fontSize: '24px', fontWeight: '600', textAlign: 'center'}}>
          {t('resetPassword')}
        </Typography>
        <Stack>
          <Stack width={'100%'} position='relative'>
            <InputType
              errors={errors}
              type={type}
              placeholder={'password'}
              value={userInfo}
              change={setUserInfo}
              name={'new_password'}
            />
            <Stack
              right={store.isRtl === 0 ? '10px' : 'auto'}
              left={store.isRtl === 1 ? '10px' : 'auto'}
              top='13px'
              position={'absolute'}
            >
              {type !== 'text' ? (
                <VisibilityOffIcon
                  onClick={() => {
                    setType('text')
                  }}
                />
              ) : (
                <RemoveRedEyeIcon
                  onClick={() => {
                    setType('password')
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Stack width={'100%'} position='relative'>
            <InputType
              errors={errors}
              type={typeC}
              placeholder={'Password'}
              value={userInfo}
              change={setUserInfo}
              name={'new_password_confirmation'}
            />
            <Stack
              right={store.isRtl === 0 ? '10px' : 'auto'}
              left={store.isRtl === 1 ? '10px' : 'auto'}
              top='13px'
              position={'absolute'}
            >
              {typeC !== 'text' ? (
                <VisibilityOffIcon
                  onClick={() => {
                    setTypeC('text')
                  }}
                />
              ) : (
                <RemoveRedEyeIcon
                  onClick={() => {
                    setTypeC('password')
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>

        {/* <Typography sx={{fontSize: '13px', color: '#3E97FF', fontWeight: '600'}}>
        {t('forgotPassword')}
      </Typography> */}
        <MainBtn text={'resetPassword'} handelSubmit={handelSubmit} disabled={isLoading} />
      </Stack>
    </form>
  )
}
