import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {IconMenu} from '../../../../helpers/components/IconMenu'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activeMenu?: boolean
  setIsOpen?: any
  activeIcon?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  activeMenu,
  setIsOpen,
  activeIcon,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link
        onClick={() => {
          setIsOpen(false)
        }}
        style={{
          display: 'flex',
          gap: '10px',
          width: activeMenu ? '186px' : 'auto',
          color: isActive ? '#364DF7' : 'var(--bs-text-gray-700) ',
          background: isActive ? '#ffffff5c' : 'none',
          borderRadius: '8px',
        }}
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span
              style={{
                color: isActive ? '#364DF7' : 'var(--bs-text-gray-700) ',
                background: '#fff',
              }}
              className='bullet bullet-dot'
            ></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span
            style={{color: isActive ? 'red !important' : 'var(--bs-text-gray-700)  !important '}}
            // className='menu-icon'
          >
            {' '}
            {/* <KTSVGMENU path={activeIcon || ''} className='svg-icon-2' /> */}
            <IconMenu path={isActive ? activeIcon || '' : icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        {activeMenu && (
          <span
            style={{
              color: '#fff',
              // background: isActive ? 'red' : 'none',
            }}
            className='menu-title'
          >
            {title}
          </span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
