import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Tabs from './Tabs'
import IDoNotHaveAccountsInStoresForm from './IDoNotHaveAccountsInStoresForm'
import IHaveAccountsInStoresForm from './IHaveAccountsInStoresForm'
import IHaveAftterSubmit from './IHaveAftterSubmit'

export default function ActiveForm({android}: any) {
  const [active, setActive] = useState(1)
  const [isSubmit, setIsSubmit] = useState(false)
  useEffect(() => {
    setIsSubmit(false)
  }, [android])

  return (
    <Stack>
      {isSubmit && active === 1 ? (
        <IHaveAftterSubmit android={android} />
      ) : isSubmit && active !== 1 ? (
        <IHaveAftterSubmit android={android} IDontHave />
      ) : (
        <></>
      )}
      {!isSubmit && (
        <>
          {' '}
          <Tabs active={active} setActive={setActive} />
          {active !== 1 ? (
            <IDoNotHaveAccountsInStoresForm android={android} setIsSubmit={setIsSubmit} />
          ) : (
            <IHaveAccountsInStoresForm android={android} setIsSubmit={setIsSubmit} />
          )}
        </>
      )}
    </Stack>
  )
}
