import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Address = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/address`, callback, setIsLoading)
  },
  getCity: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/get-cities`, callback, setIsLoading)
  },
  put: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/settings/address`, data, callback, setIsLoading)
  },
}
export default Address
