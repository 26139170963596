import React from 'react'
// import {KTSVG} from '../../../../_metronic/helpers'
import {Stack} from '@mui/material'
import Search from '../../customTable/Search'
import ISearch from '../../../interface/ISearch'
import AddCoupon from './AddCoupon'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function HeadBar({
  search,
  handleSearch,
  total,
  rows,
  setRows,
  setIsLoading,
  setNum,
  num,
}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack direction={'row'} alignItems={'center !important'} className='card-header border-0 pt-5'>
      {/* {t('hi')}
      {lang} */}
      <Search search={search} handleSearch={handleSearch} />
      <div
        style={{alignItems: 'center'}}
        className='d-flex justify-content-end'
        data-kt-user-table-toolbar='base'
      >
        <div className='card-toolbar'>
          <>
            {/* begin::Filter Button */}

            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
              <div className='separator border-gray-200'></div>

              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>{t('status')}:</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    //  onChange={(e) => setStatus(e.target.value )}
                    //  value={status}
                  >
                    <option value=''></option>
                    {['pending', 'delivered', 'canceled'].map((key) => (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    ))}
                  </select>
                </div>
                {/* end::Input group */}

                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    //  disabled={isLoading}
                    //  onClick={resetData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                  >
                    Reset
                  </button>
                  <button
                    //  disabled={isLoading}
                    type='button'
                    //  onClick={filterData}
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                  >
                    Apply
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
            {/* end::SubMenu */}
          </>
        </div>
        {store.permissions && store.permissions.includes('coupons_create') && (
          <AddCoupon
            num={num}
            setNum={setNum}
            setIsLoading={setIsLoading}
            setRows={setRows}
            total={total}
            rows={rows}
          />
        )}

        {/* end::Add user */}
      </div>
    </Stack>
  )
}
