import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'

// const languages = SetLang() || ['en']

export const MenuSchema = (languages: any) => {
  return Yup.object().shape({
    sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
    // parent_id: Yup.number().required('parent is required').min(1, 'parent is required'),
    position: Yup.string().required('positionErr').min(2, 'positionErr'),
    url_link: Yup.object().shape(
      languages.reduce(
        (schema: any, lang: any) => ({
          ...schema,
          [lang]: Yup.string().required(`linkErr`),
        }),
        {}
      )
    ),

    title: Yup.object().shape(
      languages.reduce(
        (schema: any, lang: any) => ({
          ...schema,
          [lang]: Yup.string().required(`titleErr`),
        }),
        {}
      )
    ),
  })
}
export const MenuSchemaWithLink = (languages: any) => {
  return Yup.object().shape({
    sort: Yup.number().required('sortErr').min(0, 'minmamSortErr'),
    // parent_id: Yup.number().required('parent is required').min(1, 'parent is required'),
    position: Yup.string().required('positionErr').min(2, 'positionErr'),

    title: Yup.object().shape(
      languages.reduce(
        (schema: any, lang: any) => ({
          ...schema,
          [lang]: Yup.string().required(`titleErr`),
        }),
        {}
      )
    ),
  })
}
