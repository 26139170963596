import {Stack} from '@mui/material'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ChangePackage from '../subscriptions/ChangePackage'

export default function WarperBackages({children, backageKey, width}: any) {
  const {myPackage} = useSelector((state: RootState) => state.vender)
  console.log(myPackage['BULK_DISCOUNT_NUM'], backageKey, 'myPackagemyPackage')

  return <Stack>{myPackage[backageKey] ? children : <ChangePackage width={width} />}</Stack>
}
