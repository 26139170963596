import React from 'react'
import MainBtn from '../../customTable/MainBtn'
import {Stack} from '@mui/material'

export default function RowBtns() {
  return (
    <tr className='fw-bold text-muted '>
      <td></td>
      <td>
        <Stack alignItems={'center'}>
          <MainBtn br='8px' width='101px' bgcolor='#0BB783' text='getStart' />
        </Stack>
      </td>
      <td>
        <Stack alignItems={'center'}>
          <MainBtn br='8px' width='101px' bgcolor='#009EF7' text='getStart' />
        </Stack>
      </td>
      <td>
        <Stack alignItems={'center'}>
          <MainBtn br='8px' width='101px' bgcolor='#FFA800' text='getStart' />
        </Stack>
      </td>
      <td>
        <Stack alignItems={'center'}>
          <MainBtn br='8px' width='101px' bgcolor='#D71B22' text='getStart' />
        </Stack>
      </td>
    </tr>
  )
}
