import React, {useState} from 'react'
import {Stack, Typography} from '@mui/material'

import Table from './Table'
export default function NewSubCategoryTable() {
  const [rows, setRows] = useState<any>([])

  return (
    <Stack>
      <Table rows={rows} setRows={setRows} />
    </Stack>
  )
}
// var(--bs-body-bg)
