import React, {useState} from 'react'
import {ActionsCell} from '../../componet/Menu'
import {Stack} from '@mui/material'
import IBanner from '../../interface/IBanner'
import Switch from '../../componet/Toastr/Switch'
import Banner from '../../Api/banner'
import Edit from './Edit'
import Manufacturers from '../../Api/manufacturers'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../customTable/SimpleLoading'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setIsLoading,
  setNum,
  setAdded,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
  setNum: any
  setAdded: any
}) {
  const [state, setState] = useState<any>(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelEdit = () => {
    setOpen(true)
  }
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Manufacturers.putStatus(
      item.id,
      {status: state === 1 ? 0 : 1},
      () => {
        setRows(
          [
            ...rows.filter((e: any) => e.id != item.id),
            {...editItem, status: state === 1 ? 0 : 1},
          ].sort((a, b) => a.id - b.id)
        )
        setState(state === 1 ? 0 : 1)
      },
      setIsLoadingStatus
    )
  }
  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const handelDelete = async () => {
    setAdded(!true)

    Manufacturers.destroy(
      item.id,
      () => {
        setRows((prev: any) => prev.filter((e: any) => e.id !== item.id))
        setAdded(true)
        setNum((prev: any) => prev - 1)
      },
      setIsLoadingDelete
    )

    // setDeleted(false)
  }
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td style={{textAlign: 'center'}}>
              <Stack width={'50px'} borderRadius={'8px'} height={'50px'}>
                <img
                  style={{width: '100%', height: '100%', borderRadius: '8px'}}
                  src={editItem.image}
                  alt=''
                />
              </Stack>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>
                {editItem.name &&
                  (editItem.name[lang] || editItem.name[defLang]) &&
                  (editItem.name[lang] || editItem.name[defLang]).slice(0, 15)}{' '}
                {editItem.name &&
                  (editItem.name[lang] || editItem.name[defLang]) &&
                  (editItem.name[lang] || editItem.name[defLang]).length > 15 &&
                  '...'}
              </span>
            </td>
            <td style={{textAlign: 'start'}}>
              {isLoadingStatus ? (
                <SimpleLoading />
              ) : (
                <Switch
                  name='status'
                  value={state === 1 ? true : false}
                  handleChange={
                    store.permissions &&
                    store.permissions.includes('manufacturers_update') &&
                    handleStatusChange
                  }
                />
              )}{' '}
            </td>{' '}
            <td style={{textAlign: 'start'}}>
              {isLoadingDelete ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={
                    store.permissions &&
                    store.permissions.includes('manufacturers_update') &&
                    handelEdit
                  }
                  onDelete={
                    store.permissions &&
                    store.permissions.includes('manufacturers_delete') &&
                    handelDelete
                  }
                  onView={null}
                />
              )}
            </td>
          </tr>
          <Edit
            setRows={setRows}
            rows={rows}
            setItem={setEditItem}
            item={editItem}
            open={open}
            setOpen={setOpen}
          />
        </>
      )}
    </>
  )
}
