import * as React from 'react'
import Dialog from '@mui/material/Dialog'

import AddForm from './AddForm'
import EditForm from './EditForm'
import ICuopon from '../../../interface/ICuopon'
import SimpleLoading from '../../customTable/SimpleLoading'
import Loading from '../../customTable/LoadingForm'
import {Stack} from '@mui/material'

export default function EditCoupon({
  open,
  setOpen,
  cuopon,
  setEdit,
  edit,
  rows,
  setRows,
  isLoading,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  cuopon: ICuopon
  setEdit: React.Dispatch<React.SetStateAction<ICuopon>>
  edit: ICuopon
  rows: any
  setRows: any
  isLoading: any
}) {
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {isLoading ? (
          <Stack position='relative' width='250px' height={'250px'}>
            <Loading />
          </Stack>
        ) : (
          <EditForm
            rows={rows}
            setRows={setRows}
            edit={edit}
            setEdit={setEdit}
            cuopon={cuopon}
            setOpen={setOpen}
          />
        )}
      </Dialog>
    </div>
  )
}
