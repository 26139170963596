import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {
  Stack,
  Typography,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import i18n from '../../../i18n'
import {actions} from '../../store/vender/Auth/auth.slice'

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:before': {
            borderBottom: 'none !important',
            background: 'red',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: 'var(--bs-body-bg)',
        },
      },
    },
  },
})

export default function SelectLangs({
  option,
  value,
  color,
}: {
  option: any
  value?: any
  color?: string
}) {
  const [selectedOption, setSelectedOption] = useState<any>(value)
  const dispatch = useDispatch()

  const handleChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = option.find(
      (item: any) => item.id === selectedId || item.value === selectedId
    )

    setSelectedOption(selectedItem)
  }

  return (
    <Stack position={'relative'}>
      <ThemeProvider theme={theme}>
        <FormControl className='select-lang' variant='standard' sx={{m: 1, minWidth: 80}}>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            sx={{
              // border: '1px solid var(--bs-gray-300)',
              color: ' var(--bs-text-dark)',
              '&.css-rnbnro-MuiInputBase-root-MuiInput-root-MuiSelect-root:before': {
                borderBottom: 'none !important',
              },
              // borderRadius: '24px',
              width: '100%',
            }}
            value={selectedOption && (selectedOption.id || selectedOption.value)}
            onChange={handleChange}
          >
            {option.map((e: any) => (
              <MenuItem
                onClick={() => {
                  localStorage.setItem('lang', JSON.stringify(e))
                  dispatch(actions.setLang(e.lang))

                  i18n.changeLanguage(e.lang)
                }}
                sx={{color: ' var(--bs-text-dark)'}}
                key={e.id}
                value={e.id}
              >
                <Stack
                  gap='10px'
                  sx={{'&.css-1auam8d-MuiStack-root': {width: '80%'}}}
                  direction={'row'}
                >
                  <img className='w-15px h-15px rounded-1 ms-2' src={e.flag} alt='' />
                  <Typography>{e.name}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
      <Box width={'100%'} bottom={'8px'} height={'3px'} bgcolor={color} position={'absolute'}></Box>
    </Stack>
  )
}
