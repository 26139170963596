import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ICuopon from '../interface/ICuopon'
import ApisMethods from './apisMethods'

const Coupons = {
  get: async (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    let url = `/vendor/coupons?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  put: (id: number | undefined, data: ICuopon, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/coupons/status/${id}`, data, callback, setIsLoading)
  },
  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/coupons`, data, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/coupons/${id}`, callback, setIsLoading)
  },
  getDitals: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/coupons/${id}`, callback, setIsLoading)
  },
  putDetails: (data: any, id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/coupons/${id}`, data, callback, setIsLoading)
  },
}
export default Coupons
