import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Stack, Typography} from '@mui/material'
import TitlePage from '../customTable/TitlePage'
import {KTSVG} from '../../../_metronic/helpers'
import Table from './Table'
import Create from './Create'
import IBanner from '../../interface/IBanner'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import WarperBackages from '../customTable/WarperBackages'
export default function Banner() {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const bannerInfo = {
    title: t('titleBanner'),
    home: t('home'),
    linkHome: '/',
  }
  const [rows, setRows] = useState<IBanner[]>([])
  const [added, setAdded] = useState(false)
  const [total, setTotal] = useState(1)
  const [num, setNum] = useState(0)

  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  return (
    <WarperBackages backageKey='BANNER'>
      <Stack>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <TitlePage info={bannerInfo} />
          <Stack gap={'10px'} direction={'row'}>
            {store.permissions && store.permissions.includes('banners_create') && (
              <Create setNum={setNum} num={num} setAdded={setAdded} rows={rows} setRows={setRows} />
            )}
          </Stack>
        </Stack>
        <Table
          setTotal={setTotal}
          total={total}
          setAdded={setAdded}
          added={added}
          rows={rows}
          setRows={setRows}
          setNum={setNum}
        />
      </Stack>
    </WarperBackages>
  )
}
