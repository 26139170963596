import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const PageApi = {
  get: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    //
    let url = `/vendor/pages?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  getNames: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/pages/get/name-link`, callback, setIsLoading)
  },
  putStatus: (id: number | undefined, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/pages/status/${id}`, {status: status}, callback, setIsLoading)
  },

  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/pages`, data, callback, setIsLoading)
  },
  put: (id: number | undefined, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/pages/${id}`, data, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/pages/${id}`, callback, setIsLoading)
  },
  getDitals: (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/pages/edit/${id}`, callback, setIsLoading)
  },
  putDetails: (value: any, id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/pages/${id}`, value, callback, setIsLoading)
  },
}
export default PageApi
