import {useTranslation} from 'react-i18next'

export type Language = any

export const languages: Array<Language> = JSON.parse(localStorage.getItem('langs') || 'null') || [
  'en',
]

export type TInputType = 'single_select' | 'multi_select' | 'date' | 'text' | 'file'

export const optionTypes: Array<{
  label: string
  value: TInputType
}> = [
  {label: 'single_select', value: 'single_select'},
  {label: 'multi_select', value: 'multi_select'},
  {label: 'date', value: 'date'},
  {label: 'text', value: 'text'},
  {label: 'file', value: 'file'},
]

export type ArrangementOrder = 'asc' | 'desc'

export type KeyedObject = {
  [key: string]: string | number | KeyedObject | any
}

export type GetComparator = (
  o: ArrangementOrder,
  o1: string
) => (a: KeyedObject, b: KeyedObject) => number

export interface HeadCell {
  id: string
  numeric: boolean
  label: string
  align: 'left' | 'right' | 'center'
}
