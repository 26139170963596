import {Stack} from '@mui/material'
import React from 'react'
import Label from '../customTable/Label'

export default function Card({data}: any) {
  return (
    <Stack
      width={{lg: 'calc(25% - 24px)', sm: 'calc(50% - 16px)', xs: '100%'}}
      minWidth={'226px'}
      p='16px 24px'
      border='1px solid #E8E8E8'
      borderRadius={'12px'}
      gap='24px'
    >
      <Label fw='600' color='#A1A5B7' text={data.name} />
      <Label fs='35px' fw='700' text={data.value} />
    </Stack>
  )
}
