import {FormikProps} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {fileListToBase64} from '../../../utils/image'
import {InitialValues} from './validationSchema'
import ImgMsg from '../../customTable/ImgMsg'

type Props = {
  formik: FormikProps<InitialValues>
}

export const IamgeSection = ({formik}: Props) => {
  return (
    <div className='card-body text-center pt-0'>
      <div
        className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
        data-kt-image-input='true'
      >
        <div
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          className='image-input-wrapper w-150px h-150px'
        >
          {formik.values.image ? (
            <img
              src={
                typeof formik.values.image === 'object'
                  ? URL.createObjectURL((formik.values.image as any)[0])
                  : formik.values.image
              }
              alt='User Avatar'
              className='w-150px h-150px'
              style={{maxWidth: '100%', maxHeight: '100%'}}
            />
          ) : (
            <img
              src={toAbsoluteUrl('/media/avatars/img.svg')}
              alt='user-info'
              className='w-100px h-100px'
            />
          )}
        </div>
        <label
          htmlFor='image'
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change image'
        >
          <i className='bi bi-pencil-fill fs-7'></i>
          <input
            id='image'
            type='file'
            multiple={false}
            style={{display: 'none'}}
            accept='.jpg, .jpeg, .png, .svg'
            onChange={(e) => {
              const fileList = e.target.files as FileList

              if (e.target.files && e.target.files.length > 1) {
                formik.setFieldError('image', 'Please do not take more than 4 items')
                formik.setFieldTouched('image', true)
                e.target.value = '' // Clear the value of the input element
                return
              }
              fileListToBase64(fileList).then((base64String) => {
                formik.setFieldValue('image', base64String)
              })
            }}
            onFocus={(e) => {
              if (!formik.touched.image) {
                formik.setFieldTouched('image', true)
              }
            }}
            onBlur={(e) => {
              if (!formik.touched.image) {
                formik.setFieldTouched('image', true)
              }
            }}
          />
        </label>
        {/*  check  if  the  formik  image  is  found  then  show  x  icon  */}
        {formik.values.image && formik.values.image.length > 0 && (
          <span
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            title='Cancel image'
            onClick={() => {
              formik.setFieldValue('image', null)
            }}
          >
            <i className='bi bi-x fs-2'></i>
          </span>
        )}
      </div>
      <ImgMsg />
      {formik.errors.image && formik.touched.image && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors.image}</div>
        </div>
      )}
    </div>
  )
}
