import React, {useState, useRef, useEffect} from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {addDays, format} from 'date-fns'
import {DateRange, DateRangePicker} from 'react-date-range'
import HomeApi from '../../../Api/home'
import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

function DateInputChart({setCharts}: any) {
  const [showPicker, setShowPicker] = useState(false)
  const currentDate = new Date()

  const startDate = new Date()
  const endDate = new Date()
  startDate.setDate(currentDate.getDate() - 10)
  endDate.setDate(currentDate.getDate() + 10)

  const initialSelectedRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  const [selectedRange, setSelectedRange] = useState(initialSelectedRange)
  const [isLoading, setIsLoading] = useState(false)
  const getAnalysis = async (date: any) => {
    let dataDate = date
    let startDate = new Date(date.start)
    let endDate = new Date(date.end)

    if (startDate.getTime() === endDate.getTime()) {
      startDate.setDate(startDate.getDate() - 1)

      dataDate = {
        ...date,
        start: startDate.toISOString().split('T')[0],
      }
    }

    const data = await HomeApi.getChart(
      dataDate,
      (res: any) => {
        setCharts(res.data)
      },
      setIsLoading
    )
  }

  const store = useSelector((state: RootState) => state.vender)

  const pickerRef = useRef<any>(null)

  const handleDateSelect = (ranges: any) => {
    setSelectedRange(ranges.selection)
  }

  const togglePicker = () => {
    setShowPicker(!showPicker)
  }
  useEffect(() => {
    getAnalysis({
      start: format(selectedRange.startDate, 'y-MM-dd'),
      end: format(selectedRange.endDate, 'y-MM-dd'),
    })
  }, [])
  const handlePrintRange = async () => {
    const startDateFormatted = format(selectedRange.startDate, 'y-MM-dd')
    const endDateFormatted = format(selectedRange.endDate, 'y-MM-dd')
    getAnalysis({
      start: format(selectedRange.startDate, 'y-MM-dd'),
      end: format(selectedRange.endDate, 'y-MM-dd'),
    })

    setShowPicker(false)
  }

  const handleOutsideClick = (e: any) => {
    if (pickerRef.current && !pickerRef.current.contains(e.target)) {
      setShowPicker(false)
      setSelectedRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      })
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const {t} = useTranslation()

  return (
    <div style={{position: 'relative'}}>
      <button
        style={{
          display: 'flex',
          gap: '5px',
          border: 'none',
          alignItems: 'center',
          height: '46px',
          justifyContent: 'center',
          width: '120px',
          borderRadius: '8px',
          background: '#efeef0',
        }}
        onClick={togglePicker}
      >
        <Typography>{format(selectedRange.startDate, 'y-MM-dd')}</Typography>
        <img src='./media/avatars/15.svg' alt='' />
      </button>
      <Stack
        style={{
          position: 'absolute',
          right: store.isRtl === 0 ? '0' : 'auto',
          left: store.isRtl === 1 ? '0' : 'auto',
          zIndex: '100',
          background: 'var(--bs-app-footer-bg-color)',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
        dir='ltr'
      >
        {' '}
        {showPicker && (
          <div style={{background: 'var(--bs-body-bg)'}} ref={pickerRef}>
            <Stack sx={{display: {md: 'block', xs: 'none'}}}>
              <DateRangePicker
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[selectedRange]}
                direction='horizontal'
                preventSnapRefocus={true}
                calendarFocus='backwards'
              />
            </Stack>
            <Stack sx={{display: {md: 'none', xs: 'block'}}}>
              <DateRange
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[selectedRange]}
                // direction='horizontal'

                // preventSnapRefocus={true}
                calendarFocus='backwards'
              />
            </Stack>

            <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'flex-start'}>
              <button
                style={{
                  display: 'flex',
                  gap: '5px',
                  border: 'none',
                  alignItems: 'center',
                  height: '46px',
                  justifyContent: 'center',
                  width: '120px',
                  borderRadius: '8px',
                  margin: '20px 0',
                  // background: '#3d91ff',
                  // color: 'white',
                }}
                onClick={() => {
                  setSelectedRange({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                  })
                  setShowPicker(false)
                }}
              >
                {t('cancel')}
              </button>
              <button
                style={{
                  display: 'flex',
                  gap: '5px',
                  border: 'none',
                  alignItems: 'center',
                  height: '46px',
                  justifyContent: 'center',
                  width: '120px',
                  borderRadius: '8px',
                  margin: '20px',
                  background: '#3d91ff',
                  color: 'white',
                }}
                onClick={handlePrintRange}
              >
                {t('apply')}
              </button>
            </Stack>
          </div>
        )}
      </Stack>
    </div>
  )
}

export default DateInputChart
