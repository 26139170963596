import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function VariationsHeadTable() {
  const {t} = useTranslation()

  return (
    <Stack direction={'row'} width='100%'>
      <Stack
        width={'76px'}
        sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
      ></Stack>
      <Stack width={'10vw'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'>{t('image')}</Typography>
      </Stack>
      <Stack width={'10vw'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'>{t('options')} </Typography>
      </Stack>
      <Stack width={'93px'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'>{t('quantity')} </Typography>
      </Stack>
      <Stack width={'70px'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'>{t('price')} </Typography>
      </Stack>{' '}
      <Stack width={'70px'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'> {t('weight')} </Typography>
      </Stack>{' '}
      <Stack width={'70px'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'>{t('height')} </Typography>
      </Stack>
      <Stack width={'70px'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography align='center'> {t('width')} </Typography>
      </Stack>
      <Stack sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}} width={'70px'}>
        <Typography align='center'> {t('length')}</Typography>
      </Stack>
      <Stack width={'69px'}></Stack>
    </Stack>
  )
}
