import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'
import LabelWithValue from './LabelWithValue'

export default function Detailes({item}: any) {
  return (
    <Stack p='40px'>
      <Stack borderBottom={'1px dashed #a9a9a9'} pb='40px'>
        <Label fw='500' fs='24px' text='invoiceDetails' />
      </Stack>
      <Stack gap='35px' borderBottom={'1px dashed #a9a9a9'} p='40px 0'>
        <LabelWithValue value={item.id} label='InvoiceNb' />
        <LabelWithValue value={item.type} label='type' />
      </Stack>
      <Stack gap='35px' borderBottom={'1px dashed #a9a9a9'} p='40px 0'>
        <LabelWithValue value={item.fees} label='fees' />
        <LabelWithValue value={item.subtotal} label='subTotal' />
        <LabelWithValue value={item.wallet_balance_before} label='amount' />
        <LabelWithValue value={item.wallet_balance_after} label='balance' />
      </Stack>
      <Stack
        alignItems={'center'}
        pt='40px'
        gap='5px'
        justifyContent={'flex-end'}
        direction={'row'}
      >
        <Label fw='600' fs='16px' text='total' /> :{' '}
        <Label fw='700' fs='20px' text={`${item.total}${localStorage.getItem('currency')}`} />
      </Stack>
    </Stack>
  )
}
