import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'

import Media from './media'
import Page from './page'
import Form from './page/Form'
import Menu from './menu'
import FormMenu from './menu/Form'
import Error403 from '../errors/components/Error403'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ApplicationMaker from './applicationMaker'
import WarperBackages from '../customTable/WarperBackages'

const StoreManagement = () => {
  const {t} = useTranslation()
  const categoryBreadCrumbs: Array<PageLink> = [
    {
      title: t('home'),
      path: '/storeManagement/media',
      isSeparator: false,
      isActive: false,
    },
  ]
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='media'
          element={
            store.permissions && store.permissions.includes('medias_read') ? (
              <>
                <WarperBackages backageKey='MEDIA_NUM'>
                  {' '}
                  <Media />
                </WarperBackages>

                {/* <PageTitle breadcrumbs={categoryBreadCrumbs}>{t('media')}</PageTitle> */}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='page'
          element={
            store.permissions && store.permissions.includes('pages_read') ? (
              <>
                <Page />
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='menu'
          element={
            store.permissions && store.permissions.includes('menus_read') ? (
              <>
                <WarperBackages backageKey='MENU_NUM'>
                  <Menu />
                </WarperBackages>
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='create-page'
          element={
            store.permissions && store.permissions.includes('pages_create') ? (
              <>
                <Form />
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='edit-page/:id'
          element={
            store.permissions && store.permissions.includes('pages_update') ? (
              <>
                <Form />
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='create-menu'
          element={
            store.permissions && store.permissions.includes('menus_create') ? (
              <>
                {' '}
                <WarperBackages backageKey='MENU_NUM'>
                  <FormMenu />
                </WarperBackages>
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='edit-menu/:id'
          element={
            store.permissions && store.permissions.includes('menus_update') ? (
              <>
                <WarperBackages backageKey='MENU_NUM'>
                  <FormMenu />
                </WarperBackages>
              </>
            ) : (
              <Error403 />
            )
          }
        />
      </Route>
    </Routes>
  )
}

export default StoreManagement
