import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../../index'
import {FetchStatus} from '../../types'
import {
  addOptionAction,
  changeOptionStatusAction,
  createItemOptionAction,
  deleteOptionAction,
  getCategoryNames,
  getOptionAction,
  getOptionById,
  removeItemOptionAction,
  updateItemOptionAction,
  updateOptionAction,
} from './thunk'

import {IinitialState} from './types'

const initialState: IinitialState = {
  option: {
    data: [],
    pages: null,
  },
  catogory: [],
  optionDetails: {},
  status: FetchStatus.idle,
  error: null,
}

const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    //  ADD  NEW optionDetails WITHOUT  API
    addOptionDetails: (state, action) => {
      return {
        ...state,
        optionDetails: {
          ...state.optionDetails,
          option_details: state.optionDetails.option_details.concat({
            ...action.payload,
            id: 'created',
          }),
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptionAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.option = action.payload
      })
      .addCase(getOptionAction.rejected, (state, action) => {
        state.status = FetchStatus.error
        state.error = action.payload as string
      })
      .addCase(getOptionAction.pending, (state, _) => {
        state.status = FetchStatus.pending
      })
      .addCase(getCategoryNames.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.catogory = action.payload
      })
      .addCase(getCategoryNames.rejected, (state, action) => {
        state.status = FetchStatus.error
        state.error = action.payload as string
      })
      .addCase(getCategoryNames.pending, (state, _) => {
        state.status = FetchStatus.pending
      })
      .addCase(changeOptionStatusAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
      })
      //    update  the  option catolog
      .addCase(updateOptionAction.fulfilled, (state, action) => {
        state.status = FetchStatus.completed
        state.option.data = state.option.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      })
    //   addgetOptionAction
    builder.addCase(addOptionAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })
    builder.addCase(addOptionAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    builder.addCase(addOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.option.data.push(action.payload)
    })
    //   dealtegetOptionAction

    builder.addCase(deleteOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.option = action.payload
    })

    // builder.addCase(deleteOptionAction.rejected, (state, action) => {
    //   state.status = FetchStatus.error
    //   state.error = action.payload as string
    // })

    // builder.addCase(deleteOptionAction.pending, (state, _) => {
    //   state.status = FetchStatus.pending
    // })

    //       getOptionById
    builder.addCase(getOptionById.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.optionDetails = action.payload
    })
    builder.addCase(getOptionById.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })
    builder.addCase(getOptionById.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    //     removeItemOptionAction
    builder.addCase(removeItemOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.optionDetails = state.optionDetails.optionDetails.filter((item: any) => {
        return item.id !== action.payload.id
      })
    })

    // updateItemOptionAction
    builder.addCase(updateItemOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed

      //  state.optionDetails = state.optionDetails.option_details.map((item: any) => {
      //    if (item.id === action.payload.id) {

      //      return action.payload
      //    }
      //    return item
      //  })
    })
    //     createItemOptionAction
    builder.addCase(createItemOptionAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.optionDetails = {
        ...state.optionDetails,
        option_details: state.optionDetails.option_details.map((x: any) => {
          if (x.id === 'created') {
            return action.payload
          }
          return x
        }),
      }
    })
  },
})

export default optionSlice.reducer

export const {addOptionDetails} = optionSlice.actions

export const selectoption = (state: RootState) => state.option
