import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import UploadImg from '../customTable/UploadImg'
import ErrorMsg from '../customTable/ErrorMsg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import {bannerSchema} from '../../yup/BannerShcema'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ValidationFun from '../../yup/ValidationFun'

export default function EditForm({
  setOpen,
  open,
  item,
  setItem,
  setRows,
  rows,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  item: IBanner
  setItem: React.Dispatch<React.SetStateAction<IBanner>>
  setRows: any
  rows: any
}) {
  const [base64Image, setBase64Image] = React.useState(item.image)
  const [banner, setBanner] = useState({
    ...item,
    type: item.type === 'banner' ? 1 : 2,
  })
  const {t} = useTranslation()
  console.log(item.type)

  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const [errors, setErrors] = useState({})
  const saveChange = async () => {
    ValidationFun(
      {
        ...banner,
        type: !banner.type ? 'slider' : banner.type === 1 ? 'banner' : 'new_arrival',
        image: base64Image,
      },
      bannerSchema,
      setErrors,
      (value: any) => {
        Banner.put(
          item.id,
          {
            ...banner,
            type: banner.type === 2 ? 'sub_banner' : 'banner',
            image: base64Image.includes('data') ? base64Image : '',
          },
          (res: any) => {
            setItem(res)

            // setBase64Image('')
            setMsgErr('')
            setOpen(false)
            setBase64Image(res.image)
            setBanner({
              ...res,
              type: res.type === 'sub_banner' ? 2 : 1,
            })
            setRows(
              [
                ...rows.filter((e: any) => e.id != item.id),
                {
                  ...res,
                  type: res.type === 'banner' ? 2 : 1,
                },
              ].sort((a, b) => b.id - a.id)
            )
          },
          setUsLoading
        )
      }
    )
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {t('editBanner')}
      </Typography>
      {open && (
        <Stack
          mt='25px'
          width='150px'
          height={'150px'}
          sx={{
            boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
            borderRadius: '7px',
          }}
        >
          <UploadImg
            errors={errors}
            open={open}
            base64Image={item.image}
            setBase64Image={setBase64Image}
          />
          <ErrorMsg msgErr={msgErr} name='Image' />
        </Stack>
      )}

      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('title')}
          </Typography>
          <RedStart />
        </Stack>
        <Box width='100%'>
          <InputType errors={errors} name={'title'} change={setBanner} value={banner} type='text' />
        </Box>
        <ErrorMsg msgErr={msgErr} name='title' />
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('type')}
          </Typography>
          {/* <RedStart /> */}
        </Stack>
        <Box width='100%'>
          <MySelect
            option={[
              {name: t('banner'), id: 1},
              {name: t('subBanner'), id: 2},
            ]}
            values={banner}
            setValues={setBanner}
            name='type'
            value={{id: item.type === 'banner' ? 1 : 2}}
          />{' '}
        </Box>
        <ErrorMsg msgErr={msgErr} name='Type' />
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('sort')}
          </Typography>
          <RedStart />
        </Stack>
        <Box width='100%'>
          <InputType
            errors={errors}
            name={'sort'}
            change={setBanner}
            value={banner}
            type='number'
          />
        </Box>
        <ErrorMsg msgErr={msgErr} name='sort' />
      </Stack>
      <SaveButton text={t('saveChanges')} submit={saveChange} isLoading={false} setOpen={setOpen} />
      {isLoading && <Loading />}
    </Container>
  )
}
