import { SubCatogory } from './../../../modules/options/sub-catogory/index';
import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../../index'
import {FetchStatus} from '../../types'
import {
  categoriesAction,
  createCategoryAction,
  deleteCategoryAction,
  getCategoryById,
  getCategoryName,
  getCategoryWithName,
  getCategoryWithNameAndSubCatogory,
  getSubCategoryById,
  getSubCategoryList,
  updateCategoryAction,
  updateSubCategoryAction,
} from './thunk'
import {IinitialState} from './types'

const initialState: IinitialState = {
  categories: {
    data: [],
    pages: null,
  },
  category: {},
  subCatogory: {},
  subCatogories:{
   data: [],
    pages: null,
},

  categoryDetails: {},

  nameCategory: [],
  status: FetchStatus.idle,
  error: null,
}

const CategoriesSlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //   categoriesAction
    builder.addCase(categoriesAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.categories = action.payload
    })

    builder.addCase(categoriesAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(categoriesAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })

    //      categoryDetailsAction
    builder.addCase(deleteCategoryAction.fulfilled, (state, action) => {
      //  state.status = FetchStatus.completed
      state.categories.data = action.payload.data
    })

    //     builder.addCase(deleteCategoryAction.rejected, (state, action) => {
    //       state.status = FetchStatus.error
    //       state.error = action.payload as string
    //     })

    //     builder.addCase(deleteCategoryAction.pending, (state, _) => {
    //       state.status = FetchStatus.pending
    //     })
    //     add  catoegory  to  store  after  create  new  category and  add  to  server
    builder.addCase(createCategoryAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.categories.data.push(action.payload)
    })

    builder.addCase(createCategoryAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(createCategoryAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })

    //     categoryDetailsAction
    //     builder.addCase(categoryDetailsAction.fulfilled, (state, action) => {
    //       state.status = FetchStatus.completed
    //       state.category = action.payload
    //     })

    //     builder.addCase(categoryDetailsAction.rejected, (state, action) => {
    //       state.status = FetchStatus.error
    //       state.error = action.payload as string
    //     })

    //     builder.addCase(categoryDetailsAction.pending, (state, _) => {
    //       state.status = FetchStatus.pending
    //     })

    //       get   name  category
    builder.addCase(getCategoryName.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.nameCategory = action.payload
    })

    //    get  catofry by id
    builder.addCase(getCategoryById.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    //    for  full  details  category
    builder.addCase(getCategoryById.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.category = action.payload
    })
    builder.addCase(getSubCategoryById.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })
    //    for  full  details  category
    builder.addCase(getSubCategoryById.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.subCatogory = action.payload
    })
    // getSubCategoryList  
     builder.addCase(getSubCategoryList.fulfilled, (state, action) => {
       state.status = FetchStatus.completed
       state.subCatogories = action.payload
    })

    //      update subcategory
    builder.addCase(updateSubCategoryAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    builder.addCase(updateSubCategoryAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.subCatogory = action.payload
    })
    //       update  category

    //       udate
    builder.addCase(updateCategoryAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    builder.addCase(updateCategoryAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed

      state.categories.data =action.payload
    })
    // getCategoryWithNameAndSubCatogory
    builder.addCase(getCategoryWithNameAndSubCatogory.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.categoryDetails = {
        ...state.categoryDetails,
        ...action.payload,
      }
    })
    builder.addCase(getCategoryWithNameAndSubCatogory.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })
    //   getCategoryWithName
    builder.addCase(getCategoryWithName.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    builder.addCase(getCategoryWithName.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.categoryDetails = {
        ...state.categoryDetails,
        ...action.payload,
      }
    })
  },
})

export default CategoriesSlice.reducer

export const selectVender = (state: RootState) => state.category
export const isUserLoggedIn = (state: RootState) => Boolean(state?.vender?.user)
