import React, {useState, useEffect} from 'react'
import Label from './Label'
import {Stack} from '@mui/material'

export default function CountdownTimer({initialSeconds, setTime}: any) {
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds: any) => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(intervalId) // تنظيف الـinterval عند تدمير الـcomponent
    }
  }, [seconds])

  return (
    <div>
      {seconds > 0 ? (
        <Label color='#A5A2A2' text={`${seconds} s`} />
      ) : (
        <Stack
          onClick={() => {
            setSeconds(180)
          }}
          sx={{cursor: 'pointer'}}
          gap='5px'
          alignItems={'center'}
          direction={'row'}
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.3332 1.3335C13.1564 1.3335 12.9869 1.40373 12.8618 1.52876C12.7368 1.65378 12.6666 1.82335 12.6666 2.00016V3.25303C11.7169 2.32151 10.51 1.69576 9.20137 1.45643C7.89279 1.2171 6.54253 1.37516 5.3246 1.91025C4.10667 2.44533 3.07691 3.33291 2.36803 4.45859C1.65916 5.58427 1.30365 6.89646 1.34734 8.22603C1.39104 9.5556 1.83192 10.8416 2.61317 11.9183C3.39442 12.995 4.48023 13.8131 5.73066 14.2671C6.98109 14.721 8.33882 14.7901 9.62887 14.4654C10.9189 14.1407 12.0821 13.437 12.9686 12.4452C13.0846 12.3132 13.1438 12.1407 13.1333 11.9652C13.1228 11.7898 13.0435 11.6256 12.9125 11.5084C12.7816 11.3912 12.6097 11.3304 12.4342 11.3393C12.2586 11.3482 12.0937 11.426 11.9753 11.5558C11.2481 12.3741 10.2871 12.9492 9.22226 13.2032C8.15744 13.4573 7.04027 13.378 6.02201 12.9761C5.00375 12.5742 4.13357 11.8691 3.52928 10.9563C2.92499 10.0435 2.61578 8.96705 2.6435 7.8727C2.67123 6.77834 3.03455 5.71894 3.68429 4.8379C4.33402 3.95687 5.23879 3.29676 6.2761 2.94694C7.31341 2.59713 8.43315 2.5745 9.48374 2.88212C10.5343 3.18975 11.465 3.81276 12.1498 4.66683H9.9999C9.82309 4.66683 9.65352 4.73707 9.5285 4.86209C9.40347 4.98712 9.33323 5.15669 9.33323 5.3335C9.33323 5.51031 9.40347 5.67988 9.5285 5.8049C9.65352 5.92993 9.82309 6.00016 9.9999 6.00016H13.3332C13.51 6.00016 13.6796 5.92993 13.8046 5.8049C13.9297 5.67988 13.9999 5.51031 13.9999 5.3335V2.00016C13.9999 1.82335 13.9297 1.65378 13.8046 1.52876C13.6796 1.40373 13.51 1.3335 13.3332 1.3335Z'
              fill='#A5A2A2'
            />
          </svg>

          <Label color='#A5A2A2' text={`sendAgain`} />
        </Stack>
      )}
    </div>
  )
}
