import {Stack, Typography} from '@mui/material'
import React from 'react'
import CardImage from './CardImage'
export default function CardsImage({setting, setSeeting}: any) {
  return (
    <Stack
      mt='30px'
      direction={'row'}
      gap={{xs: '22px', sm: '22px'}}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
    >
      <Stack
        sx={{
          width: {xs: '100%', sm: 'calc(50% - 11px)', md: 'calc(32% - 8px)'},
        }}
      >
        <CardImage
          setSeeting={setSeeting}
          text='FRONT HEADER LOGO'
          name='header_logo'
          setting={setting}
        />
        <Typography sx={{textAlign: 'center', marginTop: '18px'}}>FRONT HEADER LOGO</Typography>
      </Stack>
      <Stack
        sx={{
          width: {xs: '100%', sm: 'calc(50% - 11px)', md: 'calc(32% - 8px)'},
        }}
      >
        <CardImage
          setSeeting={setSeeting}
          text='FRONT FOOTER LOGO'
          name='footer_logo'
          setting={setting}
        />
        <Typography sx={{textAlign: 'center', marginTop: '18px'}}>FRONT FOOTER LOGO</Typography>
      </Stack>
      <Stack
        sx={{
          width: {xs: '100%', sm: '100%', md: 'calc(32% - 8px)'},
        }}
      >
        <CardImage setSeeting={setSeeting} text='app LOGO' name='app_logo' setting={setting} />

        <Typography sx={{textAlign: 'center', marginTop: '18px'}}>APP LOGO</Typography>
      </Stack>
    </Stack>
  )
}
