import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {IconMenu} from '../../../../helpers/components/IconMenu'

type Props = {
  to: string
  title: string
  icon?: any
  fontIcon?: string
  hasBullet?: boolean
  activeMenu?: boolean
  link?: any
  activeIcon?: any
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  activeMenu,
  link,
  activeIcon,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      {/* {pathname.includes(link) && 5555} */}
      <span
        style={{
          display: 'flex',
          gap: '10px',
          width: activeMenu ? '200px' : 'auto',
          alignItems: 'end',
          color: '#fff',
          // background: pathname.includes(link) ? ' rgba(216, 25, 34, 0.1)' : 'none',
        }}
        className='menu-link'
      >
        {/* {hasBullet && (
          <span className='menu-bullet'>
            <span
              style={{background: pathname.includes(link) ? 'red' : 'var(--bs-text-gray-700) '}}
              className='bullet bullet-dot'
            ></span>
          </span>
        )} */}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span
            style={{color: isActive ? 'red !important' : 'var(--bs-text-gray-700)  !important '}}
            // className='menu-icon'
          >
            {typeof icon === 'string' ? (
              <IconMenu
                path={pathname.includes(link) ? activeIcon || '' : icon}
                className='svg-icon-2'
              />
            ) : pathname.includes(link) ? (
              activeIcon || ''
            ) : (
              icon
            )}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        {activeMenu && <span className='menu-title'>{title}</span>}
        <span
          style={{color: pathname.includes(link) ? 'red' : 'auto'}}
          className='menu-arrow'
        ></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
