import React, {useState} from 'react'

export default function LangSelect({
  name,
  values,
  option,
  disabled,
  value,
  setValues,
  selectedLang,
  setSelectedLang,
}: {
  name: string
  values: any
  option: any
  disabled?: boolean | undefined
  value?: any
  setValues: any
  selectedLang: any[]
  setSelectedLang: React.Dispatch<React.SetStateAction<any[]>>
}) {
  const [selectedOption, setSelectedOption] = useState<any>(value)
  console.log(option)

  const handleChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = option.find(
      (item: any) => +item.id == +selectedId || +item.value == +selectedId
    )
    console.log(selectedItem)

    setSelectedOption(selectedItem)
    setSelectedLang([...selectedLang, +event.target.value])
    setValues({...values, [`${name}`]: +event.target.value})

    // values[`${name}`] = +event.target.value
  }

  return (
    <select
      disabled={disabled}
      style={{
        padding: '12.0625px 13px 12.5px',
        background: 'var(--bs-body-bg)',
        border: '1px solid var(--bs-gray-300)',
        color: ' var(--bs-text-dark)',
        borderRadius: '6.18px',
        width: '100%',
        // backgroundColor: 'var(--bs-gray-100)',
        // borderColor: 'var(--bs-gray-100)',
      }}
      value={''}
      onChange={handleChange}
    >
      <option value={''} disabled hidden>
        select
      </option>
      {option.map((e: any) => (
        <option key={e.code || e.value} value={+e.id || +e.value}>
          {e.lang || e.label}
        </option>
      ))}
    </select>
  )
}
