import {Box, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import CustomSelect from '../../../../customTable/CustomSelect'
import Table from './Table'
import Switch from '@mui/material/Switch'
import GroupedSelect from '../../../../customTable/SelectGroup'
import MySelect from '../../../../customTable/CustomSelectTest'
import {useParams} from 'react-router-dom'
import Product from '../../../../../Api/product'
import ErrorMsg from '../../../../customTable/ErrorMsg'
import Options from '../../../../../Api/options'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import DeletedMsg from '../../../../customTable/DeleteMsg'
import MainBtn from '../../../../customTable/MainBtn'

const label = {inputProps: {'aria-label': 'Switch demo'}}

// setIsCreate: React.Dispatch<React.SetStateAction<any>>
// setSelectedOption: React.Dispatch<React.SetStateAction<never[]>>
// setValues: React.Dispatch<React.SetStateAction<IOption>>
// setIsNew: React.Dispatch<React.SetStateAction<boolean>>
// optionsBtns: {id: number}[]
// setOptionsBtns: React.Dispatch<React.SetStateAction<{id: number}[]>>
// setLoadingOption: React.Dispatch<React.SetStateAction<boolean>>
// setOptionValue: React.Dispatch<React.SetStateAction<never[]>>

function ShowPotion({
  isCreate,
  selectedOption,
  setIsCreate,
  setOptionValue,
  optionValue,
  values,
  setValues,
  isNew,
  optionsBtns,
  setOptionsBtns,
  setIsActiveBtn,
  setActiveDataBtn,
  isActiveBtn,
  setIsDeleted,
  setLoadingOption,
  setIsNew,
  isActiveAddBtn,
  submit,
  setIsAddedOption,
  msgErr,
  setMsgErr,
  isLoading,
  setLoading,
  isAddedOption,
  setSelectedOption,
  setIsActiveAddBtn,
  setIsActive,
}: {
  isCreate: any
  setIsCreate: React.Dispatch<React.SetStateAction<any>>
  setOptionValue: React.Dispatch<React.SetStateAction<never[]>>
  setIsActiveBtn: React.Dispatch<React.SetStateAction<number>>
  values: any
  setValues: any
  isNew: boolean
  optionValue: never[]
  selectedOption: never[]
  optionsBtns: {id: number}[]
  setOptionsBtns: React.Dispatch<React.SetStateAction<{id: number}[]>>
  isActiveBtn: number
  setActiveDataBtn: React.Dispatch<React.SetStateAction<number>>
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>
  setLoadingOption: React.Dispatch<React.SetStateAction<boolean>>
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>
  isActiveAddBtn: any
  submit: any
  setIsAddedOption: any
  msgErr: any
  setMsgErr: any
  isLoading: any
  setLoading: any
  isAddedOption: any
  setSelectedOption: any
  setIsActiveAddBtn: any
  setIsActive: any
}) {
  // const [selected, setSelected] = useState<any>()
  const {id} = useParams()
  const {t} = useTranslation()

  const getOption = (ID: string | undefined, optionId: number) => {
    if (optionId) {
      setLoadingOption(true)

      Options.getOptionById(
        ID,
        optionId,
        (res: any) => {
          setIsDeleted(false)
          setLoadingOption(false)
          setIsCreate(res.data.option)

          setValues(res.data)
        },
        setLoadingOption
      )
    }
    // setOptionsBtns(optionsBtns.data.data)
  }
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const getAllOption = () => {
    Product.getOption(
      id,
      (res: any) => {
        setSelectedOption(res.data)
      },
      setIsLoadingOptions
    )
  }
  const [openDelete, setOpenDelete] = useState(false)
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  return (
    <Stack gap='24px'>
      {(isActiveAddBtn || optionsBtns.length > 0) && (
        <Stack>
          <Typography mb='9px' sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('option')}
          </Typography>
          <Box width='100%'>
            <GroupedSelect
              selected={isCreate}
              setSelected={setIsCreate}
              selectedOption={selectedOption}
              disabled={isNew}
              setOptionValue={setOptionValue}
              setIsAddedOption={setIsAddedOption}
            />
          </Box>
        </Stack>
      )}

      {isCreate && isCreate.id && (
        <>
          <Stack>
            <Typography mb='9px' sx={{fontSize: '16px', fontWeight: '500'}}>
              {t('required')}
            </Typography>
            <Box width='100%'>
              <MySelect
                option={[
                  {name: 'no', id: 0},

                  {name: 'yes', id: 1},
                ]}
                setValues={setValues}
                disabled={false}
                values={values}
                value={{id: values.is_require}}
                name='is_require'
              />
            </Box>
          </Stack>
          {(isCreate.input_type === 'single_select' || isCreate.input_type === 'multi_select') && (
            <>
              {isCreate.value_type !== 'text' && (
                <>
                  <Stack>
                    <Typography mb='9px' sx={{fontSize: '16px', fontWeight: '500'}}>
                      {t('existingone')}
                    </Typography>
                    <Box width='100%'>
                      <MySelect
                        option={[
                          {name: t('no'), id: 0},

                          {name: t('yes'), id: 1},
                        ]}
                        values={values}
                        value={{id: values.is_upload_image}}
                        name='is_upload_image'
                        setValues={setValues}
                        disabled={isNew || optionValue.length > 0 ? true : false}
                      />
                    </Box>
                  </Stack>
                </>
              )}
              {!isLoading && (
                <Table
                  setOptionValue={setOptionValue}
                  optionValue={optionValue}
                  type={isCreate.value_type}
                  option_id={isCreate.id}
                  values={values}
                  loadingOut={isLoading}
                />
              )}

              <ErrorMsg msgErr={msgErr} name='some' />

              {isCreate.value_type !== 'text' && (
                <>
                  {((values.is_upload_image !== 0 && isCreate.value_type === 'color') ||
                    isCreate.value_type === 'image') && (
                    <Stack gap={'22px'} alignItems={'center'} direction={'row'}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: 'var(--bs-body-color)',
                          lineHeight: '21px',
                        }}
                      >
                        {t('displayOptionValue')}
                      </Typography>
                      <Switch
                        onChange={(e) =>
                          setValues({
                            ...values,
                            is_main_image: values.is_main_image === 1 ? 0 : 1,
                          })
                        }
                        checked={values.is_main_image === 1 ? true : false}
                      />
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
          {isCreate && (
            <Box display={'flex'} gap='24px' justifyContent={'flex-end'}>
              <MainBtn
                isLoading={isLoadingDelete}
                width='auto'
                without
                text='deleteOption'
                handelSubmit={() => {
                  setOpenDelete(true)
                }}
              />

              <DeletedMsg
                isLoadingDelete={isLoadingDelete}
                open={openDelete}
                setOpen={setOpenDelete}
                onClick={() => {
                  if (isAddedOption) {
                    setIsDeleted(true)

                    Product.destroyOptionProductById(
                      optionsBtns[isActiveBtn].id,
                      id,
                      () => {
                        setOptionValue([])
                        setOptionsBtns(
                          optionsBtns.filter((e) => e.id !== optionsBtns[isActiveBtn].id)
                        )

                        if (optionsBtns.length === 1) {
                          setIsNew(false)
                          setIsCreate('')

                          setIsActiveBtn(-1)
                          setValues({
                            is_require: 1,
                            is_upload_image: 0,
                            is_main_image: 1,
                            option_id: null,
                            option: {},
                          })
                        } else if (optionsBtns.length === 2 && isActiveBtn === 0) {
                          setIsActiveBtn(0)

                          getOption(id, optionsBtns[1].id)
                          setIsDeleted(false)
                        } else {
                          setIsActiveBtn(isActiveBtn - 1)
                          getOption(id, optionsBtns[isActiveBtn - 1].id)
                          setIsDeleted(false)
                        }
                      },
                      setLoadingDelete
                    )
                  } else {
                    setIsActiveAddBtn(true)
                    setIsCreate('')
                    setIsActive(-1)
                    setValues({
                      is_require: 1,
                      is_upload_image: 0,
                      is_main_image: 1,
                      option_id: null,
                      option: {},
                    })
                    setIsNew(false)
                    setOptionValue([])
                    getAllOption()
                  }
                }}
              />
              <MainBtn
                width='auto'
                isLoading={isLoading}
                handelSubmit={() => {
                  submit(setLoading, values, setOptionValue, setMsgErr)
                  setIsAddedOption(true)
                }}
                type='submit'
                text='save'
              />
            </Box>
          )}
        </>
      )}
    </Stack>
  )
}

export default ShowPotion
