import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import ErrorMsg from '../../customTable/ErrorMsg'
import RedStart from '../../customTable/RedStart'
import InputType from '../../customTable/InputType'
import MySelect from '../../customTable/CustomSelectTest'
import SaveButton from '../../customTable/SaveButton'
import Banner from '../../../Api/banner'
import IBanner from '../../../interface/IBanner'
import {bannerSchema} from '../../../yup/BannerShcema'
import * as Yup from 'yup'
import Loading from '../../customTable/LoadingForm'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import iMedia from '../../../interface/iMedia'
import CheckBox from './CheckBox'
import UploadVideo from './UploadVideo'
import {MediaSchema} from '../../../yup/Media'
import Media from '../../../Api/media'
import {toast} from 'react-toastify'
import UploadImg from './UpdateImage'
import {useParams} from 'react-router-dom'
import ValidationFun from '../../../yup/ValidationFun'

export default function EditForm({
  setOpen,
  open,
  item,
  setItem,
  rows,
  setRows,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  item: iMedia
  setItem: React.Dispatch<React.SetStateAction<iMedia>>
  rows: any
  setRows: any
}) {
  const [iloading, setIsLoading] = useState(false)
  const [base64Image, setBase64Image] = React.useState<any>(item.attachment)
  const [isCheck, setIsCheck] = useState(item.type || 'image')
  const [media, setMedia] = useState(item)
  const [video, setVideo] = useState<any>(item.attachment)
  const {id} = useParams()
  const {t} = useTranslation()
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [errors, setErrors] = useState<any>({})
  const saveChange = async () => {
    let data = {...media, attachment: null}
    if (isCheck === 'image' && base64Image !== 'string') {
      data = {...data, attachment: base64Image}
    } else if (isCheck === 'video' && video !== 'string') {
      data = {...data, attachment: video}
    }
    if (data.attachment) {
      ValidationFun(data, MediaSchema, setErrors, (res: any) => {
        const formData = new FormData()
        formData.append('title', res.title)
        if (typeof res.attachment !== 'string') {
          formData.append('attachment', res.attachment)
        }
        formData.append('type', isCheck)

        Media.put(
          item.id,
          formData,
          (res: any) => {
            setItem(res)
            if (!id) {
              setRows(
                [...rows.filter((e: any) => e.id != item.id), res].sort((a, b) => b.id - a.id)
              )
            }
            setMsgErr('')
            setOpen(false)
          },
          setIsLoading
        )
      })
      // MediaSchema.validate(data)
      //   .then((res: any) => {
      //     setIsLoading(true)
      //     const formData = new FormData()
      //     formData.append('title', res.title)
      //     if (typeof res.attachment !== 'string') {
      //       formData.append('attachment', res.attachment)
      //     }
      //     formData.append('type', isCheck)

      //     Media.put(
      //       item.id,
      //       formData,
      //       (res: any) => {
      //         setItem(res)
      //         if (!id) {
      //           setRows(
      //             [...rows.filter((e: any) => e.id != item.id), res].sort((a, b) => b.id - a.id)
      //           )
      //         }
      //         setMsgErr('')
      //         setOpen(false)
      //       },
      //       setIsLoading
      //     )
      //   })
      //   .catch((error: Yup.ValidationError) => {
      //     setMsgErr(error.message)
      //     setIsLoading(false)
      //   })
    } else {
      setMsgErr('file is required')
    }
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Stack gap='32px'>
        <CheckBox isCheck={isCheck} setIsCheck={setIsCheck} />
        <Stack position={'relative'} gap='7px'>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'var(--bs-body-color)',
              lineHeight: '14px',
            }}
          >
            {t('title')}
          </Typography>
          <Box width='100%'>
            <Stack gap='10px'>
              <InputType errors name={'title'} change={setMedia} value={media} type='text' />

              {/* <Tabs /> */}
              <ErrorMsg msgErr={msgErr} name='title' />
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'var(--bs-body-color)',
              lineHeight: '14px',
            }}
          >
            {t('uploadFile')}
          </Typography>
          {!base64Image && !video && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                color: '#9D9DA6',
                lineHeight: '18px',
              }}
            >
              {t('maxFile')}
            </Typography>
          )}
          {isCheck === 'image' && (
            <Box width='100%'>
              <UploadImg base64Image={base64Image} setBase64Image={setBase64Image} />
              {(typeof base64Image === 'string' || base64Image.name) && (
                <Box
                  sx={{
                    color: '#9D9DA6',
                    padding: '14px',
                    borderRadius: '8px',
                    border: '1px solid var(--bs-gray-300)',
                    marginTop: '12px',
                  }}
                  width={'100%'}
                >
                  {(typeof base64Image === 'string' && base64Image.slice(0, 30) + '...') ||
                    (base64Image.name && base64Image.name) + ' '}
                  ({base64Image.size && base64Image.size})
                </Box>
              )}
            </Box>
          )}{' '}
          {isCheck === 'video' && (
            <Box width='100%'>
              <UploadVideo base64Image={video} setBase64Image={setVideo} />
              {(typeof video === 'string' || video.name) && (
                <Box
                  sx={{
                    color: '#9D9DA6',
                    padding: '14px',
                    borderRadius: '8px',
                    border: '1px solid var(--bs-gray-300)',
                    marginTop: '12px',
                  }}
                  width={'100%'}
                >
                  {(typeof video === 'string' && video.slice(0, 30) + '...') ||
                    (video.name && video.name) + ' '}
                  ({video.size && video.size})
                </Box>
              )}
            </Box>
          )}
          {base64Image && video && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                color: '#9D9DA6',
                lineHeight: '18px',
                marginTop: '5px',
              }}
            >
              {t('maxFile')}
            </Typography>
          )}
          <ErrorMsg msgErr={msgErr} name='file' />
          <Box width={'100%'} mt='18px'>
            <SaveButton
              text={t('saveChanges')}
              submit={saveChange}
              isLoading={false}
              setOpen={setOpen}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
      {iloading && <Loading />}
    </Container>
  )
}
