import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import CheckBox from './CheckBox'
import LangSelect from './SelectLang'
import LabelInput from '../../customTable/LabelInput'
import LangBtns from './LangBtns'
import RestSaveButton from '../../customTable/RestSaveBtns'
import Langs from '../../../Api/SettingLangs'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import SetLang from '../../../utils/SetLangs'
import {useTranslation} from 'react-i18next'
import ErrorMsg from '../../customTable/ErrorMsg'
interface Ilang {
  name: string
  id: number
}
export default function Languages() {
  const [isCheck, setIsCheck] = useState(0)

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [defLang, setDefLang] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const [old, setOld] = useState({})
  const [isLoadingLangs, setIsLoadingLangs] = useState(false)
  const getLangs = async () => {
    Langs.get((res: any) => {
      setLangs(res.data.all_languages)
      const selectedLangsCode = res.data.store_languages.map((e: any) => +e.id)
      setSelectedLang(selectedLangsCode)
      setOld(selectedLangsCode)
    }, setIsLoadingLangs)
    // const selectedLangs = await SetLang()
    // const selectedLangsCode = selectedLangs.map((e: any) => e.code)

    setDefLang(store.defLang)
    setIsCheck(store.langStatus || store.langStatus === 1 ? 1 : 0)
  }
  const [selectedLang, setSelectedLang] = useState<any>([])
  const [langs, setLangs] = useState<Ilang[]>([])
  useEffect(() => {
    getLangs()
  }, [store.langs])
  useEffect(() => {
    // getLangs()
  }, [])
  const [msgErr, setMsgErr] = React.useState<string>('')

  const updateLang = async () => {
    if (selectedLang.length === 1 && selectedLang[0] === defLang && isCheck === 0) {
      setMsgErr('Choose one of the languages ​​or activate the default language')
    } else {
      setIsLoading(true)
      await Langs.update(
        {
          default_language_status: isCheck,
          languages: selectedLang.filter((e: any) => e !== defLang),
        },
        () => {
          localStorage.setItem('langs', JSON.stringify(selectedLang))
          window.location.reload()
        },
        setIsLoading
      )
    }
  }
  return (
    <Stack
      p='24px'
      borderRadius={'8px'}
      bgcolor={'var(--bs-app-header-base-bg-color)'}
      width={'100%'}
      minHeight={'351px'}
      justifyContent={'space-between'}
    >
      <Stack>
        <CheckBox defLang={defLang} isCheck={isCheck} setIsCheck={setIsCheck} />

        <Stack gap='12px' mt='22px' maxWidth={'600px'}>
          {/* <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}> */}
          {/* <LabelInput text={t('defaultLang')} /> */}
          {/* </label> */}
          <LangSelect
            name='test'
            disabled={false}
            setSelectedLang={setSelectedLang}
            selectedLang={selectedLang}
            setValues={setData}
            value={{code: ''}}
            option={langs.filter((e: any) => !selectedLang.includes(e.id))}
            values={data}
          />
        </Stack>
        <Stack>
          <LangBtns
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            langs={langs.filter((e: any, i) => selectedLang.includes(e.id))}
          />
        </Stack>
      </Stack>

      <Stack>
        {store.permissions && store.permissions.includes('languages_update') && (
          <RestSaveButton
            isLoading={isLoading}
            SaveText={t('save')}
            cancelText={t('rest')}
            submit={updateLang}
            cancel={() => {
              setSelectedLang(old)
              setIsCheck(store.langStatus)
            }}
          />
        )}

        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
    </Stack>
  )
}
// name,
// values,
// option,
// disabled,
// value,
// setValues,
