import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en', 'ar']
let languages = SetLang() || ['en']
export const Generalschema = (languages: any) => {
  return Yup.object().shape({
    permalink: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`permalinkErr`),
        }
      }, {})
    ),
    description: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string()
            .required(`descriptionErr`)
            .notOneOf(['<p><br></p>'], 'descriptionErr'),
        }
      }, {})
    ),
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),
  })
}

export const Advancedschema = (languages: any) => {
  return Yup.object().shape({
    price: Yup.number().positive('minmamSortErr').required('priceErr'),

    height: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('heightErr'),
    }),
    length: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('lengthErr'),
    }),
    quantity: Yup.number().when('unlimited_quantity', {
      is: 0,
      then: Yup.number().positive('minmamSortErr').required('quantityErr'),
    }),
    // discount_amount: Yup.number()
    //   .positive('Selling price must be a positive number')
    //   .required('Selling price is required'),
    unlimited_quantity: Yup.number()
      .oneOf([0, 1], 'Unlimited quantity must be either 0 or 1')
      .required('Unlimited quantity is required'),
    weight: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('weightErr'),
    }),
    width: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('widthErr'),
    }),
    sku: Yup.string().required('skuErr'),
  })
}

export const schema = Yup.object().shape({
  // related_products: Yup.array().of(Yup.object()).min(1, 'At least one related product is required'),
  // manufacturer_id: Yup.number()
  //   .moreThan(0, 'must be added Manufacturer')
  //   .required('Manufacturer ID is required'),
  image: Yup.string().min(1, 'imageErr'),
  // categories: Yup.array().of(Yup.object()).min(1, 'At least one related categories is required'),
  model: Yup.string().min(1, 'modelErr'),
})
export const productSchema = (languages: any) => {
  return Yup.object().shape({
    image: Yup.string().min(1, 'imageErr'),
    // categories: Yup.array().of(Yup.object()).min(1, 'At least one related categories is required'),
    model: Yup.string().min(1, 'modelErr'),
    price: Yup.number().positive('minmamSortErr').required('priceErr'),

    height: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('heightErr'),
    }),
    length: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('lengthErr'),
    }),
    quantity: Yup.number().when('unlimited_quantity', {
      is: 0,
      then: Yup.number().positive('minmamSortErr').required('quantityErr'),
    }),
    // discount_amount: Yup.number()
    //   .positive('Selling price must be a positive number')
    //   .required('Selling price is required'),
    unlimited_quantity: Yup.number()
      .oneOf([0, 1], 'Unlimited quantity must be either 0 or 1')
      .required('Unlimited quantity is required'),
    weight: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('weightErr'),
    }),
    width: Yup.number().when('is_shipping_pickup', {
      is: 1,
      then: Yup.number().positive('minmamSortErr').required('widthErr'),
    }),
    sku: Yup.string().required('skuErr'),
    permalink: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`permalinkErr`),
        }
      }, {})
    ),
    description: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string()
            .required(`descriptionErr`)
            .notOneOf(['<p><br></p>'], 'descriptionErr'),
        }
      }, {})
    ),
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),
  })
}
