import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'

export default function LabelWithValue({label, value}: any) {
  return (
    <Stack gap='5px' direction={'row'}>
      <Label color='#19224C' fw='500' fs='18px' text={label} />:
      <Label color='#787887' fw='500' fs='18px' text={value} />
    </Stack>
  )
}
