import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function HeadTable() {
  const {t} = useTranslation()

  return (
    <Stack direction={'row'} width='100%'>
      <Stack width={'32%'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography>{t('optionValue')} </Typography>
      </Stack>
      <Stack width={'40%'} sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}>
        <Typography> {t('priceOperation')} </Typography>
      </Stack>
      <Stack sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}} width={'24%'}>
        <Typography>{t('sort')} </Typography>
      </Stack>
      <Stack width={'42px'}></Stack>
    </Stack>
  )
}
