import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import {SidebarLogo} from './components/sidebar/SidebarLogo'
import {Stack} from '@mui/material'
import {SidebarMenu} from './components/sidebar/sidebar-menu/SidebarMenu'
import {Sidebar} from './components/sidebar'
import {KTSVG} from '../helpers'

export default function SidBarItems({activeMenu, setActiveMenu}: any) {
  const screenWidth = window.innerWidth
  const store = useSelector((state: RootState) => state.vender)
  const [isScroll, setIsScroll] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      if (scrollY > 20) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      <Stack
        sx={{
          top: isScroll ? '140px' : '140px !important',
          left: screenWidth >= 992 && store.isRtl === 0 ? '30px !important' : 'auto !important',
          right: screenWidth >= 992 && store.isRtl === 1 ? '30px !important' : 'auto !important',
          borderRadius: '8px',
          background: 'var( --main-color) !important',
          width: activeMenu ? '250px' : '105px',
          // height: '80vh',
          transition: 'top 0.2s linear, width 0.2s linear',
        }}
        // position={'fixed'}
      >
        {/* <SidebarLogo /> */}
        <Stack p='0 7px' width={'100%'} height={'100%'} position={'relative'}>
          <Stack
            left={store.isRtl === 1 ? '-11px' : 'auto'}
            right={store.isRtl === 0 ? '-11px' : 'auto'}
            top={'42px'}
            position={'absolute'}
            onClick={() => {
              setActiveMenu(!activeMenu)
            }}
          >
            <Stack
              sx={{
                transform:
                  store.isRtl === 1 && !activeMenu
                    ? 'rotate(180deg)'
                    : store.isRtl === 0 && !activeMenu
                    ? 'rotate(180deg)'
                    : 'rotate(0)',
                background: 'white',
                padding: '1px',
                borderRadius: '4px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr.svg' className='svg-icon-2 rotate-180' />
            </Stack>
          </Stack>
          <SidebarMenu activeMenu={activeMenu} />
        </Stack>

        {/* <Sidebar /> */}
      </Stack>
    </>
  )
}
