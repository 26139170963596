import { FormikErrors, FormikTouched } from 'formik';

interface Props<T> {
     touched: FormikTouched<T> | any
     errors: FormikErrors<T> | any
}

function ErrorSection<T>({ touched, errors }: Props<T>): JSX.Element {
     if (touched && errors && typeof errors === 'string') {
          return (
               <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors}</div>
               </div>
          );
     } else {
          return <></>;
     }
}

export default ErrorSection;
