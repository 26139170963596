import React from 'react'

export default function Logo() {
  return (
    <svg width='75' height='32' viewBox='0 0 75 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M74.9005 12.7978H72.6732L67.6297 5.17693V12.7978H65.4023V1.67676H67.6297L72.6732 9.31349V1.67676H74.9005V12.7978Z'
        fill='#42538D'
      />
      <path d='M63.2039 1.69238V12.7975H60.9766V1.69238H63.2039Z' fill='#42538D' />
      <path
        d='M58.7716 12.7978H56.5442L51.5008 5.17693V12.7978H49.2734V1.67676H51.5008L56.5442 9.31349V1.67676H58.7716V12.7978Z'
        fill='#42538D'
      />
      <path
        d='M41.9379 12.9088C40.8985 12.9088 39.9439 12.6648 39.0741 12.1769C38.2044 11.689 37.515 11.0155 37.0059 10.1564C36.4967 9.28661 36.2422 8.3055 36.2422 7.21303C36.2422 6.13115 36.4967 5.16065 37.0059 4.30152C37.515 3.43178 38.2044 2.75296 39.0741 2.26506C39.9439 1.77715 40.8985 1.5332 41.9379 1.5332C42.988 1.5332 43.9426 1.77715 44.8017 2.26506C45.6714 2.75296 46.3556 3.43178 46.8541 4.30152C47.3632 5.16065 47.6177 6.13115 47.6177 7.21303C47.6177 8.3055 47.3632 9.28661 46.8541 10.1564C46.3556 11.0155 45.6714 11.689 44.8017 12.1769C43.932 12.6648 42.9774 12.9088 41.9379 12.9088ZM41.9379 10.92C42.6061 10.92 43.1948 10.7715 43.7039 10.4745C44.213 10.167 44.6108 9.73209 44.8972 9.16994C45.1835 8.60779 45.3267 7.95549 45.3267 7.21303C45.3267 6.47056 45.1835 5.82356 44.8972 5.27202C44.6108 4.70987 44.213 4.28031 43.7039 3.98332C43.1948 3.68634 42.6061 3.53785 41.9379 3.53785C41.2697 3.53785 40.6757 3.68634 40.156 3.98332C39.6469 4.28031 39.2492 4.70987 38.9628 5.27202C38.6764 5.82356 38.5332 6.47056 38.5332 7.21303C38.5332 7.95549 38.6764 8.60779 38.9628 9.16994C39.2492 9.73209 39.6469 10.167 40.156 10.4745C40.6757 10.7715 41.2697 10.92 41.9379 10.92Z'
        fill='#42538D'
      />
      <path
        d='M32.5029 12.7975L28.4618 7.8495V12.7975H26.2344V1.69238H28.4618V6.67217L32.5029 1.69238H35.1916L30.6096 7.1972L35.3189 12.7975H32.5029Z'
        fill='#42538D'
      />
      <path
        d='M70.0243 22.5813V25.3655H73.7631V27.1315H70.0243V30.0748H74.2404V31.8886H67.7969V20.7676H74.2404V22.5813H70.0243Z'
        fill='#42538D'
      />
      <path
        d='M63.3291 31.8883L60.879 27.5608H59.8289V31.8883H57.6016V20.7832H61.77C62.6291 20.7832 63.3609 20.937 63.9655 21.2446C64.5701 21.5416 65.0209 21.9499 65.3179 22.4696C65.6254 22.9788 65.7792 23.5515 65.7792 24.1879C65.7792 24.9198 65.5671 25.5827 65.1428 26.1766C64.7186 26.76 64.0875 27.1631 63.2496 27.3858L65.9065 31.8883H63.3291ZM59.8289 25.8903H61.6904C62.295 25.8903 62.7458 25.7471 63.0427 25.4607C63.3397 25.1637 63.4882 24.7554 63.4882 24.2356C63.4882 23.7265 63.3397 23.3341 63.0427 23.0583C62.7458 22.7719 62.295 22.6287 61.6904 22.6287H59.8289V25.8903Z'
        fill='#42538D'
      />
      <path
        d='M50.266 31.9996C49.2266 31.9996 48.272 31.7556 47.4023 31.2677C46.5325 30.7798 45.8431 30.1063 45.334 29.2472C44.8249 28.3774 44.5703 27.3963 44.5703 26.3038C44.5703 25.222 44.8249 24.2515 45.334 23.3923C45.8431 22.5226 46.5325 21.8438 47.4023 21.3559C48.272 20.868 49.2266 20.624 50.266 20.624C51.3161 20.624 52.2707 20.868 53.1298 21.3559C53.9996 21.8438 54.6837 22.5226 55.1822 23.3923C55.6913 24.2515 55.9459 25.222 55.9459 26.3038C55.9459 27.3963 55.6913 28.3774 55.1822 29.2472C54.6837 30.1063 53.9996 30.7798 53.1298 31.2677C52.2601 31.7556 51.3055 31.9996 50.266 31.9996ZM50.266 30.0108C50.9343 30.0108 51.5229 29.8624 52.032 29.5654C52.5412 29.2578 52.9389 28.8229 53.2253 28.2608C53.5117 27.6986 53.6548 27.0463 53.6548 26.3038C53.6548 25.5614 53.5117 24.9144 53.2253 24.3628C52.9389 23.8007 52.5412 23.3711 52.032 23.0741C51.5229 22.7772 50.9343 22.6287 50.266 22.6287C49.5978 22.6287 49.0039 22.7772 48.4841 23.0741C47.975 23.3711 47.5773 23.8007 47.2909 24.3628C47.0045 24.9144 46.8613 25.5614 46.8613 26.3038C46.8613 27.0463 47.0045 27.6986 47.2909 28.2608C47.5773 28.8229 47.975 29.2578 48.4841 29.5654C49.0039 29.8624 49.5978 30.0108 50.266 30.0108Z'
        fill='#42538D'
      />
      <path
        d='M43.4857 20.7832V22.581H40.5265V31.8883H38.2991V22.581H35.3398V20.7832H43.4857Z'
        fill='#42538D'
      />
      <path
        d='M30.0699 31.9996C29.2956 31.9996 28.5956 31.867 27.9698 31.6018C27.3546 31.3367 26.8667 30.9548 26.5061 30.4563C26.1454 29.9578 25.9598 29.3691 25.9492 28.6903H28.3357C28.3675 29.1464 28.5266 29.507 28.813 29.7722C29.11 30.0374 29.513 30.1699 30.0221 30.1699C30.5419 30.1699 30.9502 30.048 31.2472 29.804C31.5442 29.5495 31.6927 29.2207 31.6927 28.8176C31.6927 28.4888 31.5919 28.2183 31.3904 28.0062C31.1889 27.7941 30.9343 27.6297 30.6267 27.513C30.3297 27.3857 29.9161 27.2478 29.3858 27.0993C28.6645 26.8872 28.0758 26.6804 27.6198 26.4789C27.1743 26.2667 26.7871 25.9538 26.4583 25.5402C26.1401 25.1159 25.981 24.5538 25.981 23.8537C25.981 23.1961 26.1454 22.6234 26.4742 22.1355C26.803 21.6476 27.2644 21.2763 27.8584 21.0218C28.4524 20.7566 29.1312 20.624 29.8949 20.624C31.0404 20.624 31.9685 20.9051 32.6791 21.4672C33.4003 22.0188 33.7981 22.7931 33.8723 23.7901H31.4222C31.401 23.4082 31.2366 23.0954 30.929 22.8514C30.632 22.5968 30.2343 22.4696 29.7358 22.4696C29.3009 22.4696 28.9509 22.5809 28.6857 22.8037C28.4312 23.0264 28.3039 23.3499 28.3039 23.7742C28.3039 24.0712 28.3993 24.3204 28.5903 24.5219C28.7918 24.7129 29.0357 24.872 29.3221 24.9992C29.6191 25.1159 30.0328 25.2538 30.5631 25.4129C31.2843 25.625 31.873 25.8372 32.3291 26.0493C32.7852 26.2614 33.1776 26.5796 33.5064 27.0039C33.8352 27.4281 33.9996 27.985 33.9996 28.6744C33.9996 29.2684 33.8458 29.8199 33.5382 30.329C33.2306 30.8382 32.7799 31.2465 32.1859 31.5541C31.5919 31.8511 30.8866 31.9996 30.0699 31.9996Z'
        fill='#42538D'
      />
      <path
        d='M11.0138 15.1639C13.4087 13.4269 15.3578 11.147 16.7012 8.51113C18.0446 5.87525 18.7441 2.95848 18.7423 0H11.5866C11.5866 1.52106 11.287 3.02724 10.7049 4.43252C10.1229 5.8378 9.26968 7.11467 8.19413 8.19022C7.11857 9.26578 5.8417 10.119 4.43642 10.701C3.03114 11.2831 1.52497 11.5827 0.00390625 11.5827V18.7428C1.52497 18.7428 3.03114 19.0424 4.43642 19.6245C5.8417 20.2066 7.11857 21.0598 8.19413 22.1353C9.26968 23.2109 10.1229 24.4878 10.7049 25.893C11.287 27.2983 11.5866 28.8045 11.5866 30.3255H18.7445C18.7457 27.3673 18.0457 24.4508 16.7019 21.8153C15.3581 19.1799 13.4088 16.9004 11.0138 15.1639Z'
        fill='#42538D'
      />
      <path
        d='M2.73527 30.328C4.24592 30.328 5.47055 29.1033 5.47055 27.5927C5.47055 26.082 4.24592 24.8574 2.73527 24.8574C1.22462 24.8574 0 26.082 0 27.5927C0 29.1033 1.22462 30.328 2.73527 30.328Z'
        fill='#F16645'
      />
    </svg>
  )
}
