import axios from 'axios'
import JwtService from '../app/utils/TokenServices'

const userLanguage = localStorage.getItem('i18nextLng') || 'en'

const Apiservices = axios.create({
  baseURL: 'https://vendor.api.koninstore.com/api/v1',
  // withCredentials: true,
})

Apiservices.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${JwtService.getToken()}`
    config.headers['Accept-Language'] = userLanguage
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['responseType'] = 'blob'
    return Promise.resolve(config)
  },
  (error) => {
    return Promise.reject(error)
  }
)

Apiservices.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error.response, 'fffffffffff')

    if (error.response) {
      if (error.response.status === 403) {
        window.location.href = '/#/403'
      } else if (error.response.status === 401) {
        JwtService.destroyToken()
        window.location.href = '/#/'
      } else if (error.response.status === 500) {
        window.location.href = '/#/500'
      }
    }
    return Promise.reject(error)
  }
)

export default Apiservices
