import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import MainBtn from '../customTable/MainBtn'
import PopUp from '../customTable/PopUp'
import ShowPlan from './ShowPlan'
import {useNavigate} from 'react-router-dom'

export default function Cards() {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <Stack mt='30px'>
      <Stack direction={{sm: 'row', xs: 'column'}} gap={'24px'}>
        <Stack
          sx={{
            background: 'var(--bs-app-footer-bg-color)',
            width: {xs: '100%'},
            borderRadius: '8px',
            padding: '24px',
            gap: '24px',
          }}
        >
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>{t('accountDetails')}</Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('sellerName')} : <span style={{color: '#787887'}}>Kakashi</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('mobile')} : <span style={{color: '#787887'}}>Kakashi</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('sellerEmail')} : <span style={{color: '#787887'}}>Kakashi</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('country')} : <span style={{color: '#787887'}}>Kakashi</span>
          </Typography>
          <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
            {t('city')} : <span style={{color: '#787887'}}>Kakashi</span>
          </Typography>
        </Stack>
      </Stack>
      <Stack mt='24px'>
        <Stack
          sx={{
            background: 'var(--bs-app-footer-bg-color)',
            width: {xs: '100%', sm: '100%'},
            borderRadius: '8px',
            padding: '24px',
            gap: '24px',
          }}
        >
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
            {t('subscriptionsDetails')}
          </Typography>
          <Stack
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            direction={{sm: 'row', xs: 'column'}}
            gap={'24px'}
          >
            <Stack gap='24px'>
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('planName')} : <span style={{color: '#009EF7'}}>Kakashi</span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('templateName')} : <span style={{color: '#787887'}}>Kakashi</span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('subscriptionStatus')} : <span style={{color: '#787887'}}>Kakashi</span>
              </Typography>{' '}
              <MainBtn
                secondary
                width='auto'
                text='planFeatures'
                handelSubmit={() => {
                  setOpen(true)
                }}
              />
              <PopUp setOpen={setOpen} open={open} width={'md'}>
                <ShowPlan />
              </PopUp>
            </Stack>

            <Stack minWidth={'350px'} gap='24px'>
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('subscriptionType')} : <span style={{color: '#787887'}}>Kakashi</span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('startDate')} : <span style={{color: '#787887'}}>Kakashi</span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('endDate')} : <span style={{color: '#787887'}}>Kakashi</span>
              </Typography>{' '}
              <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
                {t('subscriptionStatus')} : <span style={{color: '#787887'}}>Kakashi</span>
              </Typography>{' '}
            </Stack>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <MainBtn
              handelSubmit={() => {
                navigate('/subscriptions-change-package')
              }}
              width='auto'
              text='updateAccountDetails'
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
