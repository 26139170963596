import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'
import InputType from '../../customTable/InputType'

export default function LabelWithInput({text, data, setData, name}: any) {
  return (
    <Stack width={{md: 'calc(50% - 16px)', xs: '100%'}} gap='5px'>
      <Label text={text} />
      <InputType checked name={name} change={setData} value={data} type='text' />
    </Stack>
  )
}
