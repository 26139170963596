import React, {useEffect, useState} from 'react'
import Tabs from '../../customTable/Tabs'
import {Box, Container, Stack, Typography} from '@mui/material'
import Switch from '../../../componet/Toastr/Switch'
import DateInput from '../../customTable/DateInput'
import LabelInput from '../../customTable/LabelInput'
import RedStart from '../../customTable/RedStart'
import InputType from '../../customTable/InputType'
import SaveButton from '../../customTable/SaveButton'
import CustomSelect from '../../customTable/CustomSelect'
import TextErea from '../../customTable/TextErea'
import Coupons from '../../../Api/coupons'
import MultiSelect from '../../customTable/MultiSelectDropdown'
import Product from '../../../Api/product'
import Catalog from '../../../Api/catalog'
import {Cuoponschema} from '../../../yup/CouponShcema'
import * as Yup from 'yup'
import Loading from '../../customTable/LoadingForm'
import ICuopon from '../../../interface/ICuopon'
import MySelect from '../../customTable/CustomSelectTest'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import TabsWithFlag from '../../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import SimpleLoading from '../../customTable/SimpleLoading'
import CheckInputWithLabel from '../../customTable/CheckInputWithLabel'
import ValidationFun from '../../../yup/ValidationFun'

export default function AddForm({
  total,
  rows,
  setRows,
  setOpen,
  setLoading,
  setNum,
}: {
  total: number | undefined
  rows: ICuopon[]
  setRows: React.Dispatch<React.SetStateAction<ICuopon[]>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: any
  setNum: any
}) {
  const [activeLang, setActiveLang] = useState('en')

  const {t} = useTranslation()

  const [cuopon, stCuopon] = useState({status: 0, is_free_shipping: 0, discount_type: 0})
  const [state, setState] = useState(1)
  const [freeShop, setFreeShop] = useState(1)
  const [startDate, setStartDate] = useState<string | undefined>(undefined)
  const [endDate, setEndDate] = useState<string | undefined>(undefined)
  const [optionsDisCount, setDisCount] = useState([
    {id: 0, name: 'Percentage discount'},
    {
      name: 'Fixed cart discount',
      id: 1,
    },
    {
      name: 'Fixed product discount',
      id: 2,
    },
  ])
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(state === 1 ? 0 : 1)
    stCuopon({...cuopon, status: state === 1 ? 0 : 1})
  }
  const handleFreeShopChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFreeShop(freeShop === 1 ? 0 : 1)
    stCuopon({...cuopon, is_free_shipping: freeShop === 1 ? 0 : 1})
  }
  const [msgErr, setMsgErr] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [languages, setlanguages] = useState(['en'])
  const [isProduct, setIsProduct] = useState(1)
  const [isLoadingCatagory, setLoadingCatagory] = useState(false)
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState<any>({})
  const onSubmit = () => {
    ValidationFun(
      {
        ...cuopon,
        start_date: startDate,
        end_date: endDate,
        isProduct: isProduct,
      },
      Cuoponschema(languages),
      setErrors,
      (value: any) => {
        Coupons.add(
          {
            ...value,
            discount_type:
              value.discount_type === 3 || value.discount_type === 0
                ? 'percentage'
                : value.discount_type === 1
                ? 'fixed_cart'
                : 'fixed_product',
          },
          (res: any) => {
            setRows([{...res, status: 1}, ...rows])
            setNum((prev: any) => prev + 1)
            setOpen(false)
          },
          setIsLoading
        )
      }
    )
  }
  const [products, setProducts] = useState([])
  const [catlogs, setcatlogs] = useState([])
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const getProduct = () => {
    Product.get((res: any) => {
      setProducts(res.data)
    }, setIsLoadingProducts)
  }
  const getCatlogs = () => {
    Catalog.get((res: any) => {
      setcatlogs(res.data)
    }, setLoadingCatagory)
  }
  useEffect(() => {
    getCatlogs()
    getProduct()
  }, [])
  console.log(Object.keys(errors)[0], 'Object.values(errors)[0]')

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(store.defLang)
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      className='card'
      sx={{padding: '25px 20px', position: 'relative'}}
      maxWidth='md'
    >
      <Stack width={'100%'}>
        <Stack justifyContent={'space-between'} alignItems={'center'} direction='row'>
          <Typography
            sx={{
              fontSize: '19px',
              color: 'var(--bs-heading-color, inherit)',
              fontWeight: 600,
              lineHeight: '24px',
              textTransform: 'capitalize',
            }}
          >
            {t('createCoupon')}
          </Typography>

          <Stack direction={'row'}>
            <TabsWithFlag
              languages={selectedLang}
              activeLang={defLang}
              setActiveLang={setDefLang}
              msgErr={errors && Object.keys(errors)[0]}
            />
            {/* <Tabs activeLang={activeLang} setActiveLang={setActiveLang} /> */}
          </Stack>
        </Stack>
        <Stack mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          {selectedLang.map((e: any, i: number) => (
            <>
              {e.code === defLang && (
                <Stack key={i} sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('name')} />
                    <RedStart />
                  </label>
                  <Box sx={{width: {sm: '250px', xs: '100%'}}}>
                    <InputType
                      errors={errors}
                      child={e.code}
                      name={'name'}
                      change={stCuopon}
                      value={cuopon}
                      type='text'
                    />
                  </Box>
                </Stack>
              )}
            </>
          ))}
          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
              <LabelInput text={t('code')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <InputType
                errors={errors}
                change={stCuopon}
                name={'code'}
                value={cuopon}
                type='text'
              />
            </Box>
          </Stack>
        </Stack>
        <Stack mt='20px'>
          {selectedLang.map((e: any, i: number) => (
            <>
              {e.code === defLang && (
                <Stack gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('description')} />

                    <RedStart />
                  </label>
                  <Box sx={{minWidth: {sm: '250px', xs: '100%'}}}>
                    <TextErea
                      errors={errors}
                      child={e.code}
                      name={'description'}
                      change={stCuopon}
                      value={cuopon}
                    />
                  </Box>
                </Stack>
              )}
            </>
          ))}
        </Stack>

        <Stack mt='20px'>
          <Stack gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'type'}>
              <LabelInput text={t('discountType')} />

              <RedStart />
            </label>
            <Box sx={{minWidth: {sm: '250px', xs: '100%'}}}>
              <MySelect
                values={cuopon}
                option={optionsDisCount}
                setValues={stCuopon}
                name='discount_type'
                value={{id: -1, name: 'ff'}}
              />
              {/* <CustomSelect values={cuopon} name='discount_type' /> */}
            </Box>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('amount')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <InputType
                errors={errors}
                name={'discount_amount'}
                change={stCuopon}
                value={cuopon}
                type='number'
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
              <Typography
                sx={{
                  fontSize: '19px',
                  color: 'var(--bs-heading-color, inherit)',
                  fontWeight: 700,
                  lineHeight: '24px',
                  textTransform: 'capitalize',
                }}
              >
                {t('allowFreeShepping')}
              </Typography>
              <Box mt='5px'>
                <Switch
                  name='free'
                  value={freeShop === 1 ? true : false}
                  handleChange={handleFreeShopChange}
                />
              </Box>
            </label>
          </Stack>
        </Stack>

        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('startDate')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <DateInput date={startDate} setDate={setStartDate} />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('couponExpiryDate')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <DateInput date={endDate} setDate={setEndDate} />
            </Box>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('limit')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <InputType
                errors={errors}
                name={'usage_limit_per_coupon'}
                change={stCuopon}
                value={cuopon}
                type='number'
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('limitUsage')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <InputType
                errors={errors}
                name={'limit_usage_to_x_items'}
                change={stCuopon}
                value={cuopon}
                type='number'
              />
            </Box>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('limitPerUser')} />

              <RedStart />
            </label>
            <Box sx={{width: {xs: '100%'}}}>
              <InputType
                errors={errors}
                name={'usage_limit_per_user'}
                change={stCuopon}
                value={cuopon}
                type='number'
              />
            </Box>
          </Stack>

          <Stack sx={{width: {sm: '250px', xs: '100%'}}} gap='10px'>
            <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
              <LabelInput text={t('minimumSpend')} />

              <RedStart />
            </label>
            <Box sx={{width: {sm: '250px', xs: '100%'}}}>
              <InputType
                errors={errors}
                name={'min_spend'}
                change={stCuopon}
                value={cuopon}
                type='number'
              />
            </Box>
          </Stack>
        </Stack>

        <Stack alignItems={'center'} mt='40px' gap='20px' flexWrap={'wrap'} direction={'row'}>
          <Stack width='100%' gap='24px' direction={'row'}>
            <CheckInputWithLabel
              onClick={() => {
                setIsProduct(1)
                setcatlogs([])
              }}
              check={isProduct}
              value={1}
              text={'products'}
            />
            <CheckInputWithLabel
              onClick={() => {
                setProducts([])
                setIsProduct(0)
              }}
              check={isProduct}
              value={0}
              text={'categories'}
            />
          </Stack>
          {isProduct === 1 && (
            <Stack sx={{width: {xs: '100%'}}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={t('products')} />
              </label>
              <Box sx={{width: {xs: '100%'}}}>
                {isLoadingProducts ? (
                  <SimpleLoading />
                ) : (
                  <MultiSelect
                    name={'products'}
                    cuopon={cuopon}
                    stCuopon={stCuopon}
                    products={products}
                  />
                )}

                {/* <InputType errors={errors} type='number' /> */}
              </Box>
            </Stack>
          )}

          {isProduct === 0 && (
            <Stack sx={{width: {xs: '100%'}}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={'categories'} />
              </label>
              <Box sx={{width: {xs: '100%'}}}>
                {isLoadingCatagory ? (
                  <SimpleLoading />
                ) : (
                  <MultiSelect
                    name={'categories'}
                    cuopon={cuopon}
                    stCuopon={stCuopon}
                    products={catlogs}
                  />
                )}
              </Box>
            </Stack>
          )}
        </Stack>
        <Stack mt='20px' direction={'row'} alignItems={'flex-end'} gap='100px'>
          <Typography
            sx={{
              fontSize: '19px',
              color: 'var(--bs-heading-color, inherit)',
              fontWeight: 600,
              lineHeight: '24px',
              textTransform: 'capitalize',
            }}
          >
            {t('status')}
          </Typography>
          <Switch
            name='status'
            value={state === 1 ? true : false}
            handleChange={handleStatusChange}
          />
        </Stack>

        <SaveButton
          setOpen={setOpen}
          isLoading={isLoading}
          submit={onSubmit}
          text={t('saveChanges')}
        />
      </Stack>
      <Stack alignItems={'flex-end'}></Stack>
    </Container>
  )
}
