import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../customTable/Label'
import InputType from '../customTable/InputType'
import MainBtn from '../customTable/MainBtn'
import CountdownTimer from '../customTable/CountdownTimer'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function Form() {
  const [data, setData] = useState({})
  const [time, setTime] = useState(180)
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack gap='16px' alignItems={'center'} p='48px 70px'>
      <Label fw='600' fs='24px' text='paymentConfirmation' />
      <Label align='center' text='verificationServiseFeesMsg' />
      <Label color='var(--main-color)' fw='600' fs='14px' text='serviceFeePayable' />
      <Label fs='34px' fw='700' align='center' text='IQ 2.2 M' />
      <Stack position={'relative'} m='20px 0' width={{sm: '100%'}}>
        <InputType
          placeholder={'verificationCode'}
          type='text'
          value={data}
          change={setData}
          name='verification'
        />
        <Stack
          sx={{
            left: store.isRtl !== 0 ? '10px' : 'auto',
            right: store.isRtl === 0 ? '10px' : 'auto',
            bottom: '9px',
          }}
          position={'absolute'}
        >
          <CountdownTimer setTime={setTime} initialSeconds={time} />
        </Stack>
      </Stack>
      <MainBtn text='confirm' />
    </Stack>
  )
}
