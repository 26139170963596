import {Stack} from '@mui/material'
import React, {useState} from 'react'
import CheckBox from './CheckBox'
import LangSelect from './SelectLang'
import LabelInput from '../../customTable/LabelInput'
import LangBtns from './LangBtns'
import RestSaveButton from '../../customTable/RestSaveBtns'
import Switch from '../../../componet/Toastr/Switch'
import Label from '../../customTable/Label'
interface Ilang {
  name: string
  id: number
}
export default function Currency() {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [selectedLang, setSelectedLang] = useState<number[]>([])
  const [langs, setLangs] = useState<Ilang[]>([
    {id: 1, name: 'Arabic'},
    {id: 2, name: 'English'},
  ])
  return (
    <Stack
      p='24px'
      borderRadius={'8px'}
      bgcolor={'var(--bs-app-header-base-bg-color)'}
      width={'100%'}
      // minHeight={'351px'}
      justifyContent={'space-between'}
    >
      <Stack>
        <CheckBox />

        <Stack gap='12px' mt='22px' maxWidth={'600px'}>
          <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
            <LabelInput text={'localCurrency'} />
          </label>
          <LangSelect
            name='test'
            disabled={false}
            setSelectedLang={setSelectedLang}
            selectedLang={selectedLang}
            setValues={setData}
            value={{id: ''}}
            option={langs.filter((e: Ilang) => !selectedLang.includes(e.id))}
            values={data}
          />
        </Stack>
        <Stack mt='35px' gap='90px' direction={'row'}>
          <Label text='currencySwitch' />
          <Switch
            name='status'
            value={true}
            handleChange={
              () => {}
              // store.permissions &&
              // store.permissions.includes('manufacturers_update') &&
              // handleStatusChange
            }
          />
        </Stack>
      </Stack>

      <Stack>
        <RestSaveButton
          isLoading={isLoading}
          SaveText='Save'
          cancelText='Rest'
          submit={() => {}}
          cancel={() => {}}
        />
      </Stack>
    </Stack>
  )
}
// name,
// values,
// option,
// disabled,
// value,
// setValues,
