import React, {useState} from 'react'
import {Avatar, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function Row({item}: any) {
  const [editItem, setEditItem] = useState(item)

  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      <tr className='fw-bold text-muted '>
        <td>
          <Stack gap='5px' direction={'row'}>
            <Stack width={'50px'} borderRadius={'8px'} height={'50px'}>
              <Avatar
                sx={{width: '100%', height: '100%', borderRadius: '8px'}}
                src={editItem.image}
                alt=''
              />
            </Stack>
            <Stack>
              <Typography sx={{fontSize: '16px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
                {item.name &&
                  (item.name[store.lang] || item.name[store.defLang]) &&
                  (item.name[store.lang] || item.name[store.defLang]).slice(0, 15)}
                {item.name &&
                  (item.name[store.lang] || item.name[store.defLang]) &&
                  (item.name[store.lang] || item.name[store.defLang]).length > 15 &&
                  '...'}
              </Typography>
              {item.order_options &&
                item.order_options.map((e: any, i: any) => (
                  <Typography key={i} sx={{color: 'var(--bs-body-color)'}}>
                    {e.option_name && (e.option_name[store.lang] || e.option_name[store.defLang])} :{' '}
                    <span style={{color: 'var(--bs-text-gray-700)'}}>
                      {e.option_value &&
                        (e.option_value[store.lang] || e.option_value[store.defLang])}
                    </span>{' '}
                  </Typography>
                ))}
            </Stack>
          </Stack>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.model}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.quantity}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.unit_price}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.sub_total}</span>
        </td>
        <td
          style={{
            textAlign: store.isRtl === 0 ? 'start' : 'start',
          }}
        >
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.total}</span>
        </td>
      </tr>
    </>
  )
}
