import React, {useState, useCallback, useEffect} from 'react'
import TableHead from './TablHead'
import Row from './Row'

import {CircularProgress, Stack} from '@mui/material'

import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {PaginationSection} from '../customTable/pagnationSection'
import Media from '../../Api/media'
import iMedia from '../../interface/iMedia'
import Search from '../customTable/Search'
import PageApi from '../../Api/Page'
import MenuApi from '../../Api/menu'
import Card from './Card'
import ServiseFees from '../../Api/serviseFees'

export default function Table({
  rows,
  setRows,
  added,
}: {
  rows: iMedia[] | never[]
  setRows: React.Dispatch<React.SetStateAction<iMedia[] | never[]>>
  added: boolean
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [cards, setCards] = useState<any>({})
  const {t} = useTranslation()

  const dispatch = useDispatch()

  const getMedia = (searchText: string, rowsPerPage: number, page: number) => {
    ServiseFees.get(
      searchText,
      rowsPerPage,
      page,
      (res: any) => {
        setCards({
          total: res.data.total_of_service_fees,
          paid: res.data.paid_service_fees,
          unpaid: res.data.un_paid_service_fees,
        })

        setTotal(res.pages.total)
        console.log(res, 'res.data')

        setRows(res.data.service_fees)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    getMedia(search, rowsPerPage, page)
  }, [rowsPerPage, page])
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getMedia(e.target.value, rowsPerPage, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )
  return (
    <div style={{marginTop: '30px'}} className={`card fds sha`}>
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <Stack mt='14px' flexWrap={'wrap'} gap='32px' direction={'row'}>
            <Card data={{name: 'totalServesFees', value: cards.total}} />
            <Card data={{name: 'paidService', value: cards.paid}} />
            <Card data={{name: 'serviceFee', value: cards.unpaid}} />
            {/* <Card data={{name: 'feePayable', value: 'IQ 2.25 M'}} /> */}
          </Stack>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.map((e, i) => (
                      <Row item={e} i={i} key={i} />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* pagnation  item   */}
        </div>
      </div>
    </div>
  )
}
