import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Stack, Typography} from '@mui/material'
import TitlePage from '../../customTable/TitlePage'
import {KTSVG} from '../../../../_metronic/helpers'
import Table from './Table'
import {useTranslation} from 'react-i18next'
export default function SubscriptionsDate() {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('subscriptionsDate'),
    home: 'Home',
    linkHome: '/',
  }
  const [rows, setRows] = useState<any>([])

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
        <Stack gap={'10px'} direction={'row'}>
          {/* <Stack
            p='12px'
            justifyContent={'center'}
            gap='5px'
            direction={'row'}
            sx={{background: 'var(--bs-body-bg)', borderRadius: '5.5px'}}
          >

            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
            <Typography sx={{fontSize: '15px', fontWeight: '700', color: 'var(--bs-text-muted)'}}>
              {t("filter")}
            </Typography>
          </Stack> */}
        </Stack>
      </Stack>
      <Table rows={rows} setRows={setRows} />
    </Stack>
  )
}
// var(--bs-body-bg)
