import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Langs = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/languages`, callback, setIsLoading)
  },
  getselectedLang: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/languages`, callback, setIsLoading)
  },
  update: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/settings/languages`, data, callback, setIsLoading)

    // const langs = await Apiservices.post(`/vendor/settings/languages`, data)

    // return langs
  },
}
export default Langs
