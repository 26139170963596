import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'

import {getStorage} from 'firebase/storage'

const app = initializeApp({
  apiKey: 'AIzaSyAMbT7QOSX5whtMEL9u0Zew2Jzg5JfX0Co',
  authDomain: 'store-9b30b.firebaseapp.com',
  projectId: 'store-9b30b',
  storageBucket: 'store-9b30b.appspot.com',
  messagingSenderId: '556846184164',
  appId: '1:556846184164:web:a96167101f38b43e589ef1',
  measurementId: 'G-K1S50NPF54',
})
export const db = getFirestore(app)

// Initialize Firebase
export const storage = getStorage(app)

export default storage
