import clsx from "clsx";
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/helpers";
interface Iprops {
     src: string | undefined;
     alt: string;
     width?: string;
     height?: string;
     style?: React.CSSProperties;
     className?: string;
}
export default function Img({ src, alt, width, height, style, className }: Iprops) {
     const [imageError, setImageError] = useState<boolean>(false);
     return (
          <img
               style={{ objectFit: "contain", ...style }}
               src={imageError ? toAbsoluteUrl('/media/svg/brand-logos/plurk.svg') : src}
               alt={alt}
               width={width}
               className={clsx('card h-100 w-100', className)}
               height={height}
               onError={() => setImageError(true)}
               loading="lazy"
          />
     );
}
