import axios from 'axios'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'
enum OrderState {
  ALL_ORDER = 'allOrder',
  // Add other order states here
}
const OrderApi = {
  get: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    state: string,
    callback: any,
    setIsLoading: any
  ) => {
    let url
    if (state !== OrderState.ALL_ORDER) {
      url = `/vendor/orders?page=${page}&per_page=${rowsPerPage}&order_status=${state}`
    } else {
      url = `/vendor/orders?page=${page}&per_page=${rowsPerPage}`
      if (searchText) {
        url = url + `&search=${searchText}`
      }
    }
    ApisMethods().get(url, callback, setIsLoading)
    // const coupons = await Apiservices.get(url)
  },
  getById: (
    rowsPerPage: number,
    page: number,
    id: string | undefined,
    callback: any,
    setIsLoading: any
  ) => {
    let url

    url = `/vendor/customers/orders/${id}?page=${page}&per_page=${rowsPerPage}`
    ApisMethods().get(url, callback, setIsLoading)
    // const coupons = await Apiservices.get(url)

    // return coupons
  },
  putStatus: (id: number | undefined, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(
      `/vendor/orders/update-status/${id}`,
      {status: status},
      callback,
      setIsLoading
    )
  },

  getSingleOrder: async (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/orders/${id}`, callback, setIsLoading)
  },
  getOrderPdf: async (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/orders/download-pdf/${id}`, callback, setIsLoading)
  },
}
export default OrderApi
