import {Avatar, Box, Stack, Typography} from '@mui/material'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function VariationRow({item}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      justifyContent={'space-between'}
      direction={'row'}
      p='10px 10px'
      borderRadius={'10px'}
      border='1px solid var(--bs-gray-300)'
    >
      <Stack gap='20px' direction={'row'}>
        <Box>
          <Avatar
            sx={{width: '50px', height: '50px', borderRadius: '8px'}}
            src={item.image}
            alt=''
          />
        </Box>
        <Stack gap='5px' direction={'row'}>
          {item.product_variations.map((e: any, i: any) => (
            <Box>
              {' '}
              <Typography
                sx={{
                  minWidth: '70px',
                  // height: '22px',
                  background: '#ABB1C0',
                  color: '#3F4254',
                  borderRadius: '12px',
                  textAlign: 'center',
                  padding: '4px 8px',
                  fontWeight: '400',
                }}
              >
                {e.option &&
                  e.option.name &&
                  (e.option.name[store.lang] || e.option.name[store.defLang])}{' '}
                :
                {e.option_detail &&
                e.option_detail.option_value &&
                (e.option_detail.option_value[store.lang] ||
                  e.option_detail.option_value[store.defLang])
                  ? e.option_detail.option_value[store.lang] ||
                    e.option_detail.option_value[store.defLang]
                  : 'any'}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>

      <Typography sx={{fontSize: '15px'}}>
        Qty({item.is_unlimited === 1 ? 'unlimited' : item.quantity}){' '}
      </Typography>
    </Stack>
  )
}
