import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Home from '../../sittings/address/GoogleMapReact'
import GoogleMap from './Test'
import InvoEn from './InvoEn'

export default function AddressDetails({order}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const [isShow, setIsShow] = useState(false)
  const handelShowMap = () => {
    setIsShow(!isShow)
  }

  return (
    <Stack
      p='35px 25px'
      mt='32px'
      borderRadius={'8px'}
      position={'relative'}
      sx={{
        background: 'url(/media/avatars/van.svg),var(--bs-body-bg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '90% 30%',
      }}
    >
      <Typography sx={{fontSize: '19px', fontWeight: '600'}}>{t('shippingAddress')}</Typography>
      <Stack gap='8px' mt='22px'>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>
          {order.address_full_name}
        </Typography>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>{order.address_phone}</Typography>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>
          {order.address_country && order.address_country[store.lang]} -{' '}
          {order.address_city && order.address_city[store.lang]}
        </Typography>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>{order.address_details}</Typography>
      </Stack>
      <button
        style={{
          width: '100px',
          color: 'white',
          background: '#009EF7',
          borderRadius: '8px',
          border: 'none',
          padding: '5px',
          marginTop: '20px',
        }}
        onClick={handelShowMap}
      >
        {t('map')}
      </button>
      {isShow && order.latitude && order.longitude && (
        <GoogleMap location={{lat: +order.latitude, lng: +order.longitude}} />
      )}
    </Stack>
  )
}
