import * as React from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import EditIcon from '@mui/icons-material/Edit'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined'
import SaveIcon from '@mui/icons-material/Save'
import PrintIcon from '@mui/icons-material/Print'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Product from '../../../../../Api/product'
import {useParams} from 'react-router-dom'

const actions = [
  {icon: <EditIcon />, name: 'Edit'},
  {icon: <DeleteIcon />, name: 'Delete'},
]
export default function Actons({
  item,
  optionValue,
  setOptionValue,
  setOpen,
  valuesId,
  setIsDelete,
  count,
  setCount,
  isSelected,
  setIsSelected,
  handelDelete,
  openTost,
  setOpenTost,
  setOpenDelete,
}: any) {
  const {id} = useParams()

  const handelEdit = () => {
    if (item.option_detail_id) {
      setIsSelected(isSelected.filter((e: number) => e !== item.option_detail_id))
    }

    setOpen(true)
  }
  return (
    <Box sx={{height: 20, transform: 'translateZ(150px)', flexGrow: 1}}>
      <SpeedDial
        ariaLabel='SpeedDial basic example'
        FabProps={{className: 'actions'}}
        sx={{position: 'absolute', bottom: '13px', right: '-7px', padding: '0'}}
        icon={<MoreVertIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            onClick={() => {
              if (action.name === 'Delete') {
                setOpenDelete(true)
              } else {
                handelEdit()
              }
            }}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}
