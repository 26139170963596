import React from 'react'

export default function AppMakker() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.4'
        d='M18.6714 2H16.7714C14.5914 2 13.4414 3.15 13.4414 5.33V7.23C13.4414 9.41 14.5914 10.56 16.7714 10.56H18.6714C20.8514 10.56 22.0014 9.41 22.0014 7.23V5.33C22.0014 3.15 20.8514 2 18.6714 2Z'
        fill='white'
      />
      <path
        opacity='0.4'
        d='M7.24 13.4301H5.34C3.15 13.4301 2 14.5801 2 16.7601V18.6601C2 20.8501 3.15 22.0001 5.33 22.0001H7.23C9.41 22.0001 10.56 20.8501 10.56 18.6701V16.7701C10.57 14.5801 9.42 13.4301 7.24 13.4301Z'
        fill='white'
      />
      <path
        d='M6.29 10.58C8.6593 10.58 10.58 8.6593 10.58 6.29C10.58 3.9207 8.6593 2 6.29 2C3.9207 2 2 3.9207 2 6.29C2 8.6593 3.9207 10.58 6.29 10.58Z'
        fill='white'
      />
      <path
        d='M17.7119 22C20.0812 22 22.0019 20.0793 22.0019 17.71C22.0019 15.3407 20.0812 13.42 17.7119 13.42C15.3426 13.42 13.4219 15.3407 13.4219 17.71C13.4219 20.0793 15.3426 22 17.7119 22Z'
        fill='white'
      />
    </svg>
  )
}
