import React, {useState, useCallback, useEffect} from 'react'
import HeadTable from '../customTable/HeadTable'
import TableHead from './TablHead'
import Row from './Row'
import Banner from '../../Api/banner'
import {PaginationSection} from '../customTable/pagnationSection'
import {CustomerListLoading} from '../customer/order/custmerListLoading'
import {CircularProgress, Stack} from '@mui/material'
import IBanner from '../../interface/IBanner'
import Manufacturers from '../../Api/manufacturers'
import Search from '../customTable/Search'
import Create from './Create'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import OptionCatalog from '../../Api/optionCatalog'

export default function Table({
  rows,
  setRows,
}: {
  rows: any[] | never[]
  setRows: React.Dispatch<React.SetStateAction<any[] | never[]>>
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [added, setAdded] = useState(false)
  const [num, setNum] = useState(0)

  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(e.target.value, 5, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )
  const getCoupons = (searchText: string, rowsPerPage: number, page: number) => {
    OptionCatalog.get(
      searchText,
      rowsPerPage,
      page,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    if (search && page === 1) {
    } else {
      getCoupons(search, rowsPerPage, page)
    }
  }, [rowsPerPage, page])
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  const store = useSelector((state: RootState) => state.vender)

  return (
    <div style={{marginTop: '30px'}} className={`card fds sha pt-5`}>
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <Stack mb='15px' width={'100%'} justifyContent={'space-between'} direction={'row'}>
            <Stack width={'200px'}>
              <Search search={search} handleSearch={handleSearch} />
            </Stack>
            {store.permissions && store.permissions.includes('option_catalogs_create') && (
              <Create num={num} setNum={setNum} setAdded={setAdded} rows={rows} setRows={setRows} />
            )}
          </Stack>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.map((e, i) => (
                      <Row
                        setIsLoading={setIsLoading}
                        setNum={setNum}
                        setAdded={setAdded}
                        setRows={setRows}
                        rows={rows}
                        item={e}
                        i={i}
                        key={i}
                      />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* pagnation  item   */}
        </div>
      </div>
    </div>
  )
}
