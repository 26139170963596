import {toastr} from 'react-redux-toastr'
import {KTSVG} from '../../../_metronic/helpers'
import {CustomToastrProps} from './types'

export const customToastr = ({
  type,
  iconLink,
  title = 'some title',
  description = 'some  description',
}: CustomToastrProps) => {
  let iconLinkOpt = ''

  if (!iconLink) {
    switch (type) {
      default:
      case 'success':
        iconLinkOpt = '/media/icons/toster/success.svg'
        break
      case 'warning':
        iconLinkOpt = '/media/icons/toster/exclamation-solid.svg'
        break
      case 'error':
        iconLinkOpt = '/media/icons/toster/error.svg'
        break
    }
  } else {
    iconLinkOpt = iconLink
  }
  const ToastrImg = () =>  (<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16 0C7.1632 0 0 7.1632 0 16C0 24.8368 7.1632 32 16 32C24.8368 32 32 24.8368 32 16C32 7.1632 24.8368 0 16 0ZM25.1312 12.3312L13.9312 23.5312C13.6192 23.8432 13.2096 24 12.8 24C12.3904 24 11.9808 23.8432 11.6688 23.5312L6.8688 18.7312C6.2432 18.1056 6.2432 17.0944 6.8688 16.4688C7.4944 15.8432 8.5056 15.8432 9.1312 16.4688L12.8 20.1376L22.8688 10.0688C23.4944 9.4432 24.5056 9.4432 25.1312 10.0688C25.7568 10.6944 25.7568 11.7056 25.1312 12.3312Z" fill="white"/>
  </svg>
  )


  toastr[type](title, description, {
    // icon: <ToastrImg/> ,
    showCloseButton: true,
  })
}
