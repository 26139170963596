import React, {useState, useEffect} from 'react'
import Options from '../../../Api/options'
import {OptionColorschema, Textschema, Imageschema} from '../../../yup/AddOptionShcema'
import IImageOption from '../../../interface/IOptionImage'
import IOption from '../../../interface/IOption'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import {toast} from 'react-toastify'
import DeletedMsg from '../../customTable/DeleteMsg'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import {CircularProgress, Stack} from '@mui/material'
import ValidationFun from '../../../yup/ValidationFun'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

interface ActionsButtonProps {
  isAllowToEdit: boolean
  index: number
  handleRemove: (index: number) => Promise<void>
  handleEdit: (index: number) => Promise<void>
  handelSaveOption?: (index: number) => Promise<void>
  setIsAllowToEdit: React.Dispatch<React.SetStateAction<boolean>>
  setRowSlectedToEdit?: React.Dispatch<React.SetStateAction<number>>
  isCreated?: boolean
  setIsActive?: React.Dispatch<React.SetStateAction<boolean>>
  setIsDelete?: React.Dispatch<React.SetStateAction<boolean>>
  isActive?: boolean
  option?: any
  setError?: any
  setActiveAdd?: any
}

export const ActionsButton = ({
  setIsActive,
  isActive,
  option,
  setIsDelete,
  setError,
  setErrors,
}: any) => {
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const handleEditClick = () => {
    if (setIsActive) {
      setIsActive(false)
    }
  }
  useEffect(() => {
    if (setIsActive) {
      setIsActive(true)
    }
  }, [])
  const deleteRow = () => {
    if (option && setIsDelete) {
      setIsLoadingDelete(true)
      Options.destoyOption(
        option,
        () => {
          setOpen(true)
          setIsDelete(true)
        },
        setIsLoadingDelete
      )
    }
  }
  const [open, setOpen] = React.useState(false)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const store = useSelector((state: RootState) => state.vender)

  const [languages, setlanguages] = useState(['en'])
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])

  const handleSaveClick = async () => {
    if (setIsActive && option) {
      if (option.option_code) {
        ValidationFun(option, OptionColorschema(languages), setErrors, (value: any) => {
          setIsActive(true)
          Options.putOption(
            value,
            () => {
              setError('')
            },
            setIsLoadingEdit
          )
        })
      } else if (
        !option.option_code &&
        option.option_image !== 'https://vendor.api.koninstore.com/storage/store/options'
      ) {
        ValidationFun(option, Imageschema(languages), setErrors, (value: any) => {
          setIsActive(true)
          Options.putOption(
            value,
            () => {
              setError('')
            },
            setIsLoadingEdit
          )
        })
      } else {
        ValidationFun(option, Textschema(languages), setErrors, (value: any) => {
          setIsActive(true)

          Options.putOption(
            value,
            () => {
              setError('')
            },
            setIsLoadingEdit
          )
        })
      }
    }
  }
  const [msgErr, setMsgErr] = useState('')
  const [openMsg, setOpenMsg] = useState(false)
  return (
    <div
      style={{margin: '12px 0'}}
      className='col-lg-6 text-end justify-content-end align-items-end d-flex gap-4'
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='warning' sx={{width: '100%'}}>
          {msgErr}
        </Alert>
      </Snackbar>
      {isLoadingDelete ? (
        <CircularProgress size={26} />
      ) : (
        <button
          type='button'
          style={{background: 'var(--orange-color)', color: '#fff'}}
          className='btn btn-icon btn-danger btn-sm'
          onClick={() => setOpenMsg(true)}
        >
          <i className='bi bi-trash fs-7'></i>
        </button>
      )}

      <DeletedMsg open={openMsg} setOpen={setOpenMsg} onClick={deleteRow} />

      {!isActive ? (
        <button
          type='button'
          className='btn btn-icon btn-success btn-sm me-1'
          onClick={handleSaveClick}
        >
          <i className='bi bi-check2 fs-7'></i>
        </button>
      ) : (
        <>
          {isLoadingEdit ? (
            // <Stack alignItems={'center'} width='24px'>
            <CircularProgress size={26} />
          ) : (
            // </Stack>
            <button
              type='button'
              className='btn btn-icon btn-light btn-sm'
              onClick={handleEditClick}
            >
              <i className='bi bi-pencil-fill fs-7'></i>
            </button>
          )}
        </>
      )}
    </div>
  )
}
