import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState, useDispatch, withToastr} from '../../../store'
import {FetchStatus} from '../../../store/types'
import {categoriesAction} from '../../../store/vender/catogory/thunk'
import {ProductsAction} from '../../../store/vender/products/thunk'
import {ArrangementOrder} from '../../../types'
import {getComparator, stableSort} from '../../../utils/sortTable'

import {PaginationSection} from '../../customTable/pagnationSection'
import {EnhancedTableHead} from './EnhancedTableHead'
import {RowItem} from './Row'
import {TooBar} from './TooBar'
import {ProductListData} from './types'
import Apiservices from '../../../../lib/ApiServices'
import Catalog from '../../../Api/catalog'
import {useTranslation} from 'react-i18next'
import {CircularProgress, Stack} from '@mui/material'

const ReViewProductTable = () => {
  const [page, setPage] = useState<number>(1)
  const [order, setOrder] = useState<ArrangementOrder>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const [selected, setSelected] = useState<string[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [search, setSearch] = useState<string>('')
  const dispatch = useDispatch()
  const {products, status, error} = useSelector((state: RootState) => state.product)
  const [isLoading, setIsLoading] = useState(false)
  const [newProducts, setProducts] = useState([...products.data])
  useEffect(() => {
    setProducts(products.data)
  }, [products])
  const fetchData = useCallback(() => {
    dispatch(
      withToastr(
        ProductsAction({
          page: page,
          pre_page: rowsPerPage,
          search: search,
        }),
        {
          success: {
            title: 'Success to fetch data',
          },
        }
      )
    )
  }, [page, rowsPerPage, dispatch])

  const [timerId, setTimerId] = useState<number | null>(null)
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          dispatch(
            withToastr(
              ProductsAction({
                page: 1,
                pre_page: rowsPerPage,
                search: newSearchValue,
              }),
              {
                success: {
                  title: 'Success to fetch data',
                },
              }
            )
          )
        }, 500)
      )
    },
    [timerId]
  )

  useEffect(() => {
    fetchData()

    // getCategories()
  }, [fetchData])

  const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId = products?.data?.map((n) => n.id)
      setSelected(newSelectedId || [])
      return
    }
    setSelected([])
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(0)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  return (
    <Stack mt='31px' className={`card `}>
      <TooBar handleSearch={handleSearch} search={search} />
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={products.data?.length || 0}
              selected={selected}
            />
            {/*  id  status is pending  will  show  spinner  */}

            {/* help-products-cata */}
            <tbody>
              {!isLoading && (
                <>
                  {status !== FetchStatus.completed ? (
                    <CircularProgress />
                  ) : products.data.length > 0 && status === FetchStatus.completed ? (
                    (newProducts || []).map((row, index) => {
                      if (typeof row === 'number') return null
                      const isItemSelected = isSelected(row?.id || '')

                      return (
                        <>
                          {' '}
                          <RowItem
                            isItemSelected={isItemSelected}
                            {...row}
                            className={index % 2 === 0 ? 'event' : 'odd'}
                            key={row?.id}
                            rowCount={products.pages.total || 0}
                            numSelected={selected.length}
                            selected={selected}
                            setSelected={setSelected}
                            setProducts={setProducts}
                            newProducts={newProducts}
                            setIsLoading={setIsLoading}
                          />
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {/*  WILL  SHOW  PAGNATION  SECTION  ONLY  IF  THERE  IS  DATA */}
          {products.data.length > 0 && status === FetchStatus.completed && (
            <PaginationSection
              rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
              count={products.pages?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {/*  If  ther  any  error  will  show   */}
          {status === FetchStatus.error && (
            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
              {error}
            </div>
          )}
        </div>
      </div>
    </Stack>
  )
}

export default ReViewProductTable
