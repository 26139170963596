import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'

export const SubCategorySchema = (languages: any) => {
  return Yup.object().shape({
    permalink: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`permalinkErr`),
        }
      }, {})
    ),
    description: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`descriptionErr`),
        }
      }, {})
    ),
    name: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`nameErr`),
        }
      }, {})
    ),
  })
}
