/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../auth'
import {Languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <Link style={{color: '#364DF7'}} to={'/'} className='menu-link px-5'>
          Pricing{' '}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/'} className='menu-link px-5'>
          Templates{' '}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/'} className='menu-link px-5'>
          Help{' '}
        </Link>
      </div>

      <div className='separator my-2'></div>
      <Languages />
      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
      {/* <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div> */}
    </div>
  )
}

export {HeaderUserMenu}
