import React from 'react'
import {useTranslation} from 'react-i18next'

export default function ErrorMsg({msgErr, child}: {msgErr: string; child?: any}) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(msgErr: any) {
    if (!msgErr) return msgErr
    return msgErr.charAt(0).toUpperCase() + msgErr.slice(1).toLowerCase()
  }
  const translatedText = t(msgErr)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <>
      {msgErr && (
        <div className='fv-plugins-message-container'>
          <div style={{textTransform: 'capitalize', color: '#EF476F'}} className='fv-help-block'>
            {capitalizedText} {child && `(${child})`}
          </div>
        </div>
      )}
    </>
  )
}
