import {FormikProps} from 'formik'
import {useState} from 'react'
import {Language} from '../../../types'
import {ActionsButton} from './actionsButton'
import {useLocation, useParams} from 'react-router-dom'
import OptionColor from './OptionColor'

export interface ItemOptionColor {
  color: string
  sort: number
  option_value: {
    [key in Language]: string
  }
}

interface OptionDetailsProps {
  optionDetails: Array<ItemOptionColor>
  formik: FormikProps<any>
  activeLang: Language
  disabled?: boolean
  handleRemoveOption: (id: string | number) => Promise<void>
  handleEditOption: (id: string | number, data: any) => Promise<void>
  msgErr: any
  setActiveAdd: any
  Adderrors: any
}

export function OptionColorDetails({
  optionDetails,
  Adderrors,
  formik,
  disabled,
  handleRemoveOption,
  handleEditOption,
  msgErr,
  setActiveAdd,
}: OptionDetailsProps) {
  return (
    <div className='my-2 card card-flush bg-transparent '>
      {(formik?.values?.optionDetails as Array<ItemOptionColor>)?.map((option, index) => (
        <OptionColor
          option={option}
          index={index}
          formik={formik}
          disabled={disabled}
          handleRemoveOption={handleRemoveOption}
          handleEditOption={handleEditOption}
          msgErr={msgErr}
          setActiveAdd={setActiveAdd}
          Adderrors={Adderrors}
        />
      ))}
    </div>
  )
}
