import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'

export default function BackagesTabs({plan, setPlan}: any) {
  const [plans, setPlans] = useState(['monthly', 'yearly', 'custom'])
  return (
    <Stack
      width={'100%'}
      mt='24px'
      borderRadius={'3px'}
      p='5px'
      bgcolor={'#E3E1FF'}
      direction={'row'}
    >
      {plans.map((e: any) => {
        return (
          <Stack
            onClick={() => {
              setPlan(e)
            }}
            bgcolor={plan === e ? '#fff' : ''}
            p='12px'
            sx={{cursor: 'pointer'}}
            width={{xs: '33.3%'}}
            borderRadius={'3px'}
          >
            <Label align='center' text={e} color='#554AF0' fs='18px' fw='600' />
          </Stack>
        )
      })}
    </Stack>
  )
}
