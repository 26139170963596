import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useReactToPrint} from 'react-to-print'
import InvoEn from './InvoEn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

interface InvoiceProps {
  invoiceData: {
    invoiceNumber: string
    customerName: string
    // يمكنك إضافة المزيد من البيانات هنا
  }
  dataStore: any
}

// مكون الفاتورة
const Invoice = React.forwardRef<HTMLDivElement, InvoiceProps>((props, ref) => {
  const {invoiceData, dataStore} = props

  //
  return (
    <div ref={ref}>
      <InvoEn dataStore={dataStore} invoiceData={invoiceData} />
      {/* <h1>Invoice</h1>
      <p>Invoice Number: {invoiceData.invoiceNumber}</p>
      <p>Customer Name: {invoiceData.customerName}</p> */}
      {/* أضف المزيد من البيانات هنا */}
    </div>
  )
})

// مكون زر الطباعة
interface PrintButtonProps {
  invoiceData: {
    invoiceNumber: string
    customerName: string
    // يمكنك إضافة المزيد من البيانات هنا
  }
  dataStore: any
}

const PrintButton: React.FC<PrintButtonProps> = ({invoiceData, dataStore}) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current
    },
  })
  const {t} = useTranslation()

  return (
    <div>
      <button
        style={{
          background: '#50CD89',
          padding: '10px 36px',
          color: 'white',
          border: 'none',
          borderRadius: '7px',
        }}
        onClick={handlePrint}
      >
        {' '}
        {t('print')}
      </button>
      <div style={{display: 'none'}}>
        <Invoice ref={componentRef} dataStore={dataStore} invoiceData={invoiceData} />
      </div>
    </div>
  )
}

export default PrintButton
