import React from 'react'

export default function SelectState({
  option,
  onchange,
  changeState,
  setChangeState,
  state,
  check,
}: any) {
  const handleChange = (event: any) => {
    setChangeState({value: event.target.value})
    onchange(event.target.value)
    // values[`${name}`] = +event.target.value
  }

  return (
    <>
      <select
        disabled={check}
        style={{
          padding: state ? '8px' : '12.0625px 13px 12.5px',
          background:
            state && changeState.value === 'pending'
              ? '#ffd8424f'
              : state && changeState.value === 'preparing'
              ? '#5d90f138'
              : state && changeState.value === 'in_progress'
              ? '#63b2dd24'
              : state && changeState.value === 'refunded'
              ? '#f8f5ff7a'
              : state && changeState.value === 'not_active'
              ? '#5959597a'
              : state && (changeState.value === 'completed' || changeState.value === 'active')
              ? '#30eb8940'
              : state && (changeState.value === 'canceled' || changeState.value === 'blocked')
              ? '#ddb3b340'
              : 'var(--bs-body-bg)',
          border: state ? 'none' : 'none',
          color:
            state && changeState.value === 'pending'
              ? '#FFC700'
              : state && changeState.value === 'preparing'
              ? '#7239EA'
              : state && changeState.value === 'in_progress'
              ? '#009EF7'
              : state && changeState.value === 'refunded'
              ? '#F1416C'
              : state && changeState.value === 'not_active'
              ? '#ffffff'
              : state && (changeState.value === 'completed' || changeState.value === 'active')
              ? '#50CD89'
              : state && (changeState.value === 'canceled' || changeState.value === 'blocked')
              ? '#e32046'
              : ' var(--bs-text-dark)',
          borderRadius: '8.18px',
          width: '100%',
          // backgroundColor: 'var(--bs-gray-100)',
          // borderColor: 'var(--bs-gray-100)',
        }}
        value={changeState.value}
        onChange={handleChange}
      >
        {option.map((e: any) => (
          <option
            style={{
              padding: '5px',
              background: 'var(--bs-app-footer-bg-color)',
              color: 'var(--bs-body-color)',
            }}
            key={e}
            value={e.value}
          >
            {e.label}
          </option>
        ))}
      </select>
    </>
  )
}
