import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import Form from './Form'
import IBanner from '../../interface/IBanner'
import EditForm from './EditForm'
import {useTranslation} from 'react-i18next'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Edit({
  setOpen,
  open,
  item,
  setItem,
  setRows,
  rows,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  item: any
  setItem: React.Dispatch<React.SetStateAction<any>>
  setRows: any
  rows: any
}) {
  const handleClose = () => {
    setOpen(false)
  }
  const {t} = useTranslation()

  return (
    <div>
      <></>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <EditForm
          setRows={setRows}
          rows={rows}
          setItem={setItem}
          item={{...item}}
          open={open}
          setOpen={setOpen}
        />
      </Dialog>
    </div>
  )
}
