import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {Box, Container, IconButton, Stack, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RedStart from '../../../../customTable/RedStart'
import InputType from '../../../../customTable/InputType'
import LabelInput from '../../../../customTable/LabelInput'
import SaveButton from '../../../../customTable/SaveButton'
import Radio from '@mui/material/Radio'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import MySelect from '../../../../customTable/CustomSelectTest'
import UploadImage from '../option/UpdateImage'
import Variations from '../../../../../Api/variations'
import {useParams} from 'react-router-dom'
import VariationForm from './VariationForm'
import EditVariationForm from './EditVariationForm'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const languages = [
  {label: 'English', lang: 'en', flag: '/media/flags/united-states.svg'},
  {label: 'Arabic', lang: 'ar', flag: '/media/flags/lebanon.svg'},
]
export default function EditPopUp({
  open,
  setOpen,
  item,
  setItem,
  oldItem,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  item: any
  setItem: any
  oldItem: any
}) {
  const handleClose = () => {
    setOpen(false)
    setItem(oldItem)
  }

  return (
    <div>
      <></>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <EditVariationForm setItem={setItem} item={item} open={open} setOpen={setOpen} />
      </Dialog>
    </div>
  )
}
