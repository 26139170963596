import React from 'react'

export default function FileIcon() {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.15'
        y='0.15'
        width='35.7'
        height='35.7'
        rx='2.85'
        stroke='#D2D3D3'
        stroke-width='0.3'
      />
      <path
        d='M10.9974 5.33398H19.1641L27.3307 13.5007V26.334C27.3307 26.9528 27.0849 27.5463 26.6473 27.9839C26.2097 28.4215 25.6162 28.6673 24.9974 28.6673H10.9974C10.3786 28.6673 9.78506 28.4215 9.34748 27.9839C8.9099 27.5463 8.66406 26.9528 8.66406 26.334V7.66732C8.66406 7.04848 8.9099 6.45499 9.34748 6.0174C9.78506 5.57982 10.3786 5.33398 10.9974 5.33398Z'
        stroke='#298BED'
        stroke-width='0.8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.1641 5.33398V13.5007H27.3307'
        stroke='#298BED'
        stroke-width='0.8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
